import { NgModule } from '@angular/core';
import {CommonModule} from "@angular/common";

import { FilterPipe } from './filter.pipe';
import { SearchPipe } from './search.pipe';
import { ShortNamePipe } from './short-name.pipe';
import { GenerationHourPipe } from './generation-hour.pipe';
import { ArrayPipe } from './array.pipe';
import { PadPipe } from './pad.pipe';
import { MsDurationPipe } from './ms-duration.pipe';
import { FormatNumberPipe } from './format-number.pipe';
import { XmlFormatterPipe } from './xml-formatter.pipe';
import { DurationBadgePipe } from './duration-badge.pipe';
import { DateTextPipe } from './date-text.pipe';
import { DateAsAgoPipePipe } from './date-as-ago-pipe.pipe';
import { StringToColorPipe } from './string-to-color.pipe';

@NgModule({
  declarations:[FilterPipe, SearchPipe, ShortNamePipe, GenerationHourPipe, ArrayPipe, PadPipe, MsDurationPipe, FormatNumberPipe, XmlFormatterPipe, DurationBadgePipe, DateTextPipe, DateAsAgoPipePipe, StringToColorPipe],
  imports:[CommonModule],
    exports: [FilterPipe, SearchPipe, ShortNamePipe, GenerationHourPipe, ArrayPipe, PadPipe, MsDurationPipe, FormatNumberPipe, XmlFormatterPipe, DurationBadgePipe, DateTextPipe, DateTextPipe, DateAsAgoPipePipe, StringToColorPipe]
})

export class PipeModule{}
