import { Injectable } from '@angular/core';
import {NgbDateStruct, NgbTimeStruct} from "@ng-bootstrap/ng-bootstrap";
import * as moment from 'moment';
import {NgxSpinnerService} from 'ngx-spinner';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(private spinner: NgxSpinnerService) { }

  pad(num: number, padlen: number = 2, padchar: string = '0') {
    var pad_char = typeof padchar !== 'undefined' ? padchar : '0';
    var pad = new Array(1 + padlen).join(pad_char);
    return (pad + num).slice(-pad.length);
  }

  datePickerToDate(datePickerDate: NgbDateStruct) {
    if (datePickerDate == null) {
      return '';
    }
    let date = new Date(new Date().toLocaleString("en-US", {timeZone: "Europe/Paris"}));
    date.setFullYear(datePickerDate.year,datePickerDate.month-1,datePickerDate.day)
    return  moment(date).format('YYYY-MM-DD')
  }
  datePickerToDateTime(datePickerDate: NgbDateStruct, beginDay: boolean = true) {
    let date = new Date(new Date().toLocaleString("en-US", {timeZone: "Europe/Paris"}));
    date.setFullYear(datePickerDate.year,datePickerDate.month-1,datePickerDate.day)
    if(beginDay) {
      date.setHours(0,0,0)
    } else {
      date.setHours(23,59,59)
    }
    return  moment(date).format('YYYY-MM-DDTHH:mm:ss')
  }
  dateTimePickerToDateTime(datePickerDate: NgbDateStruct, timepickerTime:NgbTimeStruct) {
    let date = new Date(new Date().toLocaleString("en-US", {timeZone: "Europe/Paris"}));
    date.setFullYear(datePickerDate.year,datePickerDate.month-1,datePickerDate.day)
    date.setHours(timepickerTime.hour,timepickerTime.minute,timepickerTime.second)
    return  moment(date).format('YYYY-MM-DDTHH:mm:ss')
  }

  loadingShow(){
    this.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
  }
  loadingHide(){
    this.spinner.hide();
  }

  excelExport (data:any, title:string,fileName:string = 'import', rowSeparator: boolean = false) {
    //const header = Object.keys(data[0][0])
    let header = [];
    let colCount = 1
    for (let i in data) {
      header = Object.keys(data[i][0])
      colCount = header.length
    }
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet();
    const titleRow = worksheet.addRow([title]);
    titleRow.alignment = { horizontal:'center'}
    titleRow.font = { family: 4, size: 12, bold: true }
    let ColEnd =  String.fromCharCode(65 + colCount -1)
    worksheet.mergeCells('A1:'+ColEnd+'1')
    //worksheet.addRow([])
    const headerRow = worksheet.addRow(header)
    headerRow.eachCell(cell => {
      cell.font = {  bold: true }
    })
    for (let i in data) {
      data[i].forEach(d => {
        let row = worksheet.addRow(Object["values"](d))
        //row.font = { name: 'Saysettha OT',  }
      })
      if (rowSeparator) {
        let separator = worksheet.addRow(new Array(colCount).fill(''))
        separator.eachCell(cell => {
          cell.font = {  bold: true }
          cell.fill={
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
              argb: 'C6CFEF'
            }
          };
        })
      }
    }
    worksheet.columns.forEach(function (column, i) {
      column.width = 20
      switch (i) {
        case 1:   // product name
        case 11:  // requester name
          column.width = 40;
          break
        case 4:   // unavailability message
          column.width = 60
          break
        case 7: // nb adult
        case 8: // nb child
        case 9: // nb infant
          column.width = 8

      }
      /*if(i!==0)
      {
        var maxLength = 0;
        column["eachCell"]({ includeEmpty: true }, function (cell) {
          var columnLength = cell.value ? cell.value.toString().length : 10;
          if (columnLength > maxLength ) {
            maxLength = columnLength;
          }
        });
        column.width = maxLength < 10 ? 10 : maxLength;
      }*/
    });

    workbook.xlsx.writeBuffer().then(excelData => {
      const blob = new Blob([excelData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      fs.saveAs(blob, fileName+'.xlsx')
    })
  }
}
