import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { XftTransactionLogComponent } from './xft-transaction-log/xft-transaction-log.component';
import {RouterModule, Routes} from '@angular/router';
import {NgSelectModule} from '@ng-select/ng-select';
import {FormsModule} from '@angular/forms';
import {NgbDatepickerModule, NgbPaginationModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {PipeModule} from '../shared/pipes/pipe.module';
import { XftTransactionLogErrorWarningDetailComponent } from './xft-transaction-log-error-warning-detail/xft-transaction-log-error-warning-detail.component';
import { XftTransactionLogXmlComponent } from './xft-transaction-log-xml/xft-transaction-log-xml.component';
import { XftTransactionLogErrorReportComponent } from './xft-transaction-log-error-report/xft-transaction-log-error-report.component';
import { XftTransactionApiLogComponent } from './xft-transaction-api-log/xft-transaction-api-log.component';
import { XftTransactionReportComponent } from './xft-transaction-report/xft-transaction-report.component';
import {AuthGuard} from '../shared/auth/auth-guard.service';

const routes: Routes = [
  {path: 'log', component:XftTransactionLogComponent, canActivate: [AuthGuard],data: { roles: ['ROLE_ADMIN','ROLE_MIDOFFICE']}},
  {path: 'error-report', component:XftTransactionLogErrorReportComponent, canActivate: [AuthGuard],data: { roles: ['ROLE_ADMIN','ROLE_MIDOFFICE']}},
  {path: 'transaction-report', component:XftTransactionReportComponent, canActivate: [AuthGuard],data: { roles: ['ROLE_ADMIN','ROLE_MIDOFFICE']}},
];

@NgModule({
  declarations: [
    XftTransactionLogComponent,
    XftTransactionLogErrorWarningDetailComponent,
    XftTransactionLogXmlComponent,
    XftTransactionLogErrorReportComponent,
    XftTransactionApiLogComponent,
    XftTransactionReportComponent
  ],
    imports: [
        CommonModule,
        [RouterModule.forChild(routes)],
        NgSelectModule,
        FormsModule,
        NgbDatepickerModule,
        NgbPaginationModule,
        PipeModule,
        NgbTooltipModule,
    ]
})
export class XftTransactionModule { }
