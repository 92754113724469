import {Component, OnDestroy, OnInit} from '@angular/core';
import {ParameterService} from '../../shared/services/midOffice/parameter.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NotificationService} from '../../shared/services/notification.service';
import {UtilsService} from '../../shared/services/utils.service';
import {GlobalConstants} from '../../shared/global-constants';
import {AuditLogComponent} from '../../shared/audit-log/audit-log.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ParameterValueFormComponent} from '../form/parameter-value-form/parameter-value-form.component';
import swal from 'sweetalert2';
import {NgDynamicBreadcrumbService} from 'ng-dynamic-breadcrumb';
import {Title} from '@angular/platform-browser';
import {Subscription} from 'rxjs';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-parameter-value-list',
  templateUrl: './parameter-value-list.component.html',
  styleUrls: ['./parameter-value-list.component.scss']
})
export class ParameterValueListComponent implements OnInit, OnDestroy {
  parameter: any = null;
  parameterValueList: any = []
  parameterCode: string = ''
  page: number = 1;
  pageSize: number;
  totalCount: number = 0
  pageCount: number = 1


  name: string = '';
  code: string = '';
  externalCode: string = '';
  status: boolean|null = true;
  statusList = [
    { value: true, name: 'Actif' },
    { value: false, name: 'Inactif' },
  ];
    searchEngineHidden: boolean = (window.innerWidth < 1200 )
    getParameterSubscription: Subscription
    getParameterValuesSubscription: Subscription
    deleteParameterValueSubscription: Subscription
    getParameterValueAuditSubscription: Subscription
  constructor(private parameterService: ParameterService,private route: ActivatedRoute,private title: Title, private notifyService : NotificationService,private router: Router, private utilsService:UtilsService,private modalService: NgbModal,private ngDynamicBreadcrumbService: NgDynamicBreadcrumbService) { }
  ngOnInit(): void {
    this.pageSize = GlobalConstants.pageSize;
    this.utilsService.loadingShow();
    this.parameterCode = this.route.snapshot.paramMap.get('parameterCode') ?? '';
      this.getParameterSubscription = this.parameterService.getParameter(this.parameterCode ?? '').subscribe(
      parameter => {
        this.parameter = parameter;
        this.utilsService.loadingHide();
          const breadcrumbs  =  [
              {
                  label: 'Accueil',
                  url: '/'
              },
              {
                  label: 'Paramètres',
                  url: '/parameter'
              },
              {
                  label: this.parameter?.name ?? '',
                  url: ''
              },
          ];
          this.ngDynamicBreadcrumbService.updateBreadcrumb(breadcrumbs);
          this.title.setTitle((environment.server != 'PRODUCTION' ? environment.server + ' - ' : '') + "Paramètre  "+(this.parameter?.name ?? '')+" - Liste des valeurs - MidOffice Thalasso N°1");
      },
      error => {
        this.utilsService.loadingHide();
        this.notifyService.showError(error.error.message ?? (error.message ?? ''),"")
      }
    )
    this.getParameterValues()


  }
    ngOnDestroy() {
        if (this.getParameterSubscription) {
            this.getParameterSubscription.unsubscribe();
        }
        if (this.getParameterValuesSubscription) {
            this.getParameterValuesSubscription.unsubscribe();
        }
        if (this.deleteParameterValueSubscription) {
            this.deleteParameterValueSubscription.unsubscribe();
        }
        if (this.getParameterValueAuditSubscription) {
            this.getParameterValueAuditSubscription.unsubscribe();
        }
    }

    onSubmit() {
        this.page = 1;
        this.getParameterValues()
    }
  getParameterValues(){
    this.utilsService.loadingShow();
    this.getParameterValuesSubscription = this.parameterService.getParameterValues(this.page,this.parameterCode,this.code,this.name,this.externalCode,this.status).subscribe(
      parameterValueList => {
        this.parameterValueList = [];
        this.totalCount = parameterValueList.totalCount;
        this.pageCount = parameterValueList.nbPage;
        for (let item in parameterValueList.parameterValues) {
          this.parameterValueList.push(parameterValueList.parameterValues[item]);
        }
        this.utilsService.loadingHide();
      },
      error => {
        this.parameterValueList = [];
        this.utilsService.loadingHide();
        this.notifyService.showError(error.error.message ?? (error.message ?? ''),"")
      }
    )
  }

  deleteParameterValue(parameterCode: string, code: string) {

      swal.fire({
          title: '',
          text: "Êtes-vous sûr de vouloir supprimer cette valeur ?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#2F8BE6',
          cancelButtonColor: '#F55252',
          confirmButtonText: 'Supprimer',
          cancelButtonText: 'Annuler',
          customClass: {
              confirmButton: 'btn btn-warning',
              cancelButton: 'btn btn-danger ml-1'
          },
          buttonsStyling: false,
      }).then((result) => {
          if (result.isConfirmed) {
              this.utilsService.loadingShow();
              this.deleteParameterValueSubscription = this.parameterService.deleteParameterValue(parameterCode,code).subscribe(
                  () => {
                      this.notifyService.showSuccess('Suppression effectuée avec succès',"")
                      this.getParameterValues()
                  },
                  error => {
                      this.notifyService.showError(error.error.message ?? (error.message ?? ''),"")
                  }
              )
          }
      });
  }

  paginate(page: number) {
    this.page = page ?? this.page;
    if(this.page>this.pageCount) {
      this.page = this.pageCount-1
    }
    this.getParameterValues()

  }
  auditLog(parameterCode: string, code: string) {
    this.utilsService.loadingShow();
    this.getParameterValueAuditSubscription = this.parameterService.getParameterValueAudit(parameterCode,code).subscribe(
        audit => {
          const modalRef = this.modalService.open(AuditLogComponent,{ size: 'lg'});
          modalRef.componentInstance.title = 'Log modification';
          modalRef.componentInstance.auditLog = audit.auditLog;
          this.utilsService.loadingHide();
        },
        error => {
          this.utilsService.loadingHide();
          this.notifyService.showError(error.error.message ?? (error.message ?? ''),"")
        }
    )
  }

    addEdit(parameterCode: string,parameterValue = null) {
      const modalRef = this.modalService.open(ParameterValueFormComponent,{ size: 'lg'});
      modalRef.componentInstance.parameterCode = parameterCode;
      modalRef.componentInstance.parameterValueCode = parameterValue !== null ? parameterValue.code : '';
      modalRef.componentInstance.parameterValue = parameterValue !== null ? parameterValue : {isActive:true};
      modalRef.componentInstance.message = parameterValue !== null ? 'edit' : 'add';
      modalRef.result.then(
          () => {
            this.paginate(this.page);
          });
    }
    showHideSearchEngine(){
        this.searchEngineHidden = !this.searchEngineHidden;
    }

}
