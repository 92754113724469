<div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
    <button type="button" style="border: 0; background: none" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"><i class="ft-x-circle" ></i></button>
</div>
<div class="modal-body">
    <table class="table table-striped table-bordered selection-deletion-row xft-transaction-log">

        <tr *ngIf="auditLog.length">
            <td style="width: 150px"><b>Date et heure</b></td>
            <td style="width: 100px"><b>Action</b></td>
            <td style="width: 150px"><b>Utilisateur</b></td>
            <td style="width: auto;min-width: 250px;"><b>Modification</b></td>
        </tr>
        <tr *ngFor="let log of auditLog">
            <td>
                {{log.dateTime  | date:'dd/MM/YYYY' | dateText}}
                {{log.dateTime  | date:'HH:mm:ss'}}
            </td>
            <td>{{log.action}}</td>
            <td>{{log.userName}}</td>
            <td>
                <div *ngFor="let event of log.eventData | keyvalue" class="audit-list">
                    <b>{{event.key}}</b>
                    <span class="audit-event-list">
                        <span class="audit-event audit-event-from">{{event?.value?.from}}<span *ngIf="event?.value?.from?.length == 0">___</span></span>
                        <i class="ft-arrow-right event-arrow"></i>
                        <span class="audit-event audit-event-to">{{event?.value?.to}}<span *ngIf="event?.value?.to?.length == 0">___</span></span><br>
                    </span>
                </div>
            </td>
        </tr>
        <tr *ngIf="!auditLog.length">
            <td style="text-align: center"><br>Aucun log<br><br></td>
        </tr>
    </table>
</div>
