import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UtilsService} from '../../shared/services/utils.service';
import {ApiLogService} from '../../shared/services/log/api-log.service';
import {NotificationService} from '../../shared/services/notification.service';
import {ApiLogRqRsComponent} from '../../api-log/api-log-rq-rs/api-log-rq-rs.component';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-xft-transaction-api-log',
  templateUrl: './xft-transaction-api-log.component.html',
  styleUrls: ['./xft-transaction-api-log.component.scss']
})
export class XftTransactionApiLogComponent implements OnInit, OnDestroy{
    @Input() apiLogList: any[]|null;
    @Input() apiLogId: number|null;
    logApiDetail: number = null;
    idLogApiDetail: number = null;
    logRqRs: any ={};
    request:string = '';
    response:string = '';
    requestHeader:string = '';
    responseHeader:string = '';
    showHeader:boolean = false;
    totalDuration: number = 0

    requestSql:string = '';
    responseSql:any;
    isSqlResponse: boolean = false;
    showSql: boolean = false;
    apiLogGetRqRsSubscription: Subscription
  constructor(private apiLogService: ApiLogService,private utilsService : UtilsService,private notifyService : NotificationService,public activeModal: NgbActiveModal,private modalService: NgbModal) {

  }

    ngOnInit(): void {
        for (let item in this.apiLogList) {
            this.totalDuration += this.apiLogList[item].msDuration
        }
    }
    getApiRqRsOLD(apiLog) {
        this.showHeader = false;
        this.logRqRs  ={};
        this.request  ='';
        this.response  ='';
        this.requestSql  ='';
        this.responseSql  ='';
        this.isSqlResponse  =false;
        this.showSql  =false;
        this.requestHeader = JSON.parse(apiLog.headerRequest)
        this.requestHeader = JSON.stringify(this.requestHeader, undefined, 8)
        this.responseHeader = JSON.parse(apiLog.headerResponse)
        this.responseHeader = JSON.stringify(this.responseHeader, undefined, 8)
        if (this.requestHeader == 'null') {
            this.requestHeader = ''
        }
        if (this.responseHeader == 'null') {
            this.responseHeader = ''
        }
        if (this.idLogApiDetail != apiLog.id) {
          this.utilsService.loadingShow();
          this.apiLogService.apiLogGetRqRs(apiLog.id).subscribe(
              getApiRqRs => {
                    this.logApiDetail = apiLog;
                    this.idLogApiDetail = apiLog.id;
                    this.logRqRs = getApiRqRs;

                    this.request = getApiRqRs.request ?? '';
                    this.response = getApiRqRs.response ?? '';
                    // Beautify JSON request/ response
                    if ((getApiRqRs.format ?? '') == 'json') {
                        try {
                            this.request = JSON.parse(this.request)
                            this.request = JSON.stringify(this.request, undefined, 8)
                        } catch (error) {}

                        try {
                            this.response = JSON.parse(this.response)
                            this.response = JSON.stringify(this.response, undefined, 8)
                        } catch (error) {}
                    }
                    // Beautify SQL response (JSON)
                    if ((getApiRqRs.format ?? '') == 'sql') {
                        try {
                            this.requestSql = this.request;
                        } catch (error) {}
                        try {
                            this.response = JSON.parse(this.response)
                            this.responseSql = this.response
                            this.response = JSON.stringify(this.response, undefined, 8)
                            this.isSqlResponse = true;
                            this.showSql = true;
                        } catch (error) {}
                    }


                this.utilsService.loadingHide();
              },
              error => {
                this.utilsService.loadingHide();
                this.notifyService.showError(error.error.message ?? (error.message ?? ''),"")
              }
          )
        } else {
          this.idLogApiDetail = null;
        }
    }
    getApiRqRs(apiLog:any) {
        this.utilsService.loadingShow();
        this.apiLogGetRqRsSubscription = this.apiLogService.apiLogGetRqRs(apiLog.id).subscribe(
            getApiRqRs => {
                const modalRef = this.modalService.open(ApiLogRqRsComponent,{ size: apiLog.format == 'sql' ? 'xl' : 'lg'});
                modalRef.componentInstance.apiLog = apiLog;
                modalRef.componentInstance.logId = apiLog.id;
                modalRef.componentInstance.logRqRs = getApiRqRs;
                this.utilsService.loadingHide();
            },
            error => {
                this.utilsService.loadingHide();
                this.notifyService.showError(error.error.message ?? (error.message ?? ''),"")
            }
        )
    }
    copyRqRs(content) {
        navigator.clipboard.writeText(content).then(function(){});
        this.notifyService.showInfo('Contenu copié','')
    }
    showHeaders(show:boolean) {
        this.showHeader = show;
    }
    showText(show:boolean) {
        this.showSql = show;
    }
    ngOnDestroy() {
        if (this.apiLogGetRqRsSubscription) {
            this.apiLogGetRqRsSubscription.unsubscribe();
        }
    }

}
