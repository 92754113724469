<div class="row">
    <div class="col-12">
        <div class="card shadow-none">
            <div class="header">
                <i class="ft-chevron-{{searchEngineHidden ? 'down' : 'up'}} accordion-button-up" (click)="showHideSearchEngine()"></i>
                <h6 class="text-white ml-2 title-header" >Temps des générations fichiers</h6>
            </div>
            <div class="card-content">
                <div class="card-body card-search-engine" [hidden]="searchEngineHidden">
                    <form (ngSubmit)="searchFileGeneration();">
                        <div class="row" style="display: flex!important;">
                            <!--Type de fichier-->
                            <div class="col-lg-3">
                                <div class="mb-3">
                                    <label>
                                        Type de fichier
                                        <ng-select [loading]="loadingFileType" [clearable]="false" [notFoundText]="'Aucun type de fichier'" [markFirst]="false" [searchable]="(fileTypeList$ | async)?.length  > 10" [items]="fileTypeList$ | async" id="fileType" labelForId="fileType" [ngModelOptions]="{standalone: true}" bindValue="code" bindLabel="name" placeholder="Type de fichier" [(ngModel)]="fileType">
                                        </ng-select>
                                    </label>
                                </div>
                            </div>
                            <!-- Marque -->
                            <div class="col-lg-3">
                                <div class="mb-3">
                                    <label>
                                        Marque
                                        <ng-select [loading]="loadingBrand" [clearable]="false" [notFoundText]="'Aucune marque'" [markFirst]="false" [searchable]="(brandList$ | async)?.length  > 10" [items]="brandList$ | async" id="brand" labelForId="brand" [ngModelOptions]="{standalone: true}" bindValue="code" bindLabel="name" placeholder="Marque" [(ngModel)]="brand">
                                        </ng-select>
                                    </label>
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <div class="mb-3">
                                    <label for="dateFrom">Date debut</label>
                                    <div class="form-group">
                                        <div class="input-group">
                                            <input class="form-control" id="dateFrom" readonly name="dp" [(ngModel)]="dateFrom" ngbDatepicker
                                                   #logDateFrom="ngbDatepicker" [maxDate]="maxDate" (click)="logDateFrom.toggle()">
                                            <div class="input-group-append">
                                                <div class="input-group-text" (click)="logDateFrom.toggle()">
                                                    <i class="fa fa-calendar" style="cursor: pointer;"></i>
                                                </div>
                                            </div>
                                            &nbsp;
                                            <ngb-timepicker [(ngModel)]="timeFrom" [seconds]="false" [hourStep]="1" [minuteStep]="30" [ngModelOptions]="{standalone: true}"></ngb-timepicker>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <div class="mb-3">
                                    <label for="dateFrom">Date Fin</label>
                                    <div class="form-group">
                                        <div class="input-group">
                                            <input class="form-control" id="dateTo" readonly name="dp" [(ngModel)]="dateTo" ngbDatepicker
                                                   #logDateTo="ngbDatepicker" [minDate]="dateFrom" [maxDate]="maxDate" (click)="logDateTo.toggle()">
                                            <div class="input-group-append">
                                                <div class="input-group-text" (click)="logDateTo.toggle()">
                                                    <i class="fa fa-calendar" style="cursor: pointer;"></i>
                                                </div>
                                            </div>
                                            &nbsp;
                                            <ngb-timepicker [(ngModel)]="timeTo" [seconds]="false" [hourStep]="1" [minuteStep]="30" [ngModelOptions]="{standalone: true}"></ngb-timepicker>

                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <button class="btn btn-primary btn-float-right" type="submit" id="search">
                                    <i class="ft-search mr-1"></i>Rechercher
                                </button>
                            </div>
                        </div>
                    </form>
                </div>

                <div class="card-body" style="position: relative">
                    <h4 class="card-title">{{totalCount}} génération{{totalCount>1 ? 's' : ''}}</h4>
                    <div class="cont-alert-duration">
                        Durée d'alerte : <input type="number" class="form-control" [(ngModel)]="alertDurationMinute" size="3" (change)="saveGenerationAlertDuration()" >
                        minutes
                    </div>
                    <div class="table-responsive">
                        <table class="table table-striped table-bordered selection-deletion-row" >
                            <thead >
                            <tr>
                                <th  style="width: 11% !important; min-width: 170px;">Marque</th>
                                <th  style="width: 11% !important; min-width: 170px;">Fichier</th>
                                <th  style="width: auto !important; min-width: 170px;">Client</th>
                                <th  style="width: 11% !important; min-width: 220px;">Début lancement</th>
                                <th  style="width: 10% !important;min-width: 200px;">Durée</th>
                                <th  style="width: 7% !important;min-width: 180px;">Lancement</th>
                                <th  style="width: 5% !important;min-width: 80px;">Logs</th>
                            </tr>
                            </thead>
                            <tbody *ngIf="cronExecutionList?.length != 0" >
                            <tr *ngFor="let cronExecution of cronExecutionList" class="{{cronExecution.durationMinute > alertDurationMinute ? 'alert-time' : ''}}" >
                                <td>{{brandName}}</td>
                                <td>{{fileTypeName}}</td>
                                <td>
                                    {{fileGenerationList[cronExecution.data2]?.requesterCode ?? ''}} -
                                    {{fileGenerationList[cronExecution.data2]?.requesterName ?? ''}}
                                    ({{fileGenerationList[cronExecution.data2]?.backofficeCode ?? ''}})
                                </td>
                                <td >
                                    {{(cronExecution.startDateTime  | date:'dd/MM/YYYY') | dateText}}
                                    {{cronExecution.startDateTime  | date:'HH:mm:ss'}}
                                </td>
                                <td>
                                    {{getDiffDays(cronExecution.endDateTime,cronExecution.startDateTime)}}
                                </td>
                                <td >
                                    <span class="badge bg-{{cronExecution.isAutomatic ? 'success' : 'info'}}">
                                      {{cronExecution.isAutomatic ? 'Automatique' : 'Manuel'}}
                                    </span>
                                    <div *ngIf="cronExecution.userName" style="position: relative;bottom: -5px;">
                                        {{cronExecution.userName}}
                                    </div>
                                </td>
                                <td style="text-align: center">
                                    <i class="ft-arrow-down" (click)="getSteps(cronExecution.id)"></i>
                                </td>
                            </tr>
                            </tbody>
                            <tbody *ngIf="cronExecutionList?.length == 0" >
                            <tr>
                                <td class="no-data-available" colspan="8" >Aucune exécution</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <!--/ Kick start -->
    </div>
</div>
