import {Component, OnDestroy, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {NotificationService} from '../../shared/services/notification.service';
import {shareReplay, tap} from 'rxjs/operators';
import {UtilsService} from '../../shared/services/utils.service';
import {Observable, Subscription} from 'rxjs';
import {ParameterService} from '../../shared/services/midOffice/parameter.service';
import {NgDynamicBreadcrumbService} from 'ng-dynamic-breadcrumb';
import {NgbDateStruct, NgbModal, NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';
import {CronService} from '../../shared/services/midOffice/cron.service';
import {CronLogService} from '../../shared/services/log/cron-log.service';
import {FileGenerationService} from '../../shared/services/midOffice/file-generation.service';
import {CronLogDetailComponent} from '../../cron/cron-log-detail/cron-log-detail.component';
import {environment} from '../../../environments/environment';
const generationDurationKey = 'generationDuration';
@Component({
  selector: 'app-file-generation-time',
  templateUrl: './file-generation-time.component.html',
  styleUrls: ['./file-generation-time.component.scss']
})
export class FileGenerationTimeComponent implements OnInit, OnDestroy {

  cronExecutionList: any = null
  fileGenerationList: any =null
  totalCount: number = 0
  now: any
  fileType:string|null = 'cache-price';
  fileTypeName:string|null = '';
  brand:string|null = 'ovoyages';
  brandName:string|null = '';
  fileTypeList$: Observable<any[]>;
  brandList$: Observable<any[]>;
  loadingBrand: boolean = true;
  loadingFileType: boolean = true;
  searchEngineHidden: boolean = (window.innerWidth < 1200 )
  alertDurationMinute: number = 30

  dateNow: NgbDateStruct;
  maxDate: NgbDateStruct;
  dateFrom: NgbDateStruct;
  dateTo: NgbDateStruct;
  timeFrom: NgbTimeStruct = { hour: 0, minute: 0, second: 0 };
  timeTo: NgbTimeStruct = { hour: 23, minute: 59, second: 0 };
  getCronLogSubscription: Subscription
  getFileGenerationsSubscription: Subscription
  constructor(private cronService:CronService, private cronLogService: CronLogService,private fileGenerationService: FileGenerationService,private modalService: NgbModal,private parameterService: ParameterService,private title: Title,private notifyService : NotificationService,private utilsService: UtilsService,private ngDynamicBreadcrumbService: NgDynamicBreadcrumbService){

  }
  ngOnInit(): void {
    this.now = new Date(new Date().toLocaleString("en-US", {timeZone: "Europe/Paris"}));
    this.title.setTitle((environment.server != 'PRODUCTION' ? environment.server + ' - ' : '') + "Temps des générations fichiers XFT - MidOffice Thalasso N°1");
    this.utilsService.loadingShow()
    this.brandList$ = this.parameterService.getBrands().pipe(tap(() => this.loadingBrand = false),shareReplay(1));
    this.fileTypeList$ = this.parameterService.getFileType().pipe(tap(() => this.loadingFileType = false),shareReplay(1));
    const breadcrumbs  =  [
      {
        label: 'Accueil',
        url: '/'
      },
      {
        label: 'Générations fichiers',
        url: ''
      },
      {
        label: 'Liste générations fichiers',
        url: ''
      }
    ];
    this.ngDynamicBreadcrumbService.updateBreadcrumb(breadcrumbs);
    this.dateNow = {year: this.now.getFullYear(), month: this.now.getMonth() + 1, day: this.now.getDate()};
    this.dateFrom = this.dateNow;
    this.dateTo = this.dateNow;
    this.maxDate = this.dateNow;
    this.searchFileGeneration()
    if(!isNaN(parseInt(localStorage.getItem(generationDurationKey)))){
      this.alertDurationMinute = parseInt(localStorage.getItem(generationDurationKey));
    }
  }
  ngOnDestroy() {
    if (this.getCronLogSubscription) {
      this.getCronLogSubscription.unsubscribe();
    }
    if (this.getFileGenerationsSubscription) {
      this.getFileGenerationsSubscription.unsubscribe();
    }

  }
  searchFileGeneration(){
    // Brand name
    this.brandList$.subscribe(brandList => {
      brandList.forEach(brand => {
        if(brand.code == this.brand) {
          this.brandName = brand.name;
        }
      });
    });
    // File type name
    this.fileTypeList$.subscribe(fileTypeList => {
      fileTypeList.forEach(fileType => {
        if(fileType.code == this.fileType) {
          this.fileTypeName = fileType.name;
        }
      });
    });
    this.utilsService.loadingShow()
    let cronCode = 'xft-' + this.fileType + '-' + this.brand;


    this.getFileGenerationsSubscription = this.fileGenerationService.getFileGenerations(this.fileType,this.brand,null,true).subscribe(
        fileGenerationList => {
          this.fileGenerationList = {};
          for (let item in fileGenerationList) {
            this.fileGenerationList[fileGenerationList[item].requesterCode] = fileGenerationList[item];
          }
          console.log(this.fileGenerationList)
        },
        error => {
          this.fileGenerationList = {};
          this.notifyService.showError(error.error.message ?? (error.message ?? ''),"")
        }
    )


    this.cronExecutionList = [];
    this.getCronLogSubscription = this.cronLogService.getCronLog(cronCode ?? '',this.utilsService.dateTimePickerToDateTime(this.dateFrom, this.timeFrom),this.utilsService.dateTimePickerToDateTime(this.dateTo, this.timeTo),null,null,null,null,null,1,999).subscribe(
        cronExecutionList => {
          for (let item in cronExecutionList.cronExecutionLog) {
            if(cronExecutionList.cronExecutionLog[item].endDateTime !== null) {
              cronExecutionList.cronExecutionLog[item].duration = cronExecutionList.cronExecutionLog[item].updateTimeStamp - cronExecutionList.cronExecutionLog[item].startTimeStamp;
              cronExecutionList.cronExecutionLog[item].durationMinute = cronExecutionList.cronExecutionLog[item].duration / 60;
              this.cronExecutionList.push(cronExecutionList.cronExecutionLog[item]);
            }
          }
          this.sortArrayByProperty('duration');
          this.totalCount = this.cronExecutionList.length
          this.utilsService.loadingHide()
          //console.log(this.cronExecutionList)
        },
        error => {
          this.cronExecutionList = [];
          this.utilsService.loadingHide()
          this.notifyService.showError(error.error.message ?? (error.message ?? ''),"")
        }
    )
  }
  getDiffDays(date1, date2): string {
    if(!date1 || !date2){
      return '-';
    }
    // Convertir les dates en millisecondes depuis l'Époque Unix (1 janvier 1970)
    let startDate = new Date(date1);
    let endDate = new Date(date2);

    const time1 = startDate.getTime();
    const time2 = endDate.getTime();

    // Calculer la différence en millisecondes
    const differenceInMilliseconds = Math.abs(time1 - time2);

    // Convertir la différence en secondes
    let differenceInSeconds = differenceInMilliseconds / 1000;

    if (differenceInSeconds < 0) {
      return 'Temps invalide';
    }

    const minutes = Math.floor(differenceInSeconds / 60);
    const remainingSeconds = differenceInSeconds % 60;

    if (minutes === 0) {
      return `${remainingSeconds} sec`;
    } else {
      if (remainingSeconds === 0) {
        return `${minutes} min`;
      } else {
        return `${minutes} min - ${remainingSeconds} sec`;
      }
    }
  }
  showHideSearchEngine(){
    this.searchEngineHidden = !this.searchEngineHidden;
  }
  getSteps(id:number) {
    let cronCode = 'xft-' + this.fileType + '-' + this.brand;
    const modalRef = this.modalService.open(CronLogDetailComponent,{ size: 'lg'});
    modalRef.componentInstance.id = id;
    modalRef.componentInstance.code = cronCode;
  }
  sortArrayByProperty(propertyName: string) {
    // Utilisez la méthode sort avec une fonction de comparaison.
    this.cronExecutionList.sort((a, b) => {
      // Compare les valeurs de la propriété spécifiée.
      if (a[propertyName] > b[propertyName]) {
        return -1;
      } else if (a[propertyName] < b[propertyName]) {
        return 1;
      } else {
        return 0;
      }
    });
  }
  saveGenerationAlertDuration(){
    localStorage.setItem(generationDurationKey, this.alertDurationMinute.toString());
  }
}
