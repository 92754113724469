import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
@Pipe({
  name: 'dateAsAgoPipe'
})
export class DateAsAgoPipePipe implements PipeTransform {

  transform(value: any): unknown {


    let now = new Date();
    let nowTime = new Date(now.toISOString().slice(0, -1)).getTime();

    //let now = new Date(new Date().toLocaleString("en-US", {timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone})).getTime();
    //let nowTimezone = moment.tz(now, Intl.DateTimeFormat().resolvedOptions().timeZone).format();
    //let nowDate = new Date(nowTimezone);
    //let nowTime = new Date(nowDate.toISOString().slice(0, -1)).getTime();


    if (!value) { return 'a long time ago'; }

    //const dateInNewYork = moment.tz(value, 'Europe/Paris').tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format();
    const dateInNewYork = value;
    let notificationDate = new Date(dateInNewYork);
    let notificationTime = new Date(notificationDate.toISOString().slice(0, -1)).getTime();

    let time = (nowTime - notificationTime) / 1000;
    if (time < 30) {
      return 'À l\'instant';
    }
    const divider = [60, 60, 24, 30, 12];
    const string = [' seconde', ' minute', ' heure', ' jour', ' mois', ' année'];
    let i;
    for (i = 0; Math.floor(time / divider[i]) > 0; i++) {
      time /= divider[i];
    }
    let plural = Math.floor(time) > 1 ? 's' : '';
    if (string[i] == ' mois') {
      plural = '';
    }
    return 'Il y a ' + Math.floor(time) + string[i] + plural;
  }

}
