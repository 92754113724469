<div class="modal-header">
  <h4 class="modal-title">Log</h4>
  <button type="button" style="border: 0; background: none" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"><i class="ft-x-circle" ></i></button>
</div>
<div class="modal-body">
  <span class="" style="font-size: 12px;float: right;cursor: pointer;font-style: italic; text-decoration: underline;" (click)="getApiLog()" >Log API</span>
  <table class="table table-striped table-bordered selection-deletion-row xft-transaction-log">
    <tr>
      <td style="width: 170px">Statut :</td>
      <td style="width: auto">
        <span class="badge badge-{{xftTransactionLog.isError ? 'danger' :  (xftTransactionLog.isWarning ?'warning' : 'success')}} ">
        {{xftTransactionLog.isError ? 'Erreur' :  (xftTransactionLog.isWarning ?'Alerte' : 'OK')}}
      </span>
      </td>
    </tr>
    <tr>
      <td>Code :</td>
      <td>{{xftTransactionLog.errorWarningCode}}</td>
    </tr>
    <tr>
      <td>Message :</td>
      <td [innerHTML]="xftTransactionLog.errorWarningMessage"></td>
    </tr>
    <div *ngIf="xftTransactionLog.errorWarningDescription?.includes('http');then withLink else noLink"></div>
    <ng-template #noLink>
      <tr>
        <td>Description :</td>
        <td [innerHTML]="xftTransactionLog.errorWarningDescription"></td>
      </tr>
    </ng-template>
    <ng-template #withLink>
      <tr>
        <td>Détail erreur :</td>
        <td>
          <a [href]="xftTransactionLog.errorWarningDescription" target="_blank">{{xftTransactionLog.errorWarningDescription}}</a>
        </td>
      </tr>
    </ng-template>


    <tr>
      <td>Raison non dispo :</td>
      <td [innerHTML]="xftTransactionLog.errorWarningUnavailabilityMessage"></td>
    </tr>
  </table>
</div>
