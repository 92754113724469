<div class="modal-header">
  <h4 class="modal-title">{{message == 'edit' ? parameterValue.name : 'Nouvelle valeur'}}</h4>

  <button type="button" style="border: 0; background: none" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"><i class="ft-x-circle" ></i></button>
</div>
<div class="modal-body">
  <form  method="post" name="parameterValue" #AddEditForm="ngForm" (ngSubmit)="onSubmit(AddEditForm);">
    <div class="card-body">
      <div class="row">
        <!-- code -->
        <div class="col-lg-3">
          <div class="mb-3">
            <label for="code">Code <span class="red" >*</span></label>
            <input [ngClass]="{'is-invalid' : !code.valid && !code.pristine}" class="form-control" id="code" maxlength="30" name="code"  placeholder="Code" [(ngModel)]="parameterValue.code"   #code="ngModel" required minlength="3" type="text">
            <div [hidden]="code.valid || code.pristine" class="invalid-feedback">
              Le code est obligatoire (min 3 caractères)
            </div>
          </div>
        </div>

        <!-- Nom -->
        <div class="col-lg-3">
          <div class="mb-3">
            <label for="name">Nom <span class="red" >*</span></label>
            <input [ngClass]="{'is-invalid' : !name.valid && !name.pristine}" class="form-control" id="name" maxlength="100" name="name" ngModel placeholder="Nom" #name="ngModel" [(ngModel)]="parameterValue.name"  required minlength="3" type="text" autocomplete="off">
            <div [hidden]="name.valid || name.pristine" class="invalid-feedback">
              Le nom est obligatoire (min 3 caractères)
            </div>
          </div>
        </div>
        <!-- parentCode -->
        <div class="col-lg-3">
          <div class="mb-3">
            <label for="parentCode">ParentCode</label>
            <input  class="form-control" id="parentCode" maxlength="30" name="parentCode"  placeholder="ParentCode" [(ngModel)]="parameterValue.parentCode"    type="text">
          </div>
        </div>

        <div class="col-lg-3">
          <div class="mb-3">
            <label for="name">Ordre</label>
            <input [ngClass]="{'is-invalid' : !rank.valid && !rank.pristine}" class="form-control" id="rank" maxlength="100" name="rank" ngModel placeholder="Ordre" #rank="ngModel" [(ngModel)]="parameterValue.rank"  type="number">
            <div [hidden]="rank.valid || rank.pristine" class="invalid-feedback">
              Le nom est obligatoire (min 3 caractères)
            </div>
          </div>
        </div>
        <!-- isActive -->
        <div class="col-lg-3">
          <div class="custom-switch mb-3">
            <br>
            <input  type="checkbox" id="status-switch" name="status" ngModel [(ngModel)]="parameterValue.isActive" class="custom-control-input form-control">
            <label for="status-switch" class="custom-control-label mr-1">
              <span>Actif</span>
            </label>
          </div>
        </div>
      </div>
      <div class="row" style="clear: both">
        <!-- External code -->
        <div class="col-lg-3">
          <div class="mb-3">
            <label for="code">Référence externe</label>
            <input [ngClass]="{'is-invalid' : !externalCode.valid && !externalCode.pristine}" class="form-control" id="externalCode" maxlength="30" name="externalCode"  placeholder="Référence externe" [(ngModel)]="parameterValue.externalCode"   #externalCode="ngModel"  type="text">
          </div>
        </div>
        <!-- Data 1 -->
        <div class="col-lg-3">
          <div class="mb-3">
            <label for="code">Donnée 1</label>
            <input [ngClass]="{'is-invalid' : !data1.valid && !data1.pristine}" class="form-control" id="data1" maxlength="30" name="data1"  placeholder="Donnée 1" [(ngModel)]="parameterValue.data1"   #data1="ngModel"  type="text">
          </div>
        </div>
        <!-- Data 2 -->
        <div class="col-lg-3">
          <div class="mb-3">
            <label for="code">Donnée 2</label>
            <input [ngClass]="{'is-invalid' : !data2.valid && !data2.pristine}" class="form-control" id="data2" maxlength="30" name="data2"  placeholder="Donnée 2" [(ngModel)]="parameterValue.data2"   #data2="ngModel"  type="text">
          </div>
        </div>
        <!-- Data 3 -->
        <div class="col-lg-3">
          <div class="mb-3">
            <label for="code">Donnée 3</label>
            <input [ngClass]="{'is-invalid' : !data3.valid && !data3.pristine}" class="form-control" id="data3" maxlength="30" name="data3"  placeholder="Donnée 3" [(ngModel)]="parameterValue.data3"   #data3="ngModel"  type="text">
          </div>
        </div>

        <div class="col-lg-12">
            <button class="pull-right btn btn-primary waves-effect btn-label waves-light modal-wait blocRightBottom" type="submit"  [disabled]="!AddEditForm.form.valid">
              <i class="ft-save"></i> Enregistrer
            </button>
        </div>
      </div>
    </div>
  </form>
</div>
