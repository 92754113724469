import {ActivatedRoute, Router} from '@angular/router';
import { Injectable } from '@angular/core';
import firebase from 'firebase/app'
import { Observable } from 'rxjs';
import {TokenService} from '../services/token.service';
import {EditoService} from '../services/edito/edito.service';

@Injectable()
export class AuthService {
  private user: Observable<firebase.User>;

  constructor(public router: Router,private activatedRoute: ActivatedRoute,private route: ActivatedRoute, private editoService: EditoService, private tokenService: TokenService) {
  }

  logout() {
    this.tokenService.clearToken();
    this.editoService.redirectToLogin();
  }

  isAuthenticated() {
    return (this.tokenService.getToken() && !this.tokenService.isExpired())
  }
}
