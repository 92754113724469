import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import {Observable, Subscription} from 'rxjs';
import {GenerationHourPipe} from '../../../shared/pipes/generation-hour.pipe';
import {FileGenerationService} from '../../../shared/services/midOffice/file-generation.service';
import {ParameterService} from '../../../shared/services/midOffice/parameter.service';
import {XftUserService} from '../../../shared/services/midOffice/xft-user.service';
import {NotificationService} from '../../../shared/services/notification.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {shareReplay, tap} from 'rxjs/operators';
import {UtilsService} from '../../../shared/services/utils.service';

@Component({
  selector: 'app-file-generation-form',
  templateUrl: './file-generation-form.component.html',
  styleUrls: ['./file-generation-form.component.scss']
})
export class FileGenerationFormComponent implements OnInit, OnDestroy {
  @Input() fileGeneration: any = {} ;
  @Input() message: string | undefined;
  @Input() xftUserList$: Observable<any[]>;
  @Input() fileType: string | undefined;
  @Input() brand: string | undefined;
  generationHours: Array<number> = [];
  fileTypeList$: Observable<any[]>;
  brandList$: Observable<any[]>;

  loadingFileType: boolean = false;
  loadingBrand: boolean = false;
  addEditFileGenerationSubscription: Subscription
  constructor(private fileGenerationService: FileGenerationService,private parameterService: ParameterService,private utilsService: UtilsService,private xftUserService: XftUserService,private notifyService : NotificationService,public activeModal: NgbActiveModal) { }
  ngOnInit(): void {
    this.loadingFileType = true;
    this.loadingBrand = true;
    this.generationHours = Array(24).fill(1).map((x,i)=>i);
    this.fileTypeList$ = this.parameterService.getFileType().pipe(tap(() => this.loadingFileType = false),shareReplay(1));
    this.brandList$ = this.parameterService.getBrands().pipe(tap(() => this.loadingBrand = false),shareReplay(1));
    // If new file generation > init with search engine value
    if(this.message == 'add'){
      if(this.fileType){
        this.fileGeneration.fileType = this.fileType
      }
      if(this.fileType){
        this.fileGeneration.brand = this.brand
      }
    }
  }
  ngOnDestroy() {
    if (this.addEditFileGenerationSubscription) {
      this.addEditFileGenerationSubscription.unsubscribe();
    }
  }
  onSubmit(AddEditForm: NgForm) {
    if(AddEditForm.valid
      && this.fileGeneration.fileType !== undefined && this.fileGeneration.fileType !== null
      && this.fileGeneration.brand !== undefined && this.fileGeneration.brand !== null
      && this.fileGeneration.requesterCode !== undefined && this.fileGeneration.requesterCode !== null
    ) {
      this.utilsService.loadingShow();
      this.addEditFileGenerationSubscription = this.fileGenerationService.addEditFileGeneration(this.fileGeneration?.id ?? null,this.fileGeneration).subscribe(
        fileGeneration => {
          this.fileGeneration = fileGeneration;
          if (this.message == 'add') {
            this.notifyService.showSuccess('Création effectuée avec succès',"")
          } else {
            this.notifyService.showSuccess('Mise à jour effectuée avec succès',"")
          }
          this.activeModal.close()
        },
        error => {
          this.utilsService.loadingHide();
          this.notifyService.showError(error.error.message ?? (error.message ?? ''),"")
        }
      )
    } else {
      this.notifyService.showError('Veuillez remplir tous les champs obligatoire',"")
    }
  }

  hasHour(Hour: string): boolean | undefined{
    if(this.fileGeneration.schedule !== undefined)
      return this.fileGeneration.schedule.includes(Hour) ?? false
    else
      return false;
  }
  selectHour($event: Event, hourInt: number) {
    let hour = new GenerationHourPipe().transform(hourInt);
    if(this.fileGeneration.schedule === undefined) {
      this.fileGeneration.schedule = [];
    }
    const  isChecked = ($event.target as HTMLInputElement).checked;
    if(isChecked){
      this.fileGeneration.schedule.push(hour)
    }else {
      const index = this.fileGeneration!.schedule.indexOf(hour);
      this.fileGeneration!.schedule.splice(index,1)
    }
  }
  customSearchClient(term: string, item) {
    term = term.toLocaleLowerCase();
    return item.requesterCode.toString().toLocaleLowerCase().indexOf(term) > -1 || item.name.toLocaleLowerCase().indexOf(term) > -1 || item.backofficeCode.toLocaleLowerCase().indexOf(term) > -1;
  }
}
