<div class="row">
    <div class="col-12">
        <div class="card shadow-none">
            <div class="header">
                <i class="ft-chevron-{{searchEngineHidden ? 'down' : 'up'}} accordion-button-up" (click)="showHideSearchEngine()"></i>
                <h6 class="text-white ml-2 title-header" >Paramètre : {{parameter?.name}}</h6>
                <i class="ft-plus-circle btn-add" (click)="addEdit(parameterCode)" title="Nouvelle valeur"></i>
            </div>
            <div class="card-content">
                <div class="card-body card-search-engine" [hidden]="searchEngineHidden">
                    <form (ngSubmit)="onSubmit();">
                        <div class="row" style="display: flex!important;">
                            <!-- CODE -->
                            <div class="col-md-3">
                                <div class="form-floating mb-3">
                                    <label for="code">code</label>
                                    <fieldset class="form-group">
                                        <input class="form-control" id="code" autocomplete="off" name="code"  type="text" [(ngModel)]="code" value="">
                                    </fieldset>
                                </div>
                            </div>
                            <!-- NAME -->
                            <div class="col-md-3">
                                <div class="form-floating mb-3">
                                    <label for="name">Nom</label>
                                    <fieldset class="form-group">
                                        <input class="form-control" id="name" autocomplete="off" name="name"  type="text" [(ngModel)]="name" value="">
                                    </fieldset>
                                </div>
                            </div>
                            <!-- EXTERNAL CODE -->
                            <div class="col-md-3">
                                <div class="form-floating mb-3">
                                    <label for="name">Référence externe</label>
                                    <fieldset class="form-group">
                                        <input class="form-control" id="externalCode" name="externalCode" [(ngModel)]="externalCode" type="text" value="">
                                    </fieldset>
                                </div>
                            </div>
                            <!-- STATUS -->
                            <div class="col-md-3">
                                <div class="form-floating mb-3">
                                    <label>
                                        Etat
                                        <fieldset class="form-group">
                                            <ng-select [notFoundText]="'Aucun état'" [markFirst]="false" [searchable]="false" [items]="statusList" [ngModelOptions]="{standalone: true}" id="status" labelForId="status" bindValue="value" bindLabel="name" placeholder="Tous" [(ngModel)]="status">
                                            </ng-select>
                                        </fieldset>
                                    </label>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <button class="btn btn-primary btn-float-right" type="submit" id="search">
                                    <i class="ft-search mr-1"></i>Rechercher
                                </button>
                            </div>
                        </div>
                    </form>
                </div>

                <div class="card-header">
                    <h4 class="card-title">{{totalCount}} valeur{{totalCount>1 ? 's' : ''}}</h4>
                </div>
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table table-striped">
                            <thead class="thead-light">
                            <tr>
                                <th  style="width: 12% !important;min-width: 150px;">Code</th>
                                <th  style="width: auto !important;min-width: 200px;" >Nom</th>
                                <th  style="width: 12% !important;min-width: 150px;" >ParentCode</th>
                                <th  style="width: 12% !important;min-width: 150px;" >Référence externe</th>
                                <th  style="width: 12% !important;min-width: 150px;" >Data 1</th>
                                <th  style="width: 12% !important;min-width: 150px;" >Data 2</th>
                                <th  style="width: 12% !important;min-width: 150px;" >Data 3</th>
                                <th  style="width: 5% !important;">Ordre</th>
                                <th  style="width: 5% !important;">Actif</th>
                                <th style="width: 10% !important;"></th>
                            </tr>
                            </thead>
                            <!--<tbody *ngIf="parameterValueList?.length == 0" >
                              <img class="LoaderImages" src="../assets/img/load.gif" style="position: inherit; margin: 15px;">
                            </tbody>-->
                            <tbody *ngIf="parameterValueList?.length != 0" >
                            <tr *ngFor="let parameterValue of parameterValueList" >
                                <td >{{parameterValue.code}}</td>
                                <td>{{parameterValue.name}}</td>
                                <td >{{parameterValue.parentCode}}</td>
                                <td >{{parameterValue.externalCode}}</td>
                                <td >{{parameterValue.data1}}</td>
                                <td >{{parameterValue.data2}}</td>
                                <td >{{parameterValue.data3}}</td>
                                <!--<td >{{parameterValue.data1}}</td>
                                <td >{{parameterValue.data2}}</td>
                                <td >{{parameterValue.data3}}</td>-->
                                <td >{{parameterValue.rank}}</td>
                                <td >
                                    <i *ngIf="parameterValue.isActive" class="ft-check-circle" style="color: #40C057"></i>
                                    <i *ngIf="!parameterValue.isActive" class="ft-x-circle" style="color: red"></i>
                                </td>
                                <td style="text-align: right">
                                    <a (click)="addEdit(parameterCode, parameterValue)" title="Éditer">
                                        <i class="ft-edit-2" style="margin: 5px; color:green;"></i>
                                    </a>
                                    <a (click)="deleteParameterValue(parameterCode,parameterValue.code)" title="Supprimer">
                                        <i class="ft-x" style="margin: 5px; color:red;"></i>
                                    </a>
                                    <a (click)="auditLog(parameterCode,parameterValue.code)" title="Log modification">
                                        <i class="ft-layers" style="margin: 5px; color:grey;"></i>
                                    </a>

                                </td>
                            </tr>
                            </tbody>
                            <tbody *ngIf="parameterValueList?.length == 0" >
                            <tr>
                                <td class="no-data-available" colspan="9">Aucune valeur</td>
                            </tr>
                            </tbody>
                        </table>
                        <div *ngIf="pageCount > 1">
                            <ngb-pagination [collectionSize]="totalCount" [pageSize]="pageSize" (pageChange)="paginate($event)" [(page)]="page" [maxSize]="3" [rotate]="true" [boundaryLinks]="true"></ngb-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--/ Kick start -->
    </div>
</div>
