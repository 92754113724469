import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'generationHour'
})
export class GenerationHourPipe implements PipeTransform {

  transform(Hour: any, format: string | null = 'long'): string {
    let hourFormatted = Hour.toString();
    if(Hour < 10 )
      hourFormatted = '0'+hourFormatted;
    if (format == 'long') {
      hourFormatted = hourFormatted + ':00';
    }
    return hourFormatted
  }

}
