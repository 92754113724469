import {Component, Input, OnDestroy} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {XftTransactionApiLogComponent} from '../xft-transaction-api-log/xft-transaction-api-log.component';
import {UtilsService} from '../../shared/services/utils.service';
import {ApiLogService} from '../../shared/services/log/api-log.service';
import {Subscription} from 'rxjs';
import {NotificationService} from '../../shared/services/notification.service';

@Component({
  selector: 'app-xft-transaction-log-error-warning-detail',
  templateUrl: './xft-transaction-log-error-warning-detail.component.html',
  styleUrls: ['./xft-transaction-log-error-warning-detail.component.scss']
})
export class XftTransactionLogErrorWarningDetailComponent implements OnDestroy {
  @Input() xftTransactionLog: any ={};
  @Input() profilId: string;
  apiLogSearchSubscription: Subscription
  constructor(public activeModal: NgbActiveModal,private notifyService : NotificationService,private utilsService : UtilsService,private apiLogService: ApiLogService,private modalService: NgbModal) {

  }
  ngOnDestroy() {
    if (this.apiLogSearchSubscription) {
      this.apiLogSearchSubscription.unsubscribe();
    }
  }
  getApiLog(){
    this.utilsService.loadingShow();
    this.apiLogSearchSubscription = this.apiLogService.apiLogSearch(this.profilId ?? 'xxx').subscribe(
        getApiLog => {
          const modalRef = this.modalService.open(XftTransactionApiLogComponent,{ size: 'xl'});
          let apiLogList = [];
          for (let item in getApiLog.apiLog) {
            apiLogList.push(getApiLog.apiLog[item]);
          }
          modalRef.componentInstance.apiLogList = apiLogList;
          this.utilsService.loadingHide();
        },
        error => {
          this.utilsService.loadingHide();
          this.notifyService.showError(error.error.message ?? (error.message ?? ''),"")
        }
    )
  }
}
