import {Component, OnInit} from '@angular/core';
import {NgbDateStruct, NgbModal, NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';
import {CronLogService} from '../../shared/services/log/cron-log.service';
import {NotificationService} from '../../shared/services/notification.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UtilsService} from '../../shared/services/utils.service';
import {CronLogDetailComponent} from '../cron-log-detail/cron-log-detail.component';
import {GlobalConstants} from '../../shared/global-constants';
import {CronService} from '../../shared/services/midOffice/cron.service';
import {NgDynamicBreadcrumbService} from 'ng-dynamic-breadcrumb';
import {Title} from '@angular/platform-browser';
import {Subscription} from 'rxjs';
import {environment} from '../../../environments/environment';




@Component({
  selector: 'app-cron-log',
  templateUrl: './cron-log.component.html',
  styleUrls: ['./cron-log.component.scss']
})
export class CronLogComponent implements OnInit {
  now: any
  cron: any = [];
  cronExecutionList: any = null
  pageSize: number;
  page: number = 1;
  totalCount: number = 0
  pageCount: number = 1

  dateNow: NgbDateStruct;
  maxDate: NgbDateStruct;
  dateFrom: NgbDateStruct;
  dateTo: NgbDateStruct;
  timeFrom: NgbTimeStruct = { hour: 0, minute: 0, second: 0 };
  timeTo: NgbTimeStruct = { hour: 23, minute: 59, second: 0 };

  isAutomatic: boolean|null;
  status: string|null = '';
  data1: string|null = '';
  data2: string|null = '';
  data3: string|null = '';
  searchEngineHidden: boolean = (window.innerWidth < 1200 )
  getCronSubscription: Subscription
  getCronLogSubscription: Subscription
  constructor(private cronService:CronService, private cronLogService: CronLogService,private title: Title,private notifyService : NotificationService,private route: ActivatedRoute,private utilsService : UtilsService,private modalService: NgbModal,private router: Router,private ngDynamicBreadcrumbService: NgDynamicBreadcrumbService) { }

  ngOnInit(): void {
    this.now = new Date(new Date().toLocaleString("en-US", {timeZone: "Europe/Paris"}));
    this.pageSize = GlobalConstants.pageSize;
    this.utilsService.loadingShow()
    let code = this.route.snapshot.paramMap.get('code');
    this.getCronSubscription = this.cronService.getCron(code ?? '').subscribe(
      cron => {
        this.cron = cron;
        const breadcrumbs  =  [
          {
            label: 'Accueil',
            url: '/'
          },
          {
            label: 'Crons',
            url: '/cron'
          },
          {
            label: this.cron?.name,
            url: ''
          },
          {
            label: 'Log exécution',
            url: ''
          }
        ];
        this.ngDynamicBreadcrumbService.updateBreadcrumb(breadcrumbs);
        this.title.setTitle((environment.server != 'PRODUCTION' ? environment.server + ' - ' : '') + "Log execution cron "+(this.cron?.name)+" - MidOffice Thalasso N°1");
        this.utilsService.loadingHide()
      },
      error => {
        this.utilsService.loadingHide()
        this.router.navigate(['/cron']).then();
        this.notifyService.showError(error.error.message ?? (error.message ?? ''),"")
      }
    )
    this.dateNow = {year: this.now.getFullYear(), month: this.now.getMonth() + 1, day: this.now.getDate()};
    this.dateFrom = this.dateNow;
    this.dateTo = this.dateNow;
    this.maxDate = this.dateNow;
    this.searchCronLog()
  }
  ngOnDestroy() {
    if (this.getCronSubscription) {
      this.getCronSubscription.unsubscribe();
    }
    if (this.getCronLogSubscription) {
      this.getCronLogSubscription.unsubscribe();
    }
  }
  onSubmit(){
    this.page = 1;
    this.searchCronLog();
  }
  searchCronLog() {
    this.utilsService.loadingShow()
    let code = this.route.snapshot.paramMap.get('code');
    this.getCronLogSubscription = this.cronLogService.getCronLog(code ?? '',this.utilsService.dateTimePickerToDateTime(this.dateFrom, this.timeFrom),this.utilsService.dateTimePickerToDateTime(this.dateTo, this.timeTo),this.status,this.isAutomatic,this.data1,this.data2,this.data3,this.page).subscribe(
      cronExecutionList => {
        this.cronExecutionList = [];
        this.totalCount = cronExecutionList.totalCount;
        this.pageCount = cronExecutionList.nbPage;
        for (let item in cronExecutionList.cronExecutionLog) {
          this.cronExecutionList.push(cronExecutionList.cronExecutionLog[item]);
        }
        this.utilsService.loadingHide()
      },
      error => {
        this.cronExecutionList = [];
        this.utilsService.loadingHide()
        this.notifyService.showError(error.error.message ?? (error.message ?? ''),"")
      }
    )
  }
  paginate(page: number|null = null) {
    this.page = page ?? this.page;
    if(this.page>this.pageCount) {
      this.page = this.pageCount-1
    }
    this.searchCronLog()
  }

  getSteps(id:number) {
    let code = this.route.snapshot.paramMap.get('code');
    const modalRef = this.modalService.open(CronLogDetailComponent,{ size: 'lg'});
    modalRef.componentInstance.id = id;
    modalRef.componentInstance.code = code;
  }
  getDiffDays(date1, date2): string {
    if(!date1 || !date2){
      return '-';
    }
    // Convertir les dates en millisecondes depuis l'Époque Unix (1 janvier 1970)
    let startDate = new Date(date1);
    let endDate = new Date(date2);

    const time1 = startDate.getTime();
    const time2 = endDate.getTime();

    // Calculer la différence en millisecondes
    const differenceInMilliseconds = Math.abs(time1 - time2);

    // Convertir la différence en secondes
    let differenceInSeconds = differenceInMilliseconds / 1000;

    if (differenceInSeconds < 0) {
      return 'Temps invalide';
    }

    const minutes = Math.floor(differenceInSeconds / 60);
    const remainingSeconds = differenceInSeconds % 60;

    if (minutes === 0) {
      return `${remainingSeconds} sec`;
    } else {
      if (remainingSeconds === 0) {
        return `${minutes} min`;
      } else {
        return `${minutes} min - ${remainingSeconds} sec`;
      }
    }
  }
  showHideSearchEngine(){
    this.searchEngineHidden = !this.searchEngineHidden;
  }
}
