import { Injectable } from '@angular/core';
import { LogSharedService } from './log-shared.service';
import {Observable} from 'rxjs';
import {GlobalConstants} from '../../global-constants';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';


@Injectable({
  providedIn: 'root'
})
export class ApiLogService {
  endPoint = 'api'
  constructor( private logSharedService: LogSharedService) { }

  apiLogSearchEngine(): Observable<any>
  {
    return this.logSharedService.get(this.endPoint + '/search-engine')
  }
  apiLogSearch(
      profilId:string|null='',
      provider:string|null='',
      consumer:string|null='',
      action:string|null='',
      type:string|null='',
      status:string|null='',
      transactionDateFrom:NgbDateStruct|undefined = undefined,
      transactionDateTo:NgbDateStruct|undefined= undefined,
      transactionTimeFromHour:string='00',
      transactionTimeFromMinute:string='00',
      transactionTimeToHour:string='23',
      transactionTimeToMinute:string='59',
      page:number=1
  ): Observable<any>
  {
    let isError = null;
    let isWarning = null;
    switch(status) {
      case 'ok': {
        isError = false;
        isWarning = false;
        break;
      }
      case 'error': {
        isError = true;
        break;
      }
      case 'warning': {
        isWarning = true;
        break;
      }
    }
    let transactionDateFromRq = null
    let transactionDateToRq = null
    if(transactionDateFrom !== undefined && transactionDateTo !== undefined ) {
      transactionDateFromRq = transactionDateFrom.year + '-' + transactionDateFrom.month + '-' +  transactionDateFrom.day + 'T' + transactionTimeFromHour + ':' + transactionTimeFromMinute + ':00'
      transactionDateToRq = transactionDateTo.year + '-' + transactionDateTo.month + '-' +  transactionDateTo.day + 'T' + transactionTimeToHour + ':' + transactionTimeToMinute + ':00'
    }
    let param = {
      profilId: [profilId],
      provider: [provider],
      consumer: [consumer],
      action: [action],
      type: [type],
      isError: isError,
      isWarning: isWarning,
      creationDateFrom: transactionDateFromRq,
      creationDateTo: transactionDateToRq,
      page: page,
      pageSize: GlobalConstants.pageSize
    };
    return this.logSharedService.post(this.endPoint + '/search',param)
  }
  apiLogGetRqRs(id:number) {
    return this.logSharedService.get(this.endPoint + '/' + id + '/request-response')
  }
}
