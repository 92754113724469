import { Pipe, PipeTransform } from '@angular/core';
import {isMoment} from 'moment';
import * as moment from 'moment/moment';
import 'moment/locale/fr'
moment.locale('fr')
const today = new Date().toLocaleDateString('fr-FR', {timeZone: "Europe/Paris"});
const yesterday = new Date((new Date()).valueOf() - 1000*60*60*24).toLocaleDateString('fr-FR', {timeZone: "Europe/Paris"});

@Pipe({
  name: 'dateText'
})
export class DateTextPipe implements PipeTransform {

  transform(value: string): string {
    let result = value;

    let toDayDate = moment(today, "DD/MM/YYYY");
    let daysAgo;
    for (let i = 1; i <= 7; i++) {
      daysAgo = toDayDate.subtract(1, 'days');
      if (value == daysAgo.format('DD/MM/YYYY')) {
        result = daysAgo.format('dddd')
      }
    }

    if (value == today) {
      result = 'Aujourd\'hui'
    }

    if (value == yesterday) {
      result = 'Hier'
    }
    return this.capitalize(result);
  }
  capitalize(word) {
    if (word !== null) {
      const firstLetter = word.charAt(0)

      const firstLetterCap = firstLetter.toUpperCase()

      const remainingLetters = word.slice(1)

      const capitalizedWord = firstLetterCap + remainingLetters
      return capitalizedWord;
    } else {
      return  word;
    }
  }
}
