import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute} from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import {TokenService} from '../services/token.service';

@Injectable()
export class AuthGuard implements CanActivate {
  public jwtHelper: JwtHelperService = new JwtHelperService();
  constructor(private authService: AuthService, private router: Router, private tokenService: TokenService,private route: ActivatedRoute) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const expectedRoles = route.data.roles ?? null;
    if(expectedRoles !== null) {
      const authorized = this.tokenService.checkRole(expectedRoles);
      if(authorized) {
        return  true;
      } else {

        this.router.navigate(['/dashboard']);
        return false;
      }
    }
    return true;
  }
}
