<div class="modal-header">
  <h4 class="modal-title">
    Log
    <i class="ft-refresh-ccw" (click)="getLog()" style="margin-left: 15px; cursor:pointer;"></i>
  </h4>

  <button type="button" style="border: 0; background: none" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"><i class="ft-x-circle" ></i></button>
</div>
<div class="modal-body">
  <div style="clear: both;margin-bottom: 20px; position: relative" *ngIf="running != null">
    Statut : <b>{{running ? 'En cours d\'execution' : 'Terminé'}}</b>
    <span *ngIf="running"> - Temps d'exécution : <b>{{getDiffDays((now | date : 'yyyy-MM-ddTHH:mm:ss'),cronExecutionDetail.startDateTime)}}</b></span>
    <span *ngIf="!running"> - Temps total d'exécution : <b>{{getDiffDays(cronExecutionDetail.updateDateTime,cronExecutionDetail.startDateTime)}}</b></span>
    <div  class="type-execution">
      <span class="badge bg-{{cronExecutionDetail.isAutomatic ? 'success' : 'info'}}">
        {{cronExecutionDetail.isAutomatic ? 'Automatique' : 'Manuel'}}
        <span *ngIf="cronExecutionDetail.userName" style="font-size: 10px">
           - {{cronExecutionDetail.userName}}
        </span>
      </span>
    </div>

  </div>
  <ul class="list-group"  *ngIf="tokenService.checkRole([])">
    <li style="overflow-wrap: anywhere;padding-top: 17px" *ngFor="let step of steps; let isFirst = first; let isLast = last;"  class="list-group-item log-steps-detail">
      <b >
        {{(now | date:'yyyyMMdd') != (step.dateTime | date:'yyyyMMdd') ? (step.dateTime | date:'dd/MM/yyyy') + ' ' : ''}}
        {{step.dateTime | date:'HH:mm:ss'}} -
      </b>
      <b class="cron-log-length" [hidden]="!isFirst">{{steps.length}} Log</b>
      <b class="cron-time" *ngIf="!step.message.includes('fil d\'attente') && step.time.length"  [hidden]="isFirst || step.message.includes('Suspension automatique')">{{step.time}}<!--{{getDuration(step.dateTime,previousTime)}}--></b>
      <span  [innerHTML]="step.message">{{step.message}}</span>

      <b *ngIf="isLast && running  && !step.message.includes('fil d\'attente')" class="cron-time-counter"><i class="ft-clock"></i><span>{{executionTime}}</span></b>
    </li>
  </ul>
  <ul *ngIf="!tokenService.checkRole([])"  class="list-group">
    <span  *ngFor="let step of steps; let isFirst = first; let isLast = last;">
      <li class="list-group-item log-steps-detail" *ngIf="isLink(step.message)" [innerHTML]="step.message">{{step.message}}</li>
    </span>
  </ul>
</div>
