import { Injectable } from '@angular/core';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {EditoSharedService} from './edito-shared.service';

@Injectable({
  providedIn: 'root'
})
export class EditoService {

  constructor(private editoSharedService: EditoSharedService) { }
  redirectToLogin(){
    window.location.href = environment.editoFrontUrl + '/login';
    return true;
  }
  getAudit(entity:string,id: number): Observable<any>
  {
    let params = {
      pagination:0,
      entityType:entity,
      entityId:id,
    };
    return this.editoSharedService.get('audit_logs/product/'+id,params)
  }
}
