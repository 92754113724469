import { Routes, RouterModule } from '@angular/router';

//Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../../dashboard/dashboard.module').then(m => m.DashboardModule)
  }
  ,
  {
    path: 'not-found',
    loadChildren: () => import('../../not-found/not-found.module').then(m => m.NotFoundModule)
  }
  ,
  {
    path: 'page',
    loadChildren: () => import('../../page/page.module').then(m => m.PageModule)
  }
  ,
  {
    path: 'parameter',
    loadChildren: () => import('../../parameter/parameter.module').then(m => m.ParameterModule)
  }
  ,
  {
    path: 'locality',
    loadChildren: () => import('../../locality/locality.module').then(m => m.LocalityModule)
  }
  ,
  {
    path: 'cron',
    loadChildren: () => import('../../cron/cron.module').then(m => m.CronModule)
  }
  ,
  {
    path: 'file-generation',
    loadChildren: () => import('../../file-generation/file-generation.module').then(m => m.FileGenerationModule)
  }
  ,
  {
    path: 'xft-transaction',
    loadChildren: () => import('../../xft-transaction/xft-transaction.module').then(m => m.XftTransactionModule)
  }
  ,
  {
    path: 'flight-transaction',
    loadChildren: () => import('../../flight-transaction/flight-transaction.module').then(m => m.FlightTransactionModule)
  }
  ,
  {
    path: 'xft-user',
    loadChildren: () => import('../../xft-user/xft-user.module').then(m => m.XftUserModule)
  }
  ,
  {
    path: 'api-user',
    loadChildren: () => import('../../api-user/api-user.module').then(m => m.ApiUserModule)
  }
  ,
  {
    path: 'api-log',
    loadChildren: () => import('../../api-log/api-log.module').then(m => m.ApiLogModule)
  }
  ,
  {
    path: 'cache',
    loadChildren: () => import('../../cache/cache.module').then(m => m.CacheModule)
  }
  ,
  {
    path: 'connecteur',
    loadChildren: () => import('../../connecteur/connecteur.module').then(m => m.ConnecteurModule)
  }
  ,
  {
    path: 'notification',
    loadChildren: () => import('../../notification/notification.module').then(m => m.NotificationModule)
  }
  ,
  {
    path: 'user',
    loadChildren: () => import('../../user/user.module').then(m => m.UserModule)
  }
];
