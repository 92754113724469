import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EditoSharedService {
  editoApiHeaders = new HttpHeaders({'Content-Type':'application/json; charset=utf-8','accept': 'application/ld+json'})
  requestOptionsGet = {headers: this.editoApiHeaders, params: {}};
  requestOptionsGetBody = {headers: this.editoApiHeaders};
  requestOptionsPost = {headers: this.editoApiHeaders};
  constructor(private httpService: HttpClient) { }
  get(endPoint, params = {}) {
    let requestUrl=environment.editoApiUrl +'/' + endPoint;
    this.requestOptionsGet.params = params;
    return this.httpService.get<any>(requestUrl, this.requestOptionsGet).pipe( );
  }
  getBody(endPoint, params = {}) {
    let requestUrl=environment.editoApiUrl +'/' + endPoint;
    console.log(JSON.stringify(params))
    return  this.httpService.request('GET', requestUrl,  {
      body: 'xx', headers: this.editoApiHeaders, observe: 'body'

    }).pipe( );
    /*console.log(res)
    this.requestOptionsGet.params = params;
    return this.httpService.get<any>(requestUrl, this.requestOptionsGet).pipe( );*/
  }
  post(endPoint, params = {}) {
    let requestUrl=environment.editoApiUrl +'/' + endPoint;
    return this.httpService.post<any>(requestUrl,params, this.requestOptionsPost).pipe( );
  }
  put(endPoint, params = {}) {
    let requestUrl=environment.editoApiUrl +'/' + endPoint;
    return this.httpService.put<any>(requestUrl,params, this.requestOptionsPost).pipe( );
  }
  delete(endPoint, params = {}) {
    let requestUrl=environment.editoApiUrl +'/' + endPoint;
    this.requestOptionsGet.params = params;
    return this.httpService.delete<any>(requestUrl, this.requestOptionsGet).pipe( );
  }
}
