<div class="row">
    <div class="col-12">
        <div class="card shadow-none">
            <div class="header">
                <i class="ft-chevron-{{searchEngineHidden ? 'down' : 'up'}} accordion-button-up" (click)="showHideSearchEngine()"></i>
                <h6 class="text-white ml-2 title-header" >Log transactions XFT</h6>
                <i class="ft-refresh-cw btn-search-engine-reload" (click)="searchEngineForm.ngSubmit.emit()" title="Actualiser"></i>
            </div>
            <div  class="card-content ">
                <div class="card-body card-search-engine" [hidden]="searchEngineHidden">
                    <form (ngSubmit)="onSubmit();" #searchEngineForm="ngForm">
                        <div class="row" style="display: flex!important;">
                            <!--Producer-->
                            <div class="col-lg-3">
                                <div class="mb-3">
                                    <label >Producteur
                                        <ng-select [loading]="loadingEngine" [markFirst]="false" [notFoundText]="'Aucun producteur'" [searchable]="(producerList$ | async)?.length  > 10" [items]="producerList$ | async"  id="producerSelect" labelForId="producerSelect" [ngModelOptions]="{standalone: true}" bindValue="code" bindLabel="code" placeholder="Tous" [(ngModel)]="producer">
                                        </ng-select>
                                    </label>
                                </div>
                            </div>
                            <!-- Transaction -->
                            <div class="col-lg-3">
                                <div class="mb-3">
                                    <label>Transaction
                                        <ng-select [loading]="loadingEngine" [notFoundText]="'Aucune transaction'" [markFirst]="false" [searchable]="(transactionList$ | async)?.length  > 10" [items]="transactionList$ | async" id="transaction" labelForId="transaction" [ngModelOptions]="{standalone: true}" bindValue="code" bindLabel="code" placeholder="Toutes" [(ngModel)]="transaction">
                                        </ng-select>
                                    </label>
                                </div>
                            </div>

                            <!-- Transaction date -->
                            <div class="col-md-3">
                                <div class="form-floating mb-3">
                                    <label>
                                        Date transaction
                                        <div class="form-group">
                                            <div class="input-group">
                                                <input class="form-control" id="transactionDateFrom" readonly name="transactionDateFrom" [(ngModel)]="transactionDateFrom" ngbDatepicker
                                                       #logTransactionDateFrom="ngbDatepicker" [maxDate]="maxDate" (click)="logTransactionDateFrom.toggle()">
                                                <div class="input-group-append" style="margin-right: 2px">
                                                    <div class="input-group-text" (click)="logTransactionDateFrom.toggle()">
                                                        <i class="fa fa-calendar" style="cursor: pointer;"></i>
                                                    </div>
                                                </div>
                                                <input class="form-control" id="transactionDateTo" readonly name="transactionDateTo" [(ngModel)]="transactionDateTo" ngbDatepicker
                                                       #logTransactionDateTo="ngbDatepicker" [maxDate]="maxDate" [minDate]="transactionDateFrom" (click)="logTransactionDateTo.toggle()">
                                                <div class="input-group-append">
                                                    <div class="input-group-text" (click)="logTransactionDateTo.toggle()">
                                                        <i class="fa fa-calendar" style="cursor: pointer;"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <!-- Transaction Time -->
                            <div class="col-md-3">
                                <div class="form-floating mb-3">
                                    <label for="TransactionTimeFromHour">Heure transaction</label>
                                    <div class="form-group">
                                        <div class="input-group time-group">
                                            <span>Entre</span>
                                            <select aria-label="Floating label select example" class="form-control" id="TransactionTimeFromHour" name="TransactionTimeFromHour" [(ngModel)]="TransactionTimeFromHour" ngModel>
                                                <option *ngFor="let in of (24 | array) ;let i = index" value="{{ i | pad }}">{{ i | pad }}</option>
                                            </select>
                                            <span>:</span>
                                            <select aria-label="Floating label select example" class="form-control" id="TransactionTimeFromMinute" name="TransactionTimeFromMinute" [(ngModel)]="TransactionTimeFromMinute" ngModel>
                                                <option *ngFor="let in of (60 | array) ;let i = index" value="{{ i | pad }}">{{ i | pad }}</option>
                                            </select>
                                            <br class="transaction-time-br">
                                            <span class="transaction-time-and"> et </span>
                                            <select aria-label="Floating label select example" class="form-control" id="TransactionTimeToHour" name="TransactionTimeToHour" [(ngModel)]="TransactionTimeToHour" ngModel>
                                                <option *ngFor="let in of (24 | array) ;let i = index" value="{{ i | pad }}">{{ i | pad }}</option>
                                            </select>
                                            <span>:</span>
                                            <select aria-label="Floating label select example" class="form-control" id="TransactionTimeToMinute" name="TransactionTimeToMinute" [(ngModel)]="TransactionTimeToMinute" ngModel>
                                                <option *ngFor="let in of (60 | array) ;let i = index" value="{{ i | pad }}">{{ i | pad }}</option>
                                            </select>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <!-- Requester -->
                            <div class="col-lg-3">
                                <div class="mb-3">
                                    <label>Requester
                                        <div class="form-group">
                                            <ng-select [loading]="loadingXftUser" [notFoundText]="'Aucun client'" [markFirst]="false" [searchable]="(xftUserList$ | async)?.length  > 10" [searchFn]="customSearchClient" [items]="xftUserList$ | async" id="requesterCode" labelForId="requesterCode" [ngModelOptions]="{standalone: true}" bindValue="requesterCode" bindLabel="name" placeholder="Tous" [(ngModel)]="requesterCode">
                                                <ng-template ng-option-tmp  ng-label-tmp let-item="item" let-index="index" let-search="searchTerm">
                                                    {{item.requesterCode}} - {{item.name}} ({{item.backofficeCode}})
                                                </ng-template>
                                            </ng-select>
                                            <!--<ng-select [notFoundText]="'Aucun requester code'" [markFirst]="false" [searchable]="(xftUserList$ | async)?.length  > 10" [items]="xftUserList$ | async" id="requesterCode" [ngModelOptions]="{standalone: true}" bindValue="requesterCode" bindLabel="name" placeholder="Tous" [(ngModel)]="requesterCode">
                                            </ng-select>-->
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <!--NETWORK-->
                            <div class="col-lg-3">
                                <div class="mb-3">
                                    <label>Réseau
                                        <ng-select [notFoundText]="'Aucun réseau'" [markFirst]="false" [searchable]="(networkList$ | async)?.length  > 10" [items]="networkList$ | async" id="networkCode" labelForId="networkCode"  [ngModelOptions]="{standalone: true}" bindValue="id" bindLabel="name" placeholder="Tous" [(ngModel)]="requesterNetworkCode">
                                        </ng-select>
                                    </label>
                                </div>
                            </div>
                            <!--SUBNETWORK-->
                            <div class="col-lg-3">
                                <div class="mb-3">
                                    <label>Sous-réseau
                                        <ng-select [notFoundText]="'Aucun sous-réseau'" [markFirst]="false" [searchable]="(subNetworkList$ | async)?.length  > 10" [items]="subNetworkList$ | async" id="subNetworkCode" labelForId="subNetworkCode" [ngModelOptions]="{standalone: true}" bindValue="id" bindLabel="name" placeholder="Tous" [(ngModel)]="requesterSubNetworkCode">
                                        </ng-select>
                                    </label>
                                </div>
                            </div>
                            <!-- Channel -->
                            <div class="col-lg-3">
                                <div class="mb-3">
                                    <label>Canal
                                        <div class="form-group">
                                            <ng-select [loading]="loadingEngine" [notFoundText]="'Aucun canal'" [markFirst]="false" [searchable]="(channelList$ | async)?.length  > 10" [items]="channelList$ | async" id="channel" labelForId="channel" [ngModelOptions]="{standalone: true}" bindValue="code" bindLabel="code" placeholder="Tous" [(ngModel)]="channel">
                                            </ng-select>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <!-- Version -->
                            <div class="col-lg-3">
                                <div class="mb-3">
                                    <label>Version
                                        <div class="form-group">
                                            <ng-select [loading]="loadingEngine" [notFoundText]="'Aucune version'" [markFirst]="false" [searchable]="(versionList$ | async)?.length  > 10" [items]="versionList$ | async" id="version" labelForId="version" [ngModelOptions]="{standalone: true}" bindValue="code" bindLabel="code" placeholder="Tous" [(ngModel)]="version">
                                            </ng-select>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <!-- Status -->
                            <div class="col-lg-3">
                                <div class="mb-3">
                                    <label>Statut
                                        <div class="form-group">
                                            <ng-select [loading]="loadingEngine" [notFoundText]="'Aucun statut'" [markFirst]="false" [searchable]="(statusList$ | async)?.length  > 10" [items]="statusList$ | async" id="status" labelForId="status" [ngModelOptions]="{standalone: true}" bindValue="code" bindLabel="name" placeholder="Tous" [(ngModel)]="status">
                                            </ng-select>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <!-- Product Code -->
                            <div class="col-md-3">
                                <div class="form-floating mb-3">
                                    <label for="productCode">Code produit</label>
                                    <input class="form-control" id="productCode" name="productCode" ngModel type="text" [(ngModel)]="productCode" value="">
                                </div>
                            </div>
                            <!-- Product Name -->
                            <div class="col-md-3">
                                <div class="form-floating mb-3">
                                    <label for="productName">Nom produit</label>
                                    <input class="form-control" id="productName" name="productName" ngModel type="text" [(ngModel)]="productName" value="">
                                </div>
                            </div>
                            <!-- Departure City -->
                            <div class="col-md-3">
                                <div class="form-floating mb-3">
                                    <label for="departureCity">Ville de départ</label>
                                    <input class="form-control" id="departureCity" name="departureCity" ngModel type="text" [(ngModel)]="departureCity" value="">
                                </div>
                            </div>
                            <!-- Booking Reference -->
                            <div class="col-md-3">
                                <div class="form-floating mb-3">
                                    <label for="bookingReference">Référence booking</label>
                                    <input class="form-control" id="bookingReference" name="bookingReference" ngModel type="text" [(ngModel)]="bookingReference" value="">
                                </div>
                            </div>
                            <!-- Departure date -->
                            <div class="col-md-3">
                                <div class="form-floating mb-3">
                                    <label for="departureDateFrom">Date de départ</label>
                                    <div class="form-group">
                                        <div class="input-group">
                                            <input class="form-control" id="departureDateFrom" readonly name="dp" [(ngModel)]="departureDateFrom" ngbDatepicker
                                                   #logDepartureDateFrom="ngbDatepicker" [maxDate]="departureDateTo" (click)="logDepartureDateFrom.toggle()">
                                            <div class="input-group-append" style="margin-right: 2px">
                                                <div class="input-group-text" (click)="logDepartureDateFrom.toggle()">
                                                    <i class="fa fa-calendar" style="cursor: pointer;"></i>
                                                </div>
                                            </div>
                                            <input class="form-control" id="departureDateTo" readonly name="departureDateTo" [(ngModel)]="departureDateTo" ngbDatepicker
                                                   #logDepartureDateTo="ngbDatepicker" [minDate]="departureDateFrom"  (click)="logDepartureDateTo.toggle()">
                                            <div class="input-group-append">
                                                <div class="input-group-text" (click)="logDepartureDateTo.toggle()">
                                                    <i class="fa fa-calendar" style="cursor: pointer;"></i>
                                                </div>
                                            </div>
                                            <div class="input-group-append">
                                                <div class="input-group-text" style="background: none">
                                                    <i class="fa fa-eraser" style="cursor: pointer;" (click)="clearDepartureDate()"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <!-- Error / Warning Code -->
                            <div class="col-md-3">
                                <div class="form-floating mb-3">
                                    <label for="errorWarningCode">Code erreur</label>
                                    <input class="form-control" id="errorWarningCode" name="errorWarningCode" ngModel type="text" [(ngModel)]="errorWarningCode" value="">
                                </div>
                            </div>
                            <!-- Platform -->
                            <div class="col-lg-3">
                                <div class="mb-3">
                                    <label>Plateforme
                                        <div class="form-group">
                                            <ng-select [loading]="loadingPlatform" [notFoundText]="'Aucune plateforme'" [markFirst]="false" [searchable]="(platformList$ | async)?.length  > 10" [items]="platformList$ | async" id="platformCode" labelForId="platformCode" [ngModelOptions]="{standalone: true}" bindValue="code" bindLabel="name" placeholder="Tous" [(ngModel)]="platformCode">
                                            </ng-select>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="row blocBtnSearch">
                            <div class="col-md-9">
                                <div class="d-flex justify-content-start product-count"></div>
                            </div>
                            <div class="col-md-3">
                                <div class="d-flex justify-content-end">
                                    <button class="btn btn-primary" type="submit" id="search">
                                        <i class="ft-search mr-1"></i>Rechercher
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="card-body" id="scrollTo">
                    <section id="minimal-statistics-bg">
                        <div class="row " >
                            <div class="col-xl-3 col-lg-6 col-6">
                                <div class="card card-inverse bg-info">
                                    <div class="card-content">
                                        <div class="card-body">
                                            <div class="media">
                                                <div class="media-body text-left">
                                                    <h3 class="card-text">{{totalCount | formatNumber}}<span class="transaction-legend-type"> &nbsp;Transaction{{totalCount>1 ? 's':''}}</span></h3>
                                                </div>
                                                <div class="media-right align-self-center">
                                                    <i class="ft-repeat font-large-1 float-right"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-6 col-6">
                                <div class="card card-inverse bg-success">
                                    <div class="card-content">
                                        <div class="card-body">
                                            <div class="media">
                                                <div class="media-body text-left">
                                                    <h3 class="card-text">{{okCount | formatNumber}} <span class="log-percent">({{(okCount/totalCount)*100 | number:'1.0-0'}}%)</span><span class="transaction-legend-type"> &nbsp;&nbsp;OK</span></h3>
                                                </div>
                                                <div class="media-right align-self-center">
                                                    <i class="ft-check-circle font-large-1 float-right"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-6 col-6">
                                <div class="card card-inverse bg-warning">
                                    <div class="card-content">
                                        <div class="card-body">
                                            <div class="media">
                                                <div class="media-body text-left">
                                                    <h3 class="card-text">{{warningCount | formatNumber}} <span class="log-percent">({{(warningCount/totalCount)*100 | number:'1.0-0'}}%)</span><span class="transaction-legend-type"> &nbsp;&nbsp;En alerte</span></h3>
                                                </div>
                                                <div class="media-right align-self-center">
                                                    <i class="ft-alert-triangle font-large-1 float-right"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-6 col-6">
                                <div class="card card-inverse bg-danger">
                                    <div class="card-content">
                                        <div class="card-body">
                                            <div class="media">
                                                <div class="media-body text-left">
                                                    <h3 class="card-text">{{errorCount | formatNumber}} <span class="log-percent">({{(errorCount/totalCount)*100 | number:'1.0-0'}}%)</span><span class="transaction-legend-type"> &nbsp;&nbsp;En erreur</span></h3>
                                                </div>
                                                <div class="media-right align-self-center">
                                                    <i class="ft-x-circle font-large-1 float-right"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div class="table-responsive">
                        <div *ngIf="pageCount > 1">
                            <ngb-pagination [collectionSize]="totalCount" [pageSize]="pageSize" (pageChange)="paginate($event)" [(page)]="page" [maxSize]="3" [rotate]="true" [boundaryLinks]="true"></ngb-pagination>
                        </div>
                            <table class="table table-striped xft-transaction-log">
                                <thead class="thead-light">
                            <tr>
                                <th  style="width: 12% !important;">
                                    Transaction<br>
                                    Date & heure
                                </th>
                                <th  style="width: 8% !important;" >Marque<br><br></th>
                                <th  style="width:auto !important;min-width: 220px">Requester<br>Réseau - Sous-réseau</th>
                                <th  style="width: 7% !important;min-width: 100px">Durée<br>Serveur</th>
                                <th  style="width:8% !important; min-width: 130px">Produit<br>Durée</th>
                                <th  style="width:8% !important; min-width: 130px">Départ<br>Passagers<br></th>
                                <th  style="width: 7% !important;min-width: 100px">Plateforme<br>IP</th>
                                <th  style="width:6% !important;min-width: 100px">Réf.<br>Booking<br></th>
                                <th  style="width:5% !important;min-width: 100px">Version<br>Canal<br></th>
                                <th  style="width:6% !important;min-width: 100px;">Status<br><br></th>
                                <th style="width:5% !important;min-width: 60px;"></th>
                            </tr>
                            </thead>
                            <tbody *ngIf="xftTransactionLogList?.length != 0">
                                <tr *ngFor="let xftTransactionLog of xftTransactionLogList"  class="transaction-{{xftTransactionLog.isError ? 'error' :  (xftTransactionLog.isWarning ?'warning' : 'success')}} {{(xftTransactionLog.id == currentLigne) ? 'current-log' : ''}}" >
                                    <td (dblclick)="getXml(xftTransactionLog.id,xftTransactionLog.profilId)" >
                                        {{xftTransactionLog.transactionCode}}
                                        <br>
                                        <span title="{{xftTransactionLog.creationDate  | date:'dd/MM/YYYY HH:mm:ss'}}">
                                        {{(xftTransactionLog.creationDate  | date:'dd/MM/YYYY') | dateText}}
                                        {{xftTransactionLog.creationDate  | date:'HH:mm:ss'}}
                                        </span>
                                    </td>
                                    <td (dblclick)="getXml(xftTransactionLog.id,xftTransactionLog.profilId)" >{{xftTransactionLog.producer}}</td>
                                    <td (dblclick)="getXml(xftTransactionLog.id,xftTransactionLog.profilId)" >
                                        <span *ngIf="xftTransactionLog.requesterCode.length">{{xftTransactionLog.requesterCode}} - {{xftTransactionLog.requesterName.substring(0, 30)}}<span *ngIf="xftTransactionLog.requesterName.length > 30">...</span> <span *ngIf="xftTransactionLog.requesterBackofficeCode.length"> ({{xftTransactionLog.requesterBackofficeCode}})</span></span>
                                        <i class="ft-info" style="font-size: 12px !important; margin-left: 1px !important;" *ngIf="xftTransactionLog.requesterName.length > 30" container="body" tooltipClass="myTooltip" placement="top" ngbTooltip="{{xftTransactionLog.requesterName}}"></i>
                                        <br>
                                        <span style="font-size: 10px" *ngIf="xftTransactionLog.requesterNetworkName.length">{{(xftTransactionLog.requesterNetworkName + ' - ' + xftTransactionLog.requesterSubNetworkName).substring(0, 90)}}</span><span *ngIf="(xftTransactionLog.requesterNetworkName + ' - ' + xftTransactionLog.requesterSubNetworkName).length > 90">...</span>
                                        <i class="ft-info" style="font-size: 12px !important; margin-left: 1px !important;" *ngIf="(xftTransactionLog.requesterNetworkName + ' - ' + xftTransactionLog.requesterSubNetworkName).length > 90" container="body" tooltipClass="myTooltip" placement="top" ngbTooltip="{{(xftTransactionLog.requesterNetworkName + ' - ' + xftTransactionLog.requesterSubNetworkName)}}"></i>
                                    </td>
                                    <td  (dblclick)="getXml(xftTransactionLog.id,xftTransactionLog.profilId)" class="server-td" >
                                        <span class="badge badge-pill {{xftTransactionLog.msDuration | durationBadge: xftTransactionMsDurationWarning }} " style="padding-left: 0;background: none !important;">{{xftTransactionLog.msDuration | msDuration }}</span>
                                        <br>
                                        <span class="server-name" title="{{xftTransactionLog.server}}">{{xftTransactionLog.server}}</span>
                                    </td>
                                    <td (dblclick)="getXml(xftTransactionLog.id,xftTransactionLog.profilId)" >
                                        {{xftTransactionLog.productCode}}
                                        <br>
                                        {{xftTransactionLog.nbNight}} nuit{{xftTransactionLog.nbNight>1?'s':''}} - {{xftTransactionLog.nbDay}} jour{{xftTransactionLog.nbDay>1?'s':''}}
                                    </td>
                                    <td (dblclick)="getXml(xftTransactionLog.id,xftTransactionLog.profilId)" >
                                        {{xftTransactionLog.departureDate  | date:'dd/MM/YYYY'}} - {{xftTransactionLog.departureCity}}
                                        <br>{{xftTransactionLog.nbAdult}}A - {{xftTransactionLog.nbChild}}E - {{xftTransactionLog.nbInfant}}B
                                    </td>
                                    <td (dblclick)="getXml(xftTransactionLog.id,xftTransactionLog.profilId)" >
                                        {{xftTransactionLog.platform}}<br>
                                        {{xftTransactionLog.ip}}
                                    </td>
                                    <td (dblclick)="getXml(xftTransactionLog.id,xftTransactionLog.profilId)" >
                                        {{xftTransactionLog.bookingReference}}
                                    </td>
                                    <td (dblclick)="getXml(xftTransactionLog.id,xftTransactionLog.profilId)" >
                                        {{xftTransactionLog.version}}<br>
                                        {{xftTransactionLog.channel}}
                                    </td>
                                    <td  >
                                        <!--<i *ngIf="!xftTransactionLog.isError && !xftTransactionLog.isWarning" class="ft-check-circle" style="color: #40C057"></i>
                                        <i *ngIf="xftTransactionLog.isWarning" class="ft-alert-circle" style="color: orange"></i>
                                        <i *ngIf="xftTransactionLog.isError" class="ft-x-circle" style="color: red"></i>-->
                                        <span class="badge badge-{{xftTransactionLog.isError ? 'danger' :  (xftTransactionLog.isWarning ?'warning' : 'success')}} ">
                                          {{xftTransactionLog.isError ? 'Erreur' :  (xftTransactionLog.isWarning ?'Alerte' : 'OK')}}
                                        </span>
                                        <a *ngIf="xftTransactionLog.isError || xftTransactionLog.isWarning" (click)="getErrorWarningDetail(xftTransactionLog.id,xftTransactionLog.profilId)"  title="Détails">
                                            <i class="ft-alert-circle" style="margin: 5px; margin-top: 4px; color:orangered;font-size: 20px;    top: 4px;    position: relative;"></i>
                                        </a>
                                        <span class="{{xftTransactionLog.isError ? 'danger' :  (xftTransactionLog.isWarning ?'warning' : '')}}"><br>{{xftTransactionLog.errorWarningCode}}</span>
                                    </td>
                                    <td class="actions" style="text-align: center" >
                                        <a (click)="getXml(xftTransactionLog.id,xftTransactionLog.profilId)"  title="XML">
                                            <i class="ft-code" style="margin: 5px; color:cornflowerblue;"></i>
                                        </a>
                                        <a (click)="getApiLog(xftTransactionLog.id,xftTransactionLog.profilId)"  title="Log API">
                                            <i class="ft-repeat" style="margin: 5px; color:cornflowerblue;"></i>
                                        </a>

                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="xftTransactionLogList?.length == 0" >
                            <tr>
                                <td class="no-data-available" colspan="11">Aucune transaction</td>
                            </tr>
                            </tbody>
                        </table>
                        <div *ngIf="pageCount > 1">
                            <ngb-pagination [collectionSize]="totalCount" [pageSize]="pageSize" (pageChange)="paginate($event)" [(page)]="page" [maxSize]="3" [rotate]="true" [boundaryLinks]="true"></ngb-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--/ Kick start -->
    </div>
</div>
