import { Injectable } from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MidOfficeSharedService {
  midOfficeApiHeaders = new HttpHeaders({'Content-Type':'application/json; charset=utf-8','accept': 'application/ld+json'})
  requestOptionsGet = {headers: this.midOfficeApiHeaders, params: {}};
  requestOptionsGetBody = {headers: this.midOfficeApiHeaders};
  requestOptionsPost = {headers: this.midOfficeApiHeaders};
  constructor(private httpService: HttpClient) { }
  get(endPoint, params = {}) {
    let requestUrl=environment.midOfficeApiUrl +'/' + endPoint;
    this.requestOptionsGet.params = params;
    return this.httpService.get<any>(requestUrl, this.requestOptionsGet).pipe( );
  }
  getBody(endPoint, params = {}) {
    let requestUrl=environment.midOfficeApiUrl +'/' + endPoint;
    return  this.httpService.request('GET', requestUrl,  {
      body: 'xx', headers: this.midOfficeApiHeaders, observe: 'body'

    }).pipe( );
  }
  post(endPoint, params = {}) {
    let requestUrl=environment.midOfficeApiUrl +'/' + endPoint;
    return this.httpService.post<any>(requestUrl,params, this.requestOptionsPost).pipe( );
  }
  put(endPoint, params = {}) {
    let requestUrl=environment.midOfficeApiUrl +'/' + endPoint;
    return this.httpService.put<any>(requestUrl,params, this.requestOptionsPost).pipe( );
  }
  delete(endPoint, params = {}) {
    let requestUrl=environment.midOfficeApiUrl +'/' + endPoint;
    this.requestOptionsGet.params = params;
    return this.httpService.delete<any>(requestUrl, this.requestOptionsGet).pipe( );
  }
}
