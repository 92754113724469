import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HTTP_INTERCEPTORS, HttpErrorResponse, HttpResponse
} from '@angular/common/http';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {AuthService} from '../auth/auth.service';
import {catchError} from 'rxjs/operators';
import {TokenService} from '../services/token.service';
import {EditoService} from '../services/edito/edito.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor( private authService: AuthService, private tokenService: TokenService, private editoService: EditoService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let authReq = request;
    const token = this.tokenService.getToken();
    if (token != null) {
      authReq = this.addTokenHeader(request, token);
    }

    return next.handle(authReq).pipe(catchError(error => {
      if (error instanceof HttpErrorResponse && error.status === 401) {
        this.handle401Error(authReq, next);
      }

      return throwError(error);
    }));
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    let redirect = true;
    if(request.url.indexOf( "/cron/" ) !== -1){
      redirect = false;
    }
    if(redirect) {
      this.editoService.redirectToLogin();
    }
  }

  private addTokenHeader(request: HttpRequest<any>, token: string) {
    if(request.url.indexOf( "edito" ) !== -1){
      return request.clone({ headers: request.headers.set('Api-Token', token) });
    } else {
      return request.clone({ headers: request.headers.set('Api-Key', token) });
    }

  }
}
export const authInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
];
