<div class="row">
    <div class="col-12">
        <div class="card shadow-none">
            <div class="header">
                <i class="ft-chevron-{{searchEngineHidden ? 'down' : 'up'}} accordion-button-up" (click)="showHideSearchEngine()"></i>
                <h6 class="text-white ml-2 title-header" >Rapport transactions XFT</h6>
            </div>
            <div  class="card-content ">
                <div class="card-body card-search-engine" [hidden]="searchEngineHidden">
                    <form (ngSubmit)="onSubmit();">
                        <div class="row" style="display: flex!important;">
                            <!--Producer-->
                            <div class="col-lg-3">
                                <div class="mb-3">
                                    <label>
                                        Producteur
                                        <ng-select [notFoundText]="'Aucun producteur'" [markFirst]="false" [searchable]="(producerList$ | async)?.length  > 10" [items]="producerList$ | async" id="producerCode" labelForId="producerCode" [ngModelOptions]="{standalone: true}" [clearable]="false" bindValue="code" bindLabel="code" placeholder="Tous" [(ngModel)]="producerCode">
                                        </ng-select>
                                    </label>
                                </div>
                            </div>
                            <!-- Transaction date -->
                            <div class="col-md-3">
                                <div class="form-floating mb-3">
                                    <label >
                                        Date transaction
                                        <div class="form-group">
                                            <div class="input-group">
                                                <input class="form-control" id="transactionDate" readonly name="transactionDate" [(ngModel)]="transactionDate" ngbDatepicker
                                                       #logTransactionDate="ngbDatepicker" [maxDate]="maxDate" (click)="logTransactionDate.toggle()">
                                                <div class="input-group-append" style="margin-right: 2px">
                                                    <div class="input-group-text" (click)="logTransactionDate.toggle()">
                                                        <i class="fa fa-calendar" style="cursor: pointer;"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <!-- Requester -->
                            <div class="col-lg-3">
                                <div class="mb-3">
                                    <label>
                                        Requester
                                        <div class="form-group">
                                            <ng-select [loading]="loadingXftUser" [notFoundText]="'Aucun requester code'" [markFirst]="false" [searchable]="(xftUserList$ | async)?.length  > 10" [searchFn]="customSearchClient" [items]="xftUserList$ | async" id="requesterCode" labelForId="requesterCode" [ngModelOptions]="{standalone: true}" bindValue="requesterCode" bindLabel="name" placeholder="Tous" [(ngModel)]="requesterCode">
                                                <ng-template ng-option-tmp  ng-label-tmp let-item="item" let-index="index" let-search="searchTerm">
                                                    {{item.requesterCode}} - {{item.name}} ({{item.backofficeCode}})
                                                </ng-template>
                                            </ng-select>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="row blocBtnSearch">
                            <div class="col-md-9">
                                <div class="d-flex justify-content-start product-count"></div>
                            </div>
                            <div class="col-md-3">
                                <div class="d-flex justify-content-end">
                                    <button class="btn btn-primary" type="submit" id="search">
                                        <i class="ft-search mr-1"></i>Rechercher
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="card-body" *ngIf="(transactionReport | keyvalue)?.length">
                    <div class="table-responsive">
                        <table class="table table-striped transaction-report-recap">
                            <thead class="thead-light">
                            <tr>
                                <th style="width: auto; min-width: 230px">Transaction</th>
                                <th>Total</th>
                                <th>Succès</th>
                                <th>Alerte</th>
                                <th>Erreur</th>
                            </tr>
                            </thead>
                            <tbody >
                            <tr>
                                <td>Get Quoted Availability</td>
                                <td>{{transactionReport['totalTransactionByCode']?.GetQuotedAvailability?.total ?? 0}}</td>
                                <td class="success" >
                                    {{(transactionReport['totalTransactionByCode']?.GetQuotedAvailability?.total ?? 0) - ((transactionReport['totalTransactionByCode']?.GetQuotedAvailability?.warning ?? 0)+(transactionReport['totalTransactionByCode']?.GetQuotedAvailability?.error ?? 0))}}
                                    <span>{{(((transactionReport['totalTransactionByCode']?.GetQuotedAvailability?.total ?? 0) - ((transactionReport['totalTransactionByCode']?.GetQuotedAvailability?.warning ?? 0)+(transactionReport['totalTransactionByCode']?.GetQuotedAvailability?.error ?? 0))) / (transactionReport['totalTransactionByCode']?.GetQuotedAvailability?.total ?? 1)) *100 | number:'1.0-1'}}%</span>
                                </td>
                                <td class="warning">
                                    {{transactionReport['totalTransactionByCode']?.GetQuotedAvailability?.warning ?? 0}}
                                    <span>{{((transactionReport['totalTransactionByCode']?.GetQuotedAvailability?.warning ?? 0) / (transactionReport['totalTransactionByCode']?.GetQuotedAvailability?.total ?? 1)) *100 | number:'1.0-1'}}%</span>
                                </td>
                                <td class="danger">
                                    {{transactionReport['totalTransactionByCode']?.GetQuotedAvailability?.error ?? 0}}
                                    <span>{{((transactionReport['totalTransactionByCode']?.GetQuotedAvailability?.error ?? 0) / (transactionReport['totalTransactionByCode']?.GetQuotedAvailability?.total ?? 1)) *100 | number:'1.0-1'}}%</span>
                                </td>
                            </tr>
                            <tr>
                                <td>Create booking</td>
                                <td>{{(transactionReport['totalTransactionByCode']?.CreateBooking?.total ?? 0)}}</td>
                                <td class="success" >
                                    {{(transactionReport['totalTransactionByCode']?.CreateBooking?.total ?? 0) - ((transactionReport['totalTransactionByCode']?.CreateBooking?.warning ?? 0)+(transactionReport['totalTransactionByCode']?.CreateBooking?.error ?? 0))}}
                                    <span>{{(((transactionReport['totalTransactionByCode']?.CreateBooking?.total ?? 0) - ((transactionReport['totalTransactionByCode']?.CreateBooking?.warning ?? 0)+(transactionReport['totalTransactionByCode']?.CreateBooking?.error ?? 0))) / (transactionReport['totalTransactionByCode']?.CreateBooking?.total ?? 1)) *100 | number:'1.0-1'}}%</span>
                                </td>
                                <td class="warning">
                                    {{transactionReport['totalTransactionByCode']?.CreateBooking?.warning ?? 0}}
                                    <span>{{((transactionReport['totalTransactionByCode']?.CreateBooking?.warning ?? 0) / (transactionReport['totalTransactionByCode']?.CreateBooking?.total ?? 1)) *100 | number:'1.0-1'}}%</span>
                                </td>
                                <td class="danger">
                                    {{transactionReport['totalTransactionByCode']?.CreateBooking?.error ?? 0}}
                                    <span>{{((transactionReport['totalTransactionByCode']?.CreateBooking?.error ?? 0) / (transactionReport['totalTransactionByCode']?.CreateBooking?.total ?? 1)) *100 | number:'1.0-1'}}%</span>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <br><br>
                    <div class="table-responsive" *ngIf="(transactionReport['totalTransactionByCode'] | keyvalue)?.length">
                        <table class="table table-striped transaction-report-recap-requester">
                            <thead class="thead-light">
                                <tr>
                                    <th rowspan="2" style="width: auto;border-right: 1px solid #E0E0E0; min-width: 290px">Requester</th>
                                    <th colspan="4" style="border-right: 1px solid #E0E0E0;">Get Quoted Availability</th>
                                    <th colspan="4">Create booking</th>
                                </tr>
                                <tr>
                                    <th style="text-align: center">Total</th>
                                    <th>Succès</th>
                                    <th>Alerte</th>
                                    <th style="border-right: 1px solid #E0E0E0;">Erreur</th>
                                    <th>Total</th>
                                    <th>Succès</th>
                                    <th>Alerte</th>
                                    <th>Erreur</th>
                                </tr>
                            </thead>
                            <tbody >
                            <tr *ngFor="let transaction of transactionReport['totalTransactionByRequester'] | keyvalue">
                                <td style="border-right: 1px solid #E0E0E0;">{{transaction.value.requesterBackofficeCode}} - {{transaction.value.name}} ({{transaction.key}})</td>
                                <td>
                                    {{(transaction.value?.transaction?.GetQuotedAvailability?.total ?? 0)}}
                                </td>
                                <td class="success" >
                                    {{(transaction.value?.transaction?.GetQuotedAvailability?.total ?? 0) - ((transaction.value?.transaction?.GetQuotedAvailability?.warning ?? 0)+(transaction.value?.transaction?.GetQuotedAvailability?.error ?? 0))}}
                                    <span>{{(((transaction.value?.transaction?.GetQuotedAvailability?.total ?? 0) - ((transaction.value?.transaction?.GetQuotedAvailability?.warning ?? 0)+(transaction.value?.transaction?.GetQuotedAvailability?.error ?? 0))) / (transaction.value?.transaction?.GetQuotedAvailability?.total ?? 1)) *100 | number:'1.0-1'}}%</span>
                                </td>
                                <td class="warning">
                                    {{transaction.value?.transaction?.GetQuotedAvailability?.warning ?? 0}}
                                    <span>{{((transaction.value?.transaction?.GetQuotedAvailability?.warning ?? 0) / (transaction.value?.transaction?.GetQuotedAvailability?.total ?? 1)) *100 | number:'1.0-1'}}%</span>
                                </td>
                                <td class="danger" style="border-right: 1px solid #E0E0E0;">
                                    {{transaction.value?.transaction?.GetQuotedAvailability?.error ?? 0}}
                                    <span>{{((transaction.value?.transaction?.GetQuotedAvailability?.error ?? 0) / (transaction.value?.transaction?.GetQuotedAvailability?.total ?? 1)) *100 | number:'1.0-1'}}%</span>
                                </td>
                                <td >
                                    {{(transaction.value?.transaction?.CreateBooking?.total ?? 0)}}
                                </td>
                                <td class="success" >
                                    {{(transaction.value?.transaction?.CreateBooking?.total ?? 0) - ((transaction.value?.transaction?.CreateBooking?.warning ?? 0)+(transaction.value?.transaction?.CreateBooking?.error ?? 0))}}
                                    <span>{{(((transaction.value?.transaction?.CreateBooking?.total ?? 0) - ((transaction.value?.transaction?.CreateBooking?.warning ?? 0)+(transaction.value?.transaction?.CreateBooking?.error ?? 0))) / (transaction.value?.transaction?.CreateBooking?.total ?? 1)) *100 | number:'1.0-1'}}%</span>
                                </td>
                                <td class="warning">
                                    {{transaction.value?.transaction?.CreateBooking?.warning ?? 0}}
                                    <span>{{((transaction.value?.transaction?.CreateBooking?.warning ?? 0) / (transaction.value?.transaction?.CreateBooking?.total ?? 1)) *100 | number:'1.0-1'}}%</span>
                                </td>
                                <td class="danger">
                                    {{transaction.value?.transaction?.CreateBooking?.error ?? 0}}
                                    <span>{{((transaction.value?.transaction?.CreateBooking?.error ?? 0) / (transaction.value?.transaction?.CreateBooking?.total ?? 1)) *100 | number:'1.0-1'}}%</span>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <!--/ Kick start -->
    </div>
</div>
