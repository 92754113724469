import {Component, Input, OnDestroy} from '@angular/core';
import {CronLogService} from '../../../shared/services/log/cron-log.service';
import {ActivatedRoute} from '@angular/router';
import {NotificationService} from '../../../shared/services/notification.service';
import {NgForm} from '@angular/forms';
import {CronService} from '../../../shared/services/midOffice/cron.service';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {environment} from '../../../../environments/environment';
import {Subscription} from 'rxjs';
import {TokenService} from '../../../shared/services/token.service';
import {CronWorkerService} from '../../../shared/services/cron-worker/cron-worker.service';
import {UtilsService} from '../../../shared/services/utils.service';

@Component({
  selector: 'app-cron-form',
  templateUrl: './cron-form.component.html',
  styleUrls: ['./cron-form.component.scss']
})
export class CronFormComponent implements  OnDestroy {
  @Input() cronCode: string = '' ;
  @Input() cron: any = {} ;
  @Input() message: string | undefined;
  cronApiUrl = environment.cronApiUrl;
  config: any;
    addEditCronSubscription: Subscription
  constructor(private cronService: CronService, private cronWorkerService: CronWorkerService ,private cronLogService: CronLogService, private utilsService: UtilsService, private tokenService: TokenService,private route: ActivatedRoute,private notifyService : NotificationService,public activeModal: NgbActiveModal) { }

    ngOnDestroy() {
        if (this.addEditCronSubscription) {
            this.addEditCronSubscription.unsubscribe();
        }
    }
  onSubmit(AddEditForm: NgForm) {
      this.utilsService.loadingShow();
    if(AddEditForm.valid) {
      this.addEditCronSubscription = this.cronService.addEditCron(this.cronCode,this.cron).subscribe(
        cron => {
          this.cron = cron;
          if (this.message == 'add') {
            this.notifyService.showSuccess('Création effectuée avec succès',"")
          } else {
            this.notifyService.showSuccess('Mise à jour effectuée avec succès',"")
          }
          this.activeModal.close()
        },
        error => {
          this.notifyService.showError(error.error.message ?? (error.message ?? ''),"")
        }
      )
    }
  }
    copyCronUrl(item) {
        let token = '?token='+this.tokenService.getToken();
        navigator.clipboard.writeText(item+token).then(
          function(){

          });
      this.notifyService.showInfo('Url copiée','')
    }
    executeCron(url: string, cron) {
        this.cronWorkerService.executeCron(url,cron);
    }
    isNumeric(id: any): boolean {
        // Vérifiez si id est numérique
        return !isNaN(parseFloat(id)) && isFinite(id);
    }
}
