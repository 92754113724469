<div class="row">
    <div class="col-12">
        <div class="card shadow-none">
            <div class="header">
                <i class="ft-chevron-{{searchEngineHidden ? 'down' : 'up'}} accordion-button-up" (click)="showHideSearchEngine()"></i>
                <h6 class="text-white ml-2 title-header " >Suivi générations</h6>
                <i class="ft-refresh-cw btn-search-engine-reload" style="margin-right: 30px" (click)="searchEngineForm.ngSubmit.emit()" title="Actualiser"></i>
                <div class="progress-circle btn-add" [style.background]="getConicGradient()" (click)="updateFileGenerationLog()"><span class="text">{{ counter }}</span></div>
            </div>
            <div class="card-content">
                <div class="card-body card-search-engine" [hidden]="searchEngineHidden">
                    <form #searchEngineForm="ngForm" (ngSubmit)="searchFileGeneration();">
                        <div class="row" style="display: flex!important;">
                            <!--Type de fichier-->
                            <div class="col-lg-3">
                                <div class="mb-3">
                                    <label>
                                        Type de fichier
                                        <ng-select [loading]="loadingFileType" [notFoundText]="'Aucun type de fichier'" [markFirst]="false" [searchable]="(fileTypeList$ | async)?.length  > 10"  [clearable]="false" [items]="fileTypeList$ | async" id="fileType" labelForId="fileType" [ngModelOptions]="{standalone: true}" bindValue="code" bindLabel="name" placeholder="Type de fichier" [(ngModel)]="fileType">
                                        </ng-select>
                                    </label>
                                </div>
                            </div>
                            <!-- Marque -->
                            <div class="col-lg-3">
                                <div class="mb-3">
                                    <label>
                                        Marque
                                        <ng-select [loading]="loadingBrand" [notFoundText]="'Aucune marque'" [markFirst]="false" [searchable]="(brandList$ | async)?.length  > 10" [clearable]="false" [items]="brandList$ | async" id="brand" labelForId="brand" [ngModelOptions]="{standalone: true}" bindValue="code" bindLabel="name" placeholder="Marque" [(ngModel)]="brand">
                                        </ng-select>
                                    </label>
                                </div>
                            </div>
                            <!-- Requester code -->
                            <div class="col-lg-4">
                                <div class="mb-3">
                                    <label>
                                        Requester code
                                        <div class="form-group">
                                            <ng-select [loading]="loadingXftUser" [notFoundText]="'Aucun client'" [markFirst]="false"  [searchable]="(xftUserList$ | async)?.length  > 10" [items]="xftUserList$ | async" id="clientCode" labelForId="clientCode" [ngModelOptions]="{standalone: true}" [searchFn]="customSearchClient" bindValue="requesterCode" bindLabel="name" placeholder="Requester code" [(ngModel)]="requesterCode">
                                                <ng-template ng-option-tmp  ng-label-tmp let-item="item" let-index="index" let-search="searchTerm">
                                                    {{item.requesterCode}} - {{item.name}} ({{item.backofficeCode}})
                                                </ng-template>
                                            </ng-select>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div class="col-lg-2">
                                <div class="mb-3">
                                    <label for="dateLog">Date</label>
                                    <div class="form-group">
                                        <div class="input-group">
                                            <input class="form-control" id="dateLog" readonly name="dp" [(ngModel)]="date" ngbDatepicker
                                                   #logDate="ngbDatepicker" [maxDate]="maxDate" (click)="logDate.toggle()">
                                            <div class="input-group-append">
                                                <div class="input-group-text" (click)="logDate.toggle()">
                                                    <i class="fa fa-calendar" style="cursor: pointer;"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- .ligne 2 -->
                        <!-- Submit + products count -->
                        <div class="row blocBtnSearch">
                            <div class="col-md-9">
                                <div class="d-flex justify-content-start product-count"></div>
                            </div>
                            <div class="col-md-3">
                                <div class="d-flex justify-content-end">
                                    <button class="btn btn-primary" type="submit" id="search">
                                        <i class="ft-search mr-1"></i>Rechercher
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="card-content">
                <div class="card-body">
                    <div class="table-responsive">
                        <div class="legend">
                            <div class="legend-title">
                                <b>Génération automatique : </b>
                            </div>
                            <div class="legend-success">
                                <span class="badge bg-success automatic">{{fileGenerationRecap?.automatic?.OK ?? ' '}}</span>
                                <span class="badge-text">Succès</span>
                            </div>
                            <div class="legend-error">
                                <span class="badge bg-danger automatic">{{fileGenerationRecap?.automatic?.ERROR ?? ' '}}</span>
                                <span class="badge-text">Erreur</span>
                            </div>
                            <div class="legend-warning">
                                <span class="badge bg-warning automatic">{{fileGenerationRecap?.automatic?.WARNING ?? ''}}</span>
                                <span class="badge-text">Alerte</span>
                            </div>
                            <div class="legend-running">
                                <span class="badge bg-in-progress automatic">{{fileGenerationRecap?.automatic?.RUNNING ?? ''}}</span>
                                <span class="badge-text">En cours</span>
                            </div>
                            <div class="legend-queued">
                                <span class="badge bg-info automatic">{{fileGenerationRecap?.automatic?.QUEUED ?? ''}}</span>
                                <span class="badge-text">En fil d'attente</span>
                            </div>
                            <div class="legend-planned">
                                <span class="badge bg-dark automatic">{{fileGenerationRecap?.automatic?.PLANNED ?? ''}}</span>
                                <span class="badge-text">Planifiée</span>
                            </div>

                        </div>

                        <div class="legend">
                            <div class="legend-title">
                                <b>Génération manuelle : </b>
                            </div>
                            <div class="legend-success">
                                <span class="badge bg-success manual">{{fileGenerationRecap?.manual?.OK ?? ' '}}</span>
                                <span class="badge-text">Succès</span>
                            </div>
                            <div class="legend-error">
                                <span class="badge bg-danger manual">{{fileGenerationRecap?.manual?.ERROR ?? ' '}}</span>
                                <span class="badge-text">Erreur</span>
                            </div>
                            <div class="legend-warning">
                                <span class="badge bg-warning manual">{{fileGenerationRecap?.manual?.WARNING ?? ' '}}</span>
                                <span class="badge-text">Alerte</span>
                            </div>
                            <div class="legend-running">
                                <span class="badge bg-in-progress manual">{{fileGenerationRecap?.manual?.RUNNING ?? ' '}}</span>
                                <span class="badge-text">En cours</span>
                            </div>
                            <div class="legend-queued">
                                <span class="badge bg-info manual">{{fileGenerationRecap?.manual?.QUEUED ?? ' '}}</span>
                                <span class="badge-text">En fil d'attente</span>
                            </div>

                        </div>
                        <table class="table table-bordered selection-deletion-row table-file-generation-monitoring">
                            <thead >
                            <tr>
                                <th style="min-width: 260px">Requester code</th>
                                <th colspan="24" style="min-width: 734px;">Horaires</th>
                                <th style="width:6%;min-width: 180px; text-align: center">Total générations</th>
                                <th style="width:8%;min-width: 180px; text-align: center">Générations OK</th>
                            </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let fileGeneration of fileGenerationList">
                                    <td style="position: relative">
                                        <div class="div-requester-name">{{fileGeneration.name}}</div>
                                        <i  *ngIf="tokenService.checkRole(['ROLE_ADMIN'])" class="ft-play new-generation" (click)="schedule(fileGeneration.id)" title="Lancer une nouvelle génération" style="position: absolute; top: -1px; right: 0; "></i>
                                    </td>
                                    <td *ngFor="let hour of generationHours" [ngClass]="(nowHourLong+':00') == hour && isToday ? 'current-hour' : ''">
                                        <!--Automatic-->
                                        <span *ngIf="fileGeneration.generations[hour].withGeneration else noGeneration">
                                            <span *ngIf="fileGeneration.generations[hour].automatic">
                                                <a class="automatic-log" [class.disabled]="fileGeneration.generations[hour].automatic.status == 'PLANNED'" (click)="fileGeneration.generations[hour].automatic.status != 'PLANNED' ? getSteps(fileGeneration.generations[hour].automatic.log?.cronCode,fileGeneration.generations[hour].automatic.log?.id) : ''">
                                                    <span *ngIf="fileGeneration.generations[hour].automatic else noGeneration" class="badge bg-{{ this.updating ? 'light' : fileGeneration.generations[hour].automatic.badge }}">
                                                        {{hour.replace(":00","") }}
                                                    </span>
                                                </a>
                                            </span>
                                            <!--Manual-->
                                            <span class="manual"  *ngIf="fileGeneration.generations[hour].manual">
                                                <span  *ngFor="let manual of fileGeneration.generations[hour].manual">
                                                    <a (click)="getSteps(manual.log.cronCode,manual.log.id)">
                                                        <span class="badge bg-{{ manual.badge }}">
                                                            {{hour.replace(":00","") }}
                                                        </span>
                                                      </a>
                                                </span><br>
                                            </span>
                                        </span>
                                        <ng-template #noGeneration>
                                            <span class="badge bg-white black" >
                                              {{hour.replace(":00","")}}
                                            </span>
                                        </ng-template>
                                    </td>
                                    <td>
                                        {{fileGenerationRecap?.requesters[fileGeneration.requesterCode]?.TOTAL ?? '-'}}
                                    </td>
                                    <td>
                                        <span style="{{(fileGenerationRecap?.requesters[fileGeneration.requesterCode]?.OK ?? '-') != (fileGenerationRecap?.requesters[fileGeneration.requesterCode]?.GENERATED ?? '-') ? 'color: red; font-weight: bold' : ( (fileGenerationRecap?.requesters[fileGeneration.requesterCode]?.GENERATED ?? 0) > 0 ? 'color: green' : '')}}  ">
                                            {{fileGenerationRecap?.requesters[fileGeneration.requesterCode]?.OK ?? '-'}} /
                                            {{fileGenerationRecap?.requesters[fileGeneration.requesterCode]?.GENERATED ?? '-'}}
                                        </span>
                                    </td>
                                </tr>
                                <tr *ngIf="fileGenerationList.length">
                                    <td></td>
                                    <td *ngFor="let hour of generationHours" [ngClass]="(nowHourLong+':00') == hour && isToday ? 'current-hour' : ''" style="font-size: 10px;{{(fileGenerationRecap?.hours[hour]?.OK ?? '-') != (fileGenerationRecap?.hours[hour]?.GENERATED ?? '-') ? 'color: red; font-weight: bold' : 'color: green'}}  ">
                                        <span *ngIf="fileGenerationRecap?.hours[hour]?.GENERATED && !updatingRecap">
                                            {{fileGenerationRecap?.hours[hour]?.OK}}/{{fileGenerationRecap?.hours[hour]?.GENERATED}}
                                        </span>
                                    </td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <!--/ Kick start -->
    </div>
</div>
