import { Pipe, PipeTransform } from '@angular/core';
import xmlFormat from 'xml-formatter';

@Pipe({
  name: 'xmlFormatter'
})
export class XmlFormatterPipe implements PipeTransform {

  transform(xml: string): string {
    let xmlFormatted = xml;
    try {
      xmlFormatted = xmlFormat(xml);
    } catch (error) {
    }

    return xmlFormatted;
  }

}
