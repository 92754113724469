import {LOCALE_ID, NgModule} from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


import { ToastrModule } from 'ngx-toastr';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {HttpClientModule, HttpClient, HTTP_INTERCEPTORS} from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxSpinnerModule } from 'ngx-spinner';
import {NgSelectModule} from '@ng-select/ng-select';
import {
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface
} from 'ngx-perfect-scrollbar';

import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';
import { ContentLayoutComponent } from './layouts/content/content-layout.component';
import { FullLayoutComponent } from './layouts/full/full-layout.component';

import { AuthService } from './shared/auth/auth.service';
import { AuthGuard } from './shared/auth/auth-guard.service';
import { WINDOW_PROVIDERS } from './shared/services/window.service';
import {ParameterModule} from './parameter/parameter.module';
import {CronModule} from './cron/cron.module';
import { CronLogDetailComponent } from './cron/cron-log-detail/cron-log-detail.component';
import {FileGenerationModule} from './file-generation/file-generation.module';
import {XftTransactionModule} from './xft-transaction/xft-transaction.module';
import {GlobalConstants} from './shared/global-constants';
import {CronJobsModule} from 'ngx-cron-jobs';
import {NgDynamicBreadcrumbModule} from 'ng-dynamic-breadcrumb';
import {DashboardModule} from './dashboard/dashboard.module';

import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import {NgImageSliderModule} from 'ng-image-slider';
import {GoogleMapsModule} from '@angular/google-maps';
import {AuthInterceptor} from './shared/helper/auth.interceptor';
import {LeafletModule} from '@asymmetrik/ngx-leaflet';
registerLocaleData(localeFr, 'fr');

let pageSize = GlobalConstants.pageSize;


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false
};

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent, FullLayoutComponent, ContentLayoutComponent, CronLogDetailComponent],
    exports: [],
    imports: [
        BrowserAnimationsModule,
        AppRoutingModule,
        SharedModule,
        HttpClientModule,
        NgbModule,
        NgxSpinnerModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        PerfectScrollbarModule,
        NgSelectModule,
        ToastrModule.forRoot(),
        ParameterModule,
        CronModule,
        FileGenerationModule,
        XftTransactionModule,
        CronJobsModule,
        DashboardModule,
        NgDynamicBreadcrumbModule,
        NgImageSliderModule,
        GoogleMapsModule,
        LeafletModule
    ],
    providers: [
    AuthService,
    AuthGuard,
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
    { provide: pageSize, useValue: pageSize },
        {provide: LOCALE_ID, useValue: 'fr-FR'},
    WINDOW_PROVIDERS
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
