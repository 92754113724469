<div class="modal-header">
  <h4 class="modal-title">
    Log API - Requête / Réponse
    <span *ngIf="(apiLog.profilId ?? '').length" style="padding-top: 6px;font-size: 16px;font-weight: 400;"> - {{apiLog.profilId}}</span>
  </h4>

  <button type="button" style="border: 0; background: none" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"><i class="ft-x-circle" ></i></button>
</div>
<div class="modal-body">
  <div style="overflow-wrap: anywhere;font-size: 13px;margin-bottom: 5px;" *ngIf="apiLog.endPoint.length">
    EndPoint : {{apiLog.endPoint}}
  </div>
  <div *ngIf="!showHeader">
    <label for="xftRequestTree">
      Requête :
      <a *ngIf="(logRqRs.requestFile ?? '').length" href="{{ logRqRs.requestFile ?? '' }}"   target="_blank">
        <i class="ft-download"></i>
      </a>
      <a *ngIf="(logRqRs.requestView ?? '').length" href="{{ logRqRs.requestView ?? '' }}"  title="Ouvrir" target="_blank" style="margin-left: 20px">
        <i class="ft-external-link"></i>
      </a>
    </label>
    <span class="" style="font-size: 12px;float: right;cursor: pointer;font-style: italic; text-decoration: underline;" (click)="showHeaders(true)">Headers</span>
    <span class="" *ngIf="isSqlResponse" style="font-size: 12px;float: right;cursor: pointer;font-style: italic; margin-right: 15px; text-decoration: underline;" (click)="showText(!showSql)">
      {{showSql ? 'Text' : 'Sql'}}
    </span>
    <div class="textarea-content txt-content" *ngIf="!showSql">
      <textarea id="xftRequestTree"  #textareaRequest readonly>{{ request }}</textarea>
      <span class="input-group-addon copy-rq-rs" (click)="copyRqRs(textareaRequest.value,'Requête', true)"><i class="ft-copy"></i></span>
    </div>
    <div class="textarea-content sql-content" [hidden]="!showSql">
      <span class="input-group-addon copy-rq-rs" style="z-index: 999;    padding: 3px 4px;"  (click)="copyRqRs(requestSql, 'SQL')"><i class="ft-copy"></i></span>
      <pre class=" language-sql" style="margin-bottom: 20px;white-space: pre-wrap;"  ><code #codeEle class="language-sql" style="white-space: pre-wrap">{{requestSql}}</code></pre>
    </div>
    <label for="xftResponseTree txt-content">
      Réponse :
      <a *ngIf="(logRqRs.responseFile ?? '').length" href="{{ logRqRs.responseFile ?? '' }}" target="_blank">
        <i class="ft-download"></i>
      </a>
      <a *ngIf="(logRqRs.responseView ?? '').length" href="{{ logRqRs.responseView ?? '' }}" title="Ouvrir" target="_blank" style="margin-left: 20px">
        <i class="ft-external-link"></i>
      </a>
    </label>
    <div class="textarea-content txt-content" *ngIf="!showSql || !isSqlResponseValid">
      <textarea id="xftResponseTree" #textareaResponse readonly>{{ response }}</textarea>
      <span class="input-group-addon copy-rq-rs" (click)="copyRqRs(textareaResponse.value,'Réponse', true)"><i class="ft-copy"></i></span>
    </div>
    <div class="textarea-content sql-content" *ngIf="showSql && isSqlResponseValid" style="width: 100%;overflow: auto;">
      <pre style="text-align: center" *ngIf="!responseSql.length">Aucun résultat</pre>
      <div *ngFor="let resultSet of responseSql; let index = index;">
        <span [hidden]="responseSql.length <= 1" class="resultSetNb">ResultSet #{{index+1}}</span>

        <table class="table table-striped sql-table" >
          <tr *ngFor="let ligne of resultSet; let isFirst = first">
            <ng-container *ngIf="isFirst; else skip">
              <th *ngFor="let keyValuePair of ligne | keyvalue">{{ keyValuePair.key }}</th>
            </ng-container>
            <ng-template #skip></ng-template>

          </tr>
          <tr *ngFor="let ligne of resultSet; let isFirst = first">
            <td *ngFor="let keyValuePair of ligne | keyvalue">
              <pre>{{ keyValuePair.value  }}</pre>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <div *ngIf="showHeader">
    <label for="requestHeader">
      Header requête :
    </label>
    <span class="" style="font-size: 12px;float: right; cursor: pointer;font-style: italic; text-decoration: underline;" (click)="showHeaders(false)">Requête / Réponse</span>
    <div class="textarea-content">
      <textarea id="requestHeader"  #textareaRequestHeader readonly>{{ requestHeader }}</textarea>
      <span class="input-group-addon copy-rq-rs" (click)="copyRqRs(textareaRequestHeader.value, 'Header requête')"><i class="ft-copy"></i></span>
    </div>

    <label for="responseHeader">
      Header réponse :
    </label>

    <div class="textarea-content">
      <textarea id="responseHeader" #textareaResponseHeader readonly>{{ responseHeader }}</textarea>
      <span class="input-group-addon copy-rq-rs" (click)="copyRqRs(textareaResponseHeader.value, 'Header réponse')"><i class="ft-copy"></i></span>
    </div>
  </div>
</div>
