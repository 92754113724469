import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'msDuration'
})
export class MsDurationPipe implements PipeTransform {

  transform(msDuration: number): string {
    let seconds = msDuration / 1000

    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    if (minutes === 0) {
      return `${remainingSeconds.toFixed(4)} sec`;
    } else {
      if (remainingSeconds === 0) {
        //return `${minutes} minute${minutes !== 1 ? 's' : ''}`;
        return `${minutes} min`;
      } else {
        return `${minutes} min - ${Math.round(remainingSeconds)} sec`;
      }
    }


    //return seconds + ' sec';
  }

}
