import { Component, Output, EventEmitter, OnDestroy, OnInit, AfterViewInit, ChangeDetectorRef, Inject, Renderer2, ViewChild, ElementRef, ViewChildren, QueryList, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LayoutService } from '../services/layout.service';
import {interval, Observable, Subscription, timer} from 'rxjs';
import { ConfigService } from '../services/config.service';
import { DOCUMENT } from '@angular/common';
import { CustomizerService } from '../services/customizer.service';
import { UntypedFormControl } from '@angular/forms';
import { LISTITEMS } from '../data/template-search';
import { Router } from '@angular/router';
import {NotificationService} from '../services/midOffice/notification.service';
import {switchMap} from 'rxjs/operators';
import {AuthService} from '../auth/auth.service';
import {TokenService} from '../services/token.service';

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"]
})
export class NavbarComponent implements OnInit, AfterViewInit, OnDestroy {

  currentLang = "fr";
  selectedLanguageText = "Français";
  selectedLanguageFlag = "./assets/img/flags/us.png";
  toggleClass = "ft-maximize";
  placement = "bottom-right";
  logoUrl = 'assets/img/logo.png';
  menuPosition = 'Side';
  isSmallScreen = false;
  protected innerWidth: any;
  searchOpenClass = "";
  transparentBGClass = "";
  hideSidebar: boolean = true;
  public isCollapsed = true;
  layoutSub: Subscription;
  configSub: Subscription;
  notificationSubscription !: Subscription;
  userName: string = ''

  @ViewChild('search') searchElement: ElementRef;
  @ViewChildren('searchResults') searchResults: QueryList<any>;

  @Output()
  toggleHideSidebar = new EventEmitter<Object>();

  @Output()
  seachTextEmpty = new EventEmitter<boolean>();

  listItems = [];
  control = new UntypedFormControl();

  public config: any = {};
  notificationSub: Subscription
  notificationTimerSubscription: Subscription
  markReadNotificationSubscription: Subscription
  markReadAllNotificationSubscription: Subscription
  notificationList: any = []
  notificationCount: number = 0
  notificationPageCount: number = 0
  notificationPageSize: number = 0
  constructor(public translate: TranslateService,
    private layoutService: LayoutService,
    private router: Router,
    private authService: AuthService,
    private configService: ConfigService, private cdr: ChangeDetectorRef,
    private notificationService: NotificationService,
    private tokenService: TokenService,
    private renderer: Renderer2, private el: ElementRef) {

    const browserLang: string = translate.getBrowserLang();
    translate.use(browserLang.match(/en|es|pt|de/) ? browserLang : "en");
    this.config = this.configService.templateConf;
    this.innerWidth = window.innerWidth;

    this.layoutSub = layoutService.toggleSidebar$.subscribe(
      isShow => {
        this.hideSidebar = !isShow;
      });

  }

  ngOnInit() {
    this.listItems = LISTITEMS;

    if (this.innerWidth < 1200) {
      this.isSmallScreen = true;
    }
    else {
      this.isSmallScreen = false;
    }

    this.userName = this.tokenService.getUserInfos().name;

    /************** Notificaions ***************/
    if(this.authService.isAuthenticated()) {
      this.notificationService.notifications$.subscribe((notifications) => {
        this.notificationCount = notifications['notificationCount'] ?? 0;
        this.notificationPageCount = notifications['notificationPageCount'] ?? 0;
        this.notificationPageSize = notifications['notificationPageSize'] ?? 0;
        this.notificationList = notifications['notifications'] ?? [];
      });

      this.updateNotifications();
      setInterval(() => {
        this.updateNotifications();
      }, 30000);
      /*this.notificationTimerSubscription = this.notificationSubscription = interval(30000) // Repeat every X seconds
          .pipe(
              switchMap(() => this.getNotifications())
          )
          .subscribe(data => {
            this.notificationList = [];
            this.notificationCount = data.totalCount;
            this.notificationPageCount = data.nbPage;
            this.notificationPageSize = data.pageSize;
            for (let item in data.notifications) {
              this.notificationList.push(data.notifications[item]);
            }
            if(this.notificationCount > 0){
              this.updateFavIcon(this.notificationCount);
            }

          });*/
    }

  }


  logout() {
    this.authService.logout();
  }

  ngAfterViewInit() {

    this.configSub = this.configService.templateConf$.subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;
      }
      this.loadLayout();
      this.cdr.markForCheck();

    })
  }

  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
    if (this.configSub) {
      this.configSub.unsubscribe();
    }
    if (this.notificationSub) {
      this.notificationSub.unsubscribe();
    }
    if (this.notificationTimerSubscription) {
      this.notificationTimerSubscription.unsubscribe();
    }
    if (this.markReadNotificationSubscription) {
      this.markReadNotificationSubscription.unsubscribe();
    }
    if (this.markReadAllNotificationSubscription) {
      this.markReadAllNotificationSubscription.unsubscribe();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = event.target.innerWidth;
    if (this.innerWidth < 1200) {
      this.isSmallScreen = true;
    }
    else {
      this.isSmallScreen = false;
    }
  }

  loadLayout() {

    if (this.config.layout.menuPosition && this.config.layout.menuPosition.toString().trim() != "") {
      this.menuPosition = this.config.layout.menuPosition;
    }

    if (this.config.layout.variant === "Light") {
      this.logoUrl = 'assets/img/logo-dark.png';
    }
    else {
      this.logoUrl = 'assets/img/logo.png';
    }

    if (this.config.layout.variant === "Transparent") {
      this.transparentBGClass = this.config.layout.sidebar.backgroundColor;
    }
    else {
      this.transparentBGClass = "";
    }

  }

  onSearchKey(event: any) {
    if (this.searchResults && this.searchResults.length > 0) {
      this.searchResults.first.host.nativeElement.classList.add('first-active-item');
    }

    if (event.target.value === "") {
      this.seachTextEmpty.emit(true);
    }
    else {
      this.seachTextEmpty.emit(false);
    }
  }

  removeActiveClass() {
    if (this.searchResults && this.searchResults.length > 0) {
      this.searchResults.first.host.nativeElement.classList.remove('first-active-item');
    }
  }

  onEscEvent() {
    this.control.setValue("");
    this.searchOpenClass = '';
    this.seachTextEmpty.emit(true);
  }

  onEnter() {
    if (this.searchResults && this.searchResults.length > 0) {
      let url = this.searchResults.first.url;
      if (url && url != '') {
        this.control.setValue("");
        this.searchOpenClass = '';
        this.router.navigate([url]);
        this.seachTextEmpty.emit(true);
      }
    }
  }

  redirectTo(value) {
    this.router.navigate([value]);
    this.seachTextEmpty.emit(true);
  }


  ChangeLanguage(language: string) {
    this.translate.use(language);

    if (language === 'en') {
      this.selectedLanguageText = "English";
      this.selectedLanguageFlag = "./assets/img/flags/us.png";
    }
    else if (language === 'es') {
      this.selectedLanguageText = "Spanish";
      this.selectedLanguageFlag = "./assets/img/flags/es.png";
    }
    else if (language === 'pt') {
      this.selectedLanguageText = "Portuguese";
      this.selectedLanguageFlag = "./assets/img/flags/pt.png";
    }
    else if (language === 'de') {
      this.selectedLanguageText = "German";
      this.selectedLanguageFlag = "./assets/img/flags/de.png";
    }
  }

  ToggleClass() {
    if (this.toggleClass === "ft-maximize") {
      this.toggleClass = "ft-minimize";
    } else {
      this.toggleClass = "ft-maximize";
    }
  }

  toggleSearchOpenClass(display) {
    this.control.setValue("");
    if (display) {
      this.searchOpenClass = 'open';
      setTimeout(() => {
        this.searchElement.nativeElement.focus();
      }, 0);
    }
    else {
      this.searchOpenClass = '';
    }
    this.seachTextEmpty.emit(true);



  }



  toggleNotificationSidebar() {
    this.layoutService.toggleNotificationSidebar(true);
  }

  toggleSidebar() {
    this.layoutService.toggleSidebarSmallScreen(this.hideSidebar);
  }

  getNotifications(): Observable<any>{
    return this.notificationService.getNotifications();
  }

  updateNotifications()
  {

    this.notificationSub = this.getNotifications().subscribe(
        data => {
          let notificationList = {
            'notificationCount':data.totalCount,
            'notificationPageCount':data.nbPage,
            'notificationPageSize':data.pageSize,
            'notifications':[],
          };
          for (let item in data.notifications) {
            notificationList.notifications.push(data.notifications[item]);
          }
          this.notificationService.updateNotifications(notificationList);
          this.updateFavIcon(data.totalCount);
        }
    )
  }
  updateFavIcon(countNotification){
    const link: HTMLLinkElement = this.renderer.createElement('link');
    link.rel = 'icon';
    link.type = 'image/x-icon';
    if(countNotification > 0) {
      link.href = 'assets/img/faviconNotif.png';
    } else {
      link.href = 'assets/img/favicon.png';
    }


    const head: HTMLHeadElement = this.el.nativeElement.ownerDocument.head;
    const existingFavicon: HTMLLinkElement | null = head.querySelector('link[rel="icon"]');

    if (existingFavicon) {
      this.renderer.removeChild(head, existingFavicon);
    }

    this.renderer.appendChild(head, link);
  }
  markNotification(notification: any) {
    this.markReadNotificationSubscription = this.notificationService.markReadNotification(notification.id).subscribe(
        data => {
          this.updateNotifications();
        }
    )

  }

  markAllNotification() {
    this.markReadAllNotificationSubscription = this.notificationService.markReadAllNotification().subscribe(
        data => {
          this.updateNotifications();
        }
    )
  }
}
