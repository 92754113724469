import { Injectable } from '@angular/core';
import {MidOfficeSharedService} from './mid-office-shared.service';
import {GlobalConstants} from '../../global-constants';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CronService {
  endPoint = 'cron';
  constructor(private midOfficeSharedService: MidOfficeSharedService) { }
  /*******************************************************************************************************************/
  /****************************************************** CRON *******************************************************/
  /*******************************************************************************************************************/
  getCron(code: string): Observable<any>
  {
    return this.midOfficeSharedService.get(this.endPoint+'/'+code)
  }
  getCrons(code:string='',name:string='', status:boolean|null = null, page:number = 1, pageSize:number|null = null): Observable<any>
  {
    let params = {
      code:code ?? '',
      name:name ?? '',
      isActive:status ?? '',
      page: page,
      pageSize:pageSize ?? GlobalConstants.pageSize
    };
    return this.midOfficeSharedService.get(this.endPoint,params)
  }
  addEditCron(cronCode: string,cron: any): Observable<any>
  {
    if(cronCode.length) {
      return this.midOfficeSharedService.put(this.endPoint + '/' + cronCode,cron)
    } else {
      return this.midOfficeSharedService.post(this.endPoint,cron)
    }
  }
  deleteCron(cronCode: string): Observable<any>
  {
    return this.midOfficeSharedService.delete(this.endPoint + '/' + cronCode)
  }
  getCronAudit(code: string): Observable<any>
  {
    return this.midOfficeSharedService.get(this.endPoint + '/' + code + '/audit')
  }
}
