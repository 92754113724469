<div class="row">
  <div class="col-12">
    <div class="card shadow-none">
      <div class="header">
        <i class="ft-chevron-{{searchEngineHidden ? 'down' : 'up'}} accordion-button-up" (click)="showHideSearchEngine()"></i>
        <h6 class="text-white ml-2 title-header" >Paramètres</h6>
        <i class="ft-plus-circle btn-add" (click)="addEdit()" title="Nouveau paramètre"></i>
      </div>
      <div class="card-content">
        <div class="card-body card-search-engine" [hidden]="searchEngineHidden">
          <form (ngSubmit)="searchParameter();">
            <div class="row" style="">
              <!-- ID -->
              <div class="col-md-3">
                <div class="form-floating mb-3">
                  <label for="name">Nom</label>
                  <fieldset class="form-group">
                    <input class="form-control" id="name" autocomplete="off" name="name"  type="text" [(ngModel)]="name" value="">
                  </fieldset>
                </div>
              </div>
              <!-- Etat -->
              <div class="col-md-3">
                <div class="form-floating mb-3">
                  <label>
                    Etat
                    <fieldset class="form-group">
                      <ng-select [notFoundText]="'Aucun état'" [markFirst]="false" [searchable]="false" [items]="statusList" [ngModelOptions]="{standalone: true}" id="status" labelForId="status" bindValue="value" bindLabel="name" placeholder="Tous" [(ngModel)]="status">
                      </ng-select>
                    </fieldset>
                  </label>
                </div>
              </div>
              <!-- Master -->
              <div class="col-md-3">
                <div class="form-floating mb-3">
                  <label>
                    Master
                    <fieldset class="form-group">
                      <ng-select [notFoundText]="'Aucune valeur'" [markFirst]="false" [searchable]="false" [items]="masterList" [ngModelOptions]="{standalone: true}" id="master" labelForId="master" bindValue="value" bindLabel="name" placeholder="Tous" [(ngModel)]="master">
                      </ng-select>
                    </fieldset>
                  </label>
                </div>
              </div>
              <div class="col-md-12">

                <button class="btn btn-primary btn-float-right" type="submit" id="search">
                  <i class="ft-search mr-1"></i>Rechercher
                </button>

              </div>
            </div>
          </form>
        </div>

        <div class="card-body">
          <h4 class="card-title">{{totalCount}} paramètre{{totalCount>1 ? 's' : ''}}</h4>
          <div class="table-responsive">
            <table class="table table-striped">
              <thead class="thead-light">
              <tr >
                <th scope="col" style="width: 15%;min-width: 150px;">Code</th>
                <th scope="col" style="width: auto;min-width: 200px;" >Nom</th>
                <th scope="col" style="width: 5%;">ParentCode</th>
                <th scope="col" style="width: 5%;">Master</th>
                <th scope="col" style="width: 5%;">Actif</th>
                <th scope="col" style="width: 10%;min-width: 160px;"></th>
              </tr>
              </thead>
              <!--<tbody *ngIf="parameterList?.length == 0" >
                <img class="LoaderImages" src="../assets/img/load.gif" style="position: inherit; margin: 15px;">
              </tbody>-->
              <tbody  >
              <tr *ngFor="let parameter of parameterList" >
                <td>{{parameter.code}}</td>
                <td>{{parameter.name}}</td>
                <td>{{parameter.parentCode}}</td>
                <td>
                  <!--<span class="badge bg-{{parameter.isMaster ? 'success' : 'danger'}}">{{parameter.isMaster ? 'Oui' : 'Non'}}</span>-->
                  <i *ngIf="parameter.isMaster" class="ft-check-circle" style="color: #40C057"></i>
                  <i *ngIf="!parameter.isMaster" class="ft-x-circle" style="color: red"></i>
                </td>
                <td>
                  <i *ngIf="parameter.isActive" class="ft-check-circle" style="color: #40C057"></i>
                  <i *ngIf="!parameter.isActive" class="ft-x-circle" style="color: red"></i>
                </td>
                <td style="text-align: right">
                  <a (click)="addEdit(parameter)" title="Éditer">
                    <i class="ft-edit-2" style="margin: 5px; color:green;"></i>
                  </a>
                  <a routerLink="/parameter/{{parameter.code}}/values" title="Liste valeur">
                    <i class="fa fa-list-ul" style="margin: 5px; color:blue;"></i>
                  </a>
                  <a (click)="deleteParameter(parameter.code)" title="Supprimer">
                    <i class="ft-x" style="margin: 5px; color:red;"></i>
                  </a>
                  <a (click)="auditLog(parameter.code)" title="Log modification">
                    <i class="ft-layers" style="margin: 5px; color:grey;"></i>
                  </a>
                </td>
              </tr>
              <tr *ngIf="parameterList?.length == 0">
                <td class="no-data-available" colspan="5" >Aucun paramètre</td>
              </tr>
            </table>
            <div *ngIf="pageCount > 1">
              <ngb-pagination [collectionSize]="totalCount"  [pageSize]="pageSize" (pageChange)="paginate($event)" [(page)]="page" [maxSize]="3" [rotate]="true" [boundaryLinks]="true"></ngb-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--/ Kick start -->
  </div>
</div>
