import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-cron-execution',
  templateUrl: './cron-execution.component.html',
  styleUrls: ['./cron-execution.component.scss']
})
export class CronExecutionComponent implements OnInit{
  @Input() url: string = '' ;
  @Input() cron: any ;
  parameterList: any
  parameters: any = {}
  parametersDefault: any= []
  colors: Array<string> = []
  constructor(public activeModal: NgbActiveModal,private sanitizer: DomSanitizer) {
  }
  ngOnInit(): void {
    const matches = this.url.match(/{(.*?)}/g);
    this.parameterList =  matches.map(match => match.slice(1, -1));

    this.colors.push('#f44336');
    for (let i = 0; i < this.parameterList.length; i++) {
      this.colors.push(this.getRandomColor());
    }
    // Default parameters
    if(this.cron.data1.length){
      this.parametersDefault.push(this.cron.data1);
    }
    if(this.cron.data2.length){
      this.parametersDefault.push(this.cron.data2);
    }
    if(this.cron.data3.length){
      this.parametersDefault.push(this.cron.data3);
    }
    // Prarameters
    for (let item in this.parameterList) {
      this.parameters[this.parameterList[item]] = this.parametersDefault[item] ?? null;
    }

  }
  generateUrl(withToken: boolean = false): string {
    let newUrl = this.url;
    if(!withToken) {
      newUrl = this.url.replace(/(\?|&)token=[^&]*/gi, '');
    }
    let attributName;
    for (let item in this.parameterList) {
      attributName = this.parameterList[item]
      if(withToken) {
        newUrl = newUrl.replace('{' + attributName + '}', (this.parameters[attributName]?.length ? this.parameters[attributName] : '{' + attributName + '}'));
      } else {
        newUrl = newUrl.replace('{' + attributName + '}', '<span style="font-weight: 600; color:' + this.colors[item] + '">' + (this.parameters[attributName]?.length ? this.parameters[attributName] : '{' + attributName + '}') + '</span>');
      }
    }
    if(!withToken) {
      newUrl = 'URL : ' + newUrl;
      return <string>this.sanitizer.bypassSecurityTrustHtml(newUrl);
    } else {
      return newUrl;
    }
  }

  goToUrl() {
    window.open(this.generateUrl(true), '_blank');
    this.activeModal.close()
  }
  getRandomColor(): string {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      // Utilisez des valeurs plus grandes pour favoriser les couleurs plus foncées
      color += letters[Math.floor(Math.random() * 10) + 6];
    }
    return color;
  }
}
