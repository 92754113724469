import { Injectable } from '@angular/core';
import {MidOfficeSharedService} from './mid-office-shared.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FileGenerationService {

  endPoint = 'file-generation';
  constructor(private midOfficeSharedService: MidOfficeSharedService) { }
  getFileGeneration(id: string | null): Observable<any>
  {
    return this.midOfficeSharedService.get(this.endPoint+'/'+id)
  }
  getFileGenerations(fileType?: string|null, brand?:string|null, requesterCode?:number|null, isActive?: boolean|null): Observable<any>
  {
    let params = {
      fileType:fileType ?? '',
      brand:brand ?? '',
      requesterCode:requesterCode ?? '',
      isActive:isActive ?? ''

    };
    return this.midOfficeSharedService.get(this.endPoint,params)
  }
  addEditFileGeneration(fileGenerationId: string | null, fileGeneration: any): Observable<any>
  {
    let body = { fileType: fileGeneration.fileType,brand: fileGeneration.brand,requesterCode: fileGeneration.requesterCode,isActive: fileGeneration.isActive,schedule: fileGeneration.schedule}
    if(fileGenerationId !== null) {
      return this.midOfficeSharedService.put(this.endPoint+'/'+fileGenerationId,fileGeneration)
    } else {
      return this.midOfficeSharedService.post(this.endPoint,fileGeneration)
    }

  }
  deleteFileGeneration(fileGenerationId: string): Observable<any>
  {
    return this.midOfficeSharedService.delete(this.endPoint+'/'+fileGenerationId)
  }
  getCachePrice(brand?:string|null, requesterCode?:number|null, productCode?: string|null): Observable<any>
  {
    let params = {
      brand:brand ?? '',
      requesterCode:requesterCode ?? '',
      productCode:productCode ?? ''

    };
    return this.midOfficeSharedService.get(this.endPoint+'/cache-price',params)
  }
  getFileGenerationAudit(id: string): Observable<any>
  {
    return this.midOfficeSharedService.get(this.endPoint + '/' + id + '/audit')
  }
}
