import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {FormsModule} from '@angular/forms';
import { CronListComponent } from './cron-list/cron-list.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {NgbDatepickerModule, NgbPaginationModule, NgbTimepickerModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import { CronFormComponent } from './form/cron-form/cron-form.component';
import { CronLogComponent } from './cron-log/cron-log.component';
import {CronJobsModule} from 'ngx-cron-jobs';
import {PipeModule} from '../shared/pipes/pipe.module';
import {AuthGuard} from '../shared/auth/auth-guard.service';
import { CronExecutionComponent } from './form/cron-execution/cron-execution.component';

const routes: Routes = [
  {path: '', component:CronListComponent, canActivate: [AuthGuard],data: { roles: []}},
  {path: ':code/log', component:CronLogComponent, canActivate: [AuthGuard],data: { roles: []}},
];

@NgModule({
  declarations: [
    CronListComponent,
    CronFormComponent,
    CronLogComponent,
    CronExecutionComponent
  ],
    imports: [
        CommonModule,
        [RouterModule.forChild(routes)],
        FormsModule,
        NgSelectModule,
        NgbPaginationModule,
        NgbDatepickerModule,
        NgbTimepickerModule,
        CronJobsModule,
        PipeModule,
        NgbTooltipModule,

    ]
})
export class CronModule { }
