import { Injectable } from '@angular/core';
import {CronWorkerSharedService} from './cron-worker-shared.service';
import {TokenService} from '../token.service';
import {CronExecutionComponent} from '../../../cron/form/cron-execution/cron-execution.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class CronWorkerService {

  constructor(private cronWorkerSharedService: CronWorkerSharedService, private tokenService: TokenService,private modalService: NgbModal) { }

  scheduleFileGeneration(id:number) {
    return this.cronWorkerSharedService.get('cron/xft-file-export/'+id+'?token='+this.tokenService.getToken())
  }
  executeCron(url:string,cron) {
    url = url+'?token='+this.tokenService.getToken();
    let withParam = url.indexOf( '{' ) !== -1
    if(withParam) {
      const modalRef = this.modalService.open(CronExecutionComponent,{ size: 'lg',centered:false});
      modalRef.componentInstance.cron = cron;
      modalRef.componentInstance.url = url;
    } else {
      window.open(url, '_blank');
    }
    //console.log(url)
    return  url;
  }
}
