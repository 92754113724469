import {ElementRef, Injectable, Renderer2} from '@angular/core';
import {MidOfficeSharedService} from './mid-office-shared.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {GlobalConstants} from '../../global-constants';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private notificationsSubject: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public notifications$: Observable<{}> = this.notificationsSubject.asObservable();
  endPoint = 'notification'
  constructor(private midOfficeSharedService: MidOfficeSharedService) { }

  getNotifications(
      status:string = 'unread',
      page:number = -1
  ): Observable<any>
  {
    let params = {
      status: status,
      page: page,
      pageSize:GlobalConstants.pageSize
    };

    if(page == -1) {
      params.page = 1
      params.pageSize = 30
    }
    return  this.midOfficeSharedService.get(this.endPoint,params)
  }
  public updateNotifications(notifications: {}): void {
    this.notificationsSubject.next(notifications);
  }

  markReadNotification(id): Observable<any>
  {
    return this.midOfficeSharedService.post(this.endPoint+'/'+id+'/read')
  }
  markReadAllNotification(): Observable<any>
  {
    return this.midOfficeSharedService.post(this.endPoint+'/all/read')
  }
  deleteNotification(id): Observable<any>
  {
    return this.midOfficeSharedService.delete(this.endPoint+'/'+id)
  }
  deleteAllNotification(): Observable<any>
  {
    return this.midOfficeSharedService.delete(this.endPoint+'/all')
  }

}
