import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';



@NgModule({
  declarations: [],
  exports:[

  ],
  imports: [
    CommonModule,

  ]
})
export class MidOfficeServiceModule { }
