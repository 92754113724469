import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {MidOfficeSharedService} from './mid-office-shared.service';
import {GlobalConstants} from '../../global-constants';
import {fileTypeType, xftUserType} from '../../interfaces/interfaces';
import {catchError, map, shareReplay} from 'rxjs/operators';
import {NotificationService} from '../notification.service';
import {Title} from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class XftUserService {
  endPoint = 'xft-user'
  constructor(private midOfficeSharedService: MidOfficeSharedService,private title: Title,private notifyService : NotificationService) { }


  getXftUser(requesterCode: string): Observable<any>
  {
    return this.midOfficeSharedService.get(this.endPoint+'/'+requesterCode)
  }
  getXftUsersNetworks(): Observable<any>
  {
    return  this.midOfficeSharedService.get(this.endPoint+'/network')
  }
  getXftUsersSubNetworks(): Observable<any>
  {
    return  this.midOfficeSharedService.get(this.endPoint+'/sub-network')
  }

  getXftUsers(
      page:number = -1,
      requesterCode:number|null = null,
      name:string|null = null,
      backofficeCode:string|null = null,
      networkCode:string|null = null,
      subNetworkCode:string|null = null
  ): Observable<any>
  {

    let params = {
      requesterCode:requesterCode ?? '',
      name:name ?? '',
      backofficeCode:backofficeCode ?? '',
      networkCode:networkCode ?? '',
      subNetworkCode:subNetworkCode ?? '',
      page: page,
      pageSize:GlobalConstants.pageSize
    };

    if(page == -1) {
      params.page = 1
      params.pageSize = 9999
    }
    return  this.midOfficeSharedService.get(this.endPoint,params)
  }
  addEditXftUser(requesterCode: number,xftUser: any): Observable<any>
  {
    let xftUserBody = {
      requesterCode: xftUser.requesterCode ?? '',
      name: xftUser.name,
      backofficeCode: xftUser.backofficeCode,
      networkCode: xftUser.network.code ?? null,
      subNetworkCode: xftUser.subNetwork.code ?? null,
      isActive: xftUser.isActive ?? true,
      xftLogin: xftUser.xftLogin,
      xftPassword: xftUser.xftPassword
    };

    if(requesterCode !== null) {
      return this.midOfficeSharedService.put(this.endPoint + '/' + requesterCode,xftUserBody)
    } else {
      return this.midOfficeSharedService.post(this.endPoint,xftUserBody)
    }
  }

  deleteXftUser(requesterCode: number): Observable<any>
  {
    return this.midOfficeSharedService.delete(this.endPoint + '/' + requesterCode)
  }

  getXftUserAudit(requesterCode: string): Observable<any>
  {
    return this.midOfficeSharedService.get(this.endPoint + '/' + requesterCode + '/audit')
  }

  getAllXftUsers(): Observable<xftUserType[]> {
    return this.getXftUsers(-1,null,null,null,null,null).pipe(
        map(xftUserList => Object.values(xftUserList.xftUsers).map((xftUser: any): xftUserType => ({'requesterCode': xftUser.requesterCode, 'name': xftUser.name, 'backofficeCode': xftUser.backofficeCode}))),shareReplay(1),
        catchError(error => {
          this.notifyService.showError(error.error.message ?? (error.message ?? ''),"");
          return of([]);
        })
    );
  }
}
