<div class="row">
  <div class="col-12">
    <div class="card shadow-none">
      <div class="header">
        <i class="ft-chevron-{{searchEngineHidden ? 'down' : 'up'}} accordion-button-up" (click)="showHideSearchEngine()"></i>
        <h6 class="text-white ml-2 title-header" >Rapport des Erreurs</h6>
      </div>
      <div class="card-content">
        <div class="card-body card-search-engine" [hidden]="searchEngineHidden">
          <form (ngSubmit)="onSubmit();">
            <div class="row" style="display: flex!important;">
              <!-- Transaction date -->
              <div class="col-md-3">
                <div class="form-floating mb-3">
                  <label >
                    Date transaction
                    <div class="form-group">
                      <div class="input-group">
                        <input class="form-control" id="dateFrom" readonly name="dp" [(ngModel)]="errorDate" ngbDatepicker
                               #logErrorDate="ngbDatepicker" [maxDate]="maxDate" (click)="logErrorDate.toggle()">
                        <div class="input-group-append">
                          <div class="input-group-text" (click)="logErrorDate.toggle()">
                            <i class="fa fa-calendar" style="cursor: pointer;"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
              <!-- Requester -->
              <div class="col-lg-3">
                <div class="mb-3">
                  <label>
                    Requester
                    <div class="form-group">
                      <ng-select [loading]="loadingXftUser" [notFoundText]="'Aucun requester code'" [markFirst]="false" [searchable]="(xftUserList$ | async)?.length  > 10" [searchFn]="customSearchClient" [items]="xftUserList$ | async" id="requesterCode" labelForId="requesterCode" [ngModelOptions]="{standalone: true}" bindValue="requesterCode" bindLabel="name" placeholder="Tous" [(ngModel)]="requesterCode">
                        <ng-template ng-option-tmp  ng-label-tmp let-item="item" let-index="index" let-search="searchTerm">
                          {{item.requesterCode}} - {{item.name}} ({{item.backofficeCode}})
                        </ng-template>
                      </ng-select>
                    </div>
                  </label>
                </div>
              </div>
              <!--NETWORK-->
              <div class="col-lg-3">
                <div class="mb-3">
                  <label>
                    Réseau
                    <ng-select [notFoundText]="'Aucun réseau'" [markFirst]="false" [searchable]="(networkList$ | async)?.length  > 10" [items]="networkList$ | async" id="networkCode" labelForId="networkCode" [ngModelOptions]="{standalone: true}" bindValue="id" bindLabel="name" placeholder="Tous" [(ngModel)]="requesterNetworkCode">
                    </ng-select>
                  </label>
                </div>
              </div>
              <!--SUBNETWORK-->
              <div class="col-lg-3">
                <div class="mb-3">
                  <label>
                    Sous-réseau
                    <ng-select [notFoundText]="'Aucun sous-réseau'" [markFirst]="false" [searchable]="(subNetworkList$ | async)?.length  > 10" [items]="subNetworkList$ | async" id="subNetworkCode" labelForId="subNetworkCode" [ngModelOptions]="{standalone: true}" bindValue="id" bindLabel="name" placeholder="Tous" [(ngModel)]="requesterSubNetworkCode">
                    </ng-select>
                  </label>
                </div>
              </div>
              <!-- Product Code -->
              <div class="col-md-3">
                <div class="form-floating mb-3">
                  <label for="productCode">Code produit</label>
                  <input class="form-control" id="productCode" name="productCode" ngModel type="text" [(ngModel)]="productCode" value="">
                </div>
              </div>
            </div>
            <!-- .ligne 2 -->
            <!-- Submit + products count -->
            <div class="row blocBtnSearch">
              <div class="col-md-9">
                <div class="d-flex justify-content-start product-count"></div>
              </div>
              <div class="col-md-3">
                <div class="d-flex justify-content-end">
                  <button class="btn btn-primary" type="submit" id="search">
                    <i class="ft-search mr-1"></i>Rechercher
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="card-body">
          <button class="btn btn-success" (click)="downloadFile()" type="button" id="export">
            <i class="ft-download mb-3 mr-1"></i> Export Excel
          </button>
          <br><br>
          <div class="table-responsive">

            <table class="table table-striped xft-transaction-log">
                <thead class="thead-light">
              <tr>
                <th  style="width: 11% !important;min-width: 130px;" >Produit<br><br></th>
                <th  style="width: 18% !important; min-width: 230px">Requester Code<br>Réseau - Sous-réseau</th>
                <th style="width: auto !important;min-width: 300px;">Message<br><br></th>
                <th style="width: 10% !important;min-width: 160px;">Destination<br><br></th>
                <th style="width: 10% !important;min-width: 140px">Date de départ<br>Ville de départ</th>
                <th style="width: 10% !important;min-width: 100px;">Passagers<br><br></th>
                <th style="width: 10% !important;min-width: 140px">Nombre d'alerte<br><br></th>
              </tr>
              </thead>
              <tbody *ngIf="xftTransactionErrorReportList?.length != 0">
              <tr *ngFor="let xftTransactionErrorReport of xftTransactionErrorReportList" >
                <td >
                  {{xftTransactionErrorReport.productCode}}
                </td>
                <td >
                  {{xftTransactionErrorReport.requesterBackofficeCode}} - {{xftTransactionErrorReport.requesterName}} ({{xftTransactionErrorReport.requesterCode}})<br>
                  <span style="font-size: 12px">{{xftTransactionErrorReport.requesterNetworkName}}<span *ngIf="xftTransactionErrorReport.requesterSubNetworkName.length"> - {{xftTransactionErrorReport.requesterSubNetworkName}}</span></span>
                </td>
                <td >
                  {{xftTransactionErrorReport.unavailabilityMessage}}
                </td>
                <td >
                  {{xftTransactionErrorReport.productCountryName}} ({{xftTransactionErrorReport.productCountryCode}})<br>
                  {{xftTransactionErrorReport.productCityName}} ({{xftTransactionErrorReport.productCityCode}})<br>
                </td>
                <td >
                  {{xftTransactionErrorReport.departureDate  | date:'dd/MM/YYYY' | dateText}}<br>
                  {{xftTransactionErrorReport.departureCity}}
                </td>
                <td >
                  {{xftTransactionErrorReport.nbAdult}}A - {{xftTransactionErrorReport.nbChild}}E - {{xftTransactionErrorReport.nbInfant}}B
                </td>
                <td >
                  {{xftTransactionErrorReport.errorCount}}
                </td>
              </tr>
              </tbody>
              <tbody *ngIf="xftTransactionErrorReportList?.length == 0" >
              <tr>
                <td class="no-data-available" colspan="11">Aucune transaction</td>
              </tr>
              </tbody>
            </table>
            <div *ngIf="pageCount > 1">
              <ngb-pagination [collectionSize]="totalCount" (pageChange)="paginate($event)" [pageSize]="pageSize" [(page)]="page" [maxSize]="3" [rotate]="true" [boundaryLinks]="true"></ngb-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
