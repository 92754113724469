import { Injectable } from '@angular/core';
import {LogSharedService} from './log-shared.service';
import {GlobalConstants} from '../../global-constants';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CronLogService {
  endPoint = 'cron';
  constructor(private logSharedService: LogSharedService) { }

  getCronLog(
    code: string,
    dateFrom?: string | null,
    dateTo?: string | null,
    status?: string | null,
    isAutomatic?: boolean | null,
    data1?: string | null,
    data2?: string | null,
    data3?: string | null,
    page?: number | null,
    pageSize?: number | null

  ): Observable<any>
  {
    let params = {
      cronCode:code ?? '',
      dateTimeFrom:dateFrom ?? '',
      dateTimeTo:dateTo ?? '',
      status:status ?? '',
      isAutomatic:isAutomatic ?? '',
      data1:data1 ?? '',
      data2:data2 ?? '',
      data3:data3 ?? '',
      page:page ?? 1,
      pageSize:pageSize ?? GlobalConstants.pageSize

    };
    return this.logSharedService.get(this.endPoint,params)
  }

  getCronLogDetail(code: string, id:number): Observable<any>
  {
    return this.logSharedService.get(this.endPoint + '/' +id)
  }
}
