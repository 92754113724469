import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from '@angular/forms';

@Directive({
  selector: '[appMatchPassword]',
  providers: [{ provide: NG_VALIDATORS, useExisting: MatchPasswordDirective, multi: true }]
})
export class MatchPasswordDirective implements Validator {
  @Input('matchControl') matchControl: AbstractControl;

  validate(control: AbstractControl): ValidationErrors | null {
    if (!control.value || !this.matchControl || !this.matchControl.value) {
      return null; // La validation réussit si l'un des champs est vide
    }

    return control.value === this.matchControl.value ? null : { passwordMismatch: true };
  }
}
