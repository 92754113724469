import { Injectable } from '@angular/core';
import { LogSharedService } from './log-shared.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {GlobalConstants} from '../../global-constants';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class XftTransactionService {
  endPoint = 'xfttransaction'
  endPointReport = 'xfttransaction-report'
  constructor(private httpService: HttpClient, private logSharedService: LogSharedService) { }
  xftTransactionSearchEngine(): Observable<any>
  {
    return this.logSharedService.get(this.endPoint + '/search-engine')
  }
  xftTransactionSearch(
    producer:string|null='',
    transactionCode:string|null='',
    requesterCode:string|null='',
    requesterNetworkCode:string|null='',
    requesterSubNetworkCode:string|null='',
    channel:string|null='',
    version:string|null='',
    status:string|null='',
    transactionDateFrom:NgbDateStruct|undefined,
    transactionDateTo:NgbDateStruct|undefined,
    transactionTimeFromHour:string='00',
    transactionTimeFromMinute:string='00',
    transactionTimeToHour:string='23',
    transactionTimeToMinute:string='59',
    productCode:string|null='',
    productName:string|null='',
    departureCity:string|null='',
    departureDateFrom:NgbDateStruct|undefined,
    departureDateTo:NgbDateStruct|undefined,
    bookingReference:string|null='',
    errorWarningCode:string|null='',
    platformCode:string|null='',
    page:number=1
  ): Observable<any>
  {
    let isError = null;
    let isWarning = null;
    switch(status) {
      case 'ok': {
        isError = false;
        isWarning = false;
        break;
      }
      case 'error': {
        isError = true;
        break;
      }
      case 'warning': {
        isWarning = true;
        break;
      }
    }
    let transactionDateFromRq = null
    let transactionDateToRq = null
    if(transactionDateFrom !== undefined && transactionDateTo !== undefined ) {
      transactionDateFromRq = transactionDateFrom.year + '-' + transactionDateFrom.month + '-' +  transactionDateFrom.day + 'T' + transactionTimeFromHour + ':' + transactionTimeFromMinute + ':00'
      transactionDateToRq = transactionDateTo.year + '-' + transactionDateTo.month + '-' +  transactionDateTo.day + 'T' + transactionTimeToHour + ':' + transactionTimeToMinute + ':00'
    }
    let departureDateFromRq = null
    let departureDateToRq = null
    if(departureDateFrom !== undefined && departureDateTo !== undefined ) {
      departureDateFromRq = departureDateFrom.year + '-' + departureDateFrom.month + '-' +  departureDateFrom.day
      departureDateToRq = departureDateTo.year + '-' + departureDateTo.month + '-' +  departureDateTo.day
    }
    let param = {
      producer: [producer],
      transactionCode: [transactionCode],
      requesterCode: [requesterCode],
      requesterNetworkCode: [requesterNetworkCode],
      requesterSubNetworkCode: [requesterSubNetworkCode],
      channel: [channel],
      version: [version],
      isError: isError,
      isWarning: isWarning,
      creationDateFrom: transactionDateFromRq,
      creationDateTo: transactionDateToRq,
      productCode: [productCode],
      productName: productName,
      departureCity: [departureCity],
      departureDateFrom: departureDateFromRq,
      departureDateTo: departureDateToRq,
      bookingReference: [bookingReference],
      errorWarningCode: [errorWarningCode],
      platformCode: platformCode,
      page: page,
      pageSize: GlobalConstants.pageSize
    };
    return this.logSharedService.post(this.endPoint + '/search',param)
  }
  getXftTransaction(id:number): Observable<any>
  {
    return this.logSharedService.get(this.endPoint + '/'+id)
  }
  getXftTransactionRqRs(id:number): Observable<any>
  {
    return this.logSharedService.get('xfttransaction-rq-rs/'+id)
  }

  /******************************************************************************************************************************/
  /********************************************************* LOG REPORT *********************************************************/
  /******************************************************************************************************************************/
  xftTransactionReportSearch(
      transactionDate:NgbDateStruct|undefined,
      producerCode:string|null='',
      requesterCode:string|null='',
  ): Observable<any>
  {
    let dateRq = null
    if(transactionDate !== undefined ) {
      dateRq = transactionDate.year + '-' + transactionDate.month + '-' +  transactionDate.day
    }
    let param = {
      date: dateRq,
      producerCode: producerCode ?? '',
      requesterCode: requesterCode ?? '',
    };
    return this.logSharedService.get(this.endPointReport + '/transaction-report',param)
  }
  xftTransactionErrorReportSearch(
    date:NgbDateStruct|undefined,
    productCode:string|null='',
    requesterCode:string|null='',
    requesterNetworkCode:string|null='',
    requesterSubNetworkCode:string|null='',
    page:number=1
  ): Observable<any>
  {
    let dateRq = null
    if(date !== undefined ) {
      dateRq = date.year + '-' + date.month + '-' +  date.day
    }
    let param = {
      date: dateRq,
      productCode: productCode ?? '',
      requesterCode: requesterCode ?? '',
      requesterNetworkCode: requesterNetworkCode ?? '',
      requesterSubNetworkCode: requesterSubNetworkCode ?? '',
      page: page,
      pageSize: GlobalConstants.pageSize
    };
    return this.logSharedService.get(this.endPointReport + '/error-report',param)
  }

}
