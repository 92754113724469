<div class="modal-header">
    <h4 class="modal-title" style="font-size: 1.1rem;">
        <span style="font-size: 1.3rem;">{{cron.name}} - </span>Paramètres de lancement
    </h4>
    <button type="button" style="border: 0; background: none" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"><i class="ft-x-circle" ></i></button>
</div>

<div class="modal-body">
    <div class="">
        <div class="row" style="margin-bottom: 10px;">
            <div class="col-lg-12" style="font-size: 14px;" [innerHTML]="generateUrl()"></div>
        </div>
        <form  method="post" name="parameter" #cronExecute="ngForm">
            <div class="row">
                <div class="col-lg-12" *ngFor="let parameter of parameterList;let i = index">
                    <div class="mb-3">
                        <label for="code" style="{{'color: '+colors[i]}}">{{parameter}} </label>
                        <input class="form-control" id="code" maxlength="100"  name="{{parameter}}"  placeholder="{{parameter}}" [(ngModel)]="parameters[parameter]"  type="text" autocomplete="off">
                    </div>
                </div>
                <div class="col-lg-12" *ngIf="cron.dataInfo.length">
                    <div class="mb-3 text-bold-500" style="font-size: 12px">
                        <b>Paramètres : </b><br>
                        <span [innerHTML]="cron.dataInfo.replace('\n', '<br>')"></span>
                    </div>
                </div>

                <div class="col-lg-12 float-end">
                    <button class="pull-right btn btn-primary waves-effect btn-label waves-light modal-wait blocRightBottom" type="button"  [disabled]="!cronExecute.valid" (click)="goToUrl()">
                        <i class="ft-play"></i> &nbsp;Lancer
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
