import { Component, HostBinding } from '@angular/core';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})

export class FooterComponent{
    //Variables
    currentDate : Date = new Date(new Date().toLocaleString("en-US", {timeZone: "Europe/Paris"}));
}
