import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringToColor'
})
export class StringToColorPipe implements PipeTransform {

  transform(str: string,): string {
    let colour = ''
    if (str !== null) {
      let i;
      let hash = 0;
      for (i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      let colour = '#';
      for (i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xFF;
        colour += ('00' + value.toString(16)).substr(-2);
      }
    }
    return colour;
  }

}
