import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileGenerationListComponent } from './file-generation-list/file-generation-list.component';
import {RouterModule, Routes} from '@angular/router';
import {FormsModule} from '@angular/forms';
import {NgSelectModule} from '@ng-select/ng-select';
import {NgbDatepickerModule, NgbPaginationModule, NgbTimepickerModule} from '@ng-bootstrap/ng-bootstrap';
import { FileGenerationFormComponent } from './form/file-generation-form/file-generation-form.component';
import {PipeModule} from '../shared/pipes/pipe.module';
import { FileGenerationMonitoringComponent } from './file-generation-monitoring/file-generation-monitoring.component';
import { CachePriceCheckComponent } from './cache-price-check/cache-price-check.component';
import {AuthGuard} from '../shared/auth/auth-guard.service';
import { FileGenerationTimeComponent } from './file-generation-time/file-generation-time.component';
const routes: Routes = [
  {path: 'configuration', component:FileGenerationListComponent, canActivate: [AuthGuard],data: { roles: ['ROLE_ADMIN','ROLE_MIDOFFICE']}},
  {path: 'monitoring', component:FileGenerationMonitoringComponent, canActivate: [AuthGuard],data: { roles: ['ROLE_ADMIN','ROLE_MIDOFFICE']}},
  {path: 'monitoring/:brand', component:FileGenerationMonitoringComponent, canActivate: [AuthGuard],data: { roles: ['ROLE_ADMIN','ROLE_MIDOFFICE']}},
  {path: 'monitoring/all/:fileType', component:FileGenerationMonitoringComponent, canActivate: [AuthGuard],data: { roles: ['ROLE_ADMIN','ROLE_MIDOFFICE']}},
  {path: 'monitoring/:brand/:fileType', component:FileGenerationMonitoringComponent, canActivate: [AuthGuard],data: { roles: ['ROLE_ADMIN','ROLE_MIDOFFICE']}},
  {path: 'time', component:FileGenerationTimeComponent, canActivate: [AuthGuard],data: { roles: ['ROLE_ADMIN','ROLE_MIDOFFICE']}},
  {path: 'cache-price', component:CachePriceCheckComponent, canActivate: [AuthGuard],data: { roles: ['ROLE_ADMIN','ROLE_MIDOFFICE']}},
];

@NgModule({
  declarations: [
    FileGenerationListComponent,
    FileGenerationFormComponent,
    FileGenerationMonitoringComponent,
    CachePriceCheckComponent,
    FileGenerationTimeComponent
  ],
    imports: [
        CommonModule,
        [RouterModule.forChild(routes)],
        FormsModule,
        NgSelectModule,
        NgbPaginationModule,
        PipeModule,
        NgbDatepickerModule,
        NgbTimepickerModule,
    ]
})
export class FileGenerationModule { }
