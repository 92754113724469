import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NotificationService} from '../../shared/services/notification.service';
import {XftTransactionApiLogComponent} from '../xft-transaction-api-log/xft-transaction-api-log.component';
import {UtilsService} from '../../shared/services/utils.service';
import {Subscription} from 'rxjs';
import {ApiLogService} from '../../shared/services/log/api-log.service';
@Component({
  selector: 'app-xft-transaction-log-xml',
  templateUrl: './xft-transaction-log-xml.component.html',
  styleUrls: ['./xft-transaction-log-xml.component.scss']
})
export class XftTransactionLogXmlComponent implements OnInit,OnDestroy{
  @Input() xftTransactionLogId: number;
  @Input() profilId: string;
  @Input() xftTransactionLogRqRs: any ={};
  apiLogSearchSubscription: Subscription
    requestHeader:string = '';
    responseHeader:string = '';
    showHeader:boolean = false;
  constructor(public activeModal: NgbActiveModal,private notifyService : NotificationService,private utilsService : UtilsService,private apiLogService: ApiLogService,private modalService: NgbModal) {

  }
    ngOnInit(): void {
        this.requestHeader = JSON.parse(this.xftTransactionLogRqRs.headerRequest)
        this.requestHeader = JSON.stringify(this.requestHeader, undefined, 8)
        this.responseHeader = JSON.parse(this.xftTransactionLogRqRs.headerResponse)
        this.responseHeader = JSON.stringify(this.responseHeader, undefined, 8)

        if (this.requestHeader == 'null') {
            this.requestHeader = ''
        }
        if (this.responseHeader == 'null') {
            this.responseHeader = ''
        }
    }
  ngOnDestroy() {
    if (this.apiLogSearchSubscription) {
      this.apiLogSearchSubscription.unsubscribe();
    }
  }
  copyXml(xml) {
    navigator.clipboard.writeText(xml).then(function(){});
    this.notifyService.showInfo('Xml copié','')
  }
  getApiLog(){
    this.utilsService.loadingShow();
    this.apiLogSearchSubscription = this.apiLogService.apiLogSearch(this.profilId ?? 'xxx').subscribe(
        getApiLog => {
          const modalRef = this.modalService.open(XftTransactionApiLogComponent,{ size: 'xl'});
          let apiLogList = [];
          for (let item in getApiLog.apiLog) {
            apiLogList.push(getApiLog.apiLog[item]);
          }
          modalRef.componentInstance.apiLogList = apiLogList;
          this.utilsService.loadingHide();
        },
        error => {
          this.utilsService.loadingHide();
          this.notifyService.showError(error.error.message ?? (error.message ?? ''),"")
        }
    )
  }
    showHeaders(show:boolean) {
        this.showHeader = show;
    }
}
