import {Component, Input, OnDestroy} from '@angular/core';
import {ParameterService} from '../../../shared/services/midOffice/parameter.service';
import {ActivatedRoute} from "@angular/router";
import {NotificationService} from "../../../shared/services/notification.service";
import {NgForm} from "@angular/forms";
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Subscription} from 'rxjs';
import {UtilsService} from '../../../shared/services/utils.service';

@Component({
  selector: 'app-parameter-value-form',
  templateUrl: './parameter-value-form.component.html',
  styleUrls: ['./parameter-value-form.component.scss']
})
export class ParameterValueFormComponent implements  OnDestroy {
  @Input() parameterCode: string = '' ;
  @Input() parameterValueCode: string = '' ;
  @Input() parameterValue: any = [] ;
  @Input() message: string | undefined;
  addEditParameterValueSubscription: Subscription
  constructor(private parameterService: ParameterService,private utilsService: UtilsService,private route: ActivatedRoute,private notifyService : NotificationService,public activeModal: NgbActiveModal) { }

  ngOnDestroy() {
    if (this.addEditParameterValueSubscription) {
      this.addEditParameterValueSubscription.unsubscribe();
    }
  }
  onSubmit(AddEditForm: NgForm) {
    if(AddEditForm.valid) {
      this.utilsService.loadingShow();
      this.addEditParameterValueSubscription = this.parameterService.addEditParameterValue(this.parameterCode ,this.parameterValueCode,this.parameterValue).subscribe(
        parameterValue => {
          this.parameterValue = parameterValue;
          if (this.message == 'add') {
            this.notifyService.showSuccess('Création effectuée avec succès',"")
          } else {
            this.notifyService.showSuccess('Mise à jour effectuée avec succès',"")
          }
          this.activeModal.close()
        },
        error => {
          this.notifyService.showError(error.error.message ?? (error.message ?? ''),"")
        }
      )
    }
  }
}
