import {Component, OnInit, ViewChild} from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { AuthService } from 'app/shared/auth/auth.service';
import { NgxSpinnerService } from "ngx-spinner";
import {TokenService} from '../shared/services/token.service';
import {EditoService} from '../shared/services/edito/edito.service';


@Component({
  template: ``,
  selector: 'app-login-page'
})

export class LoginPageComponent implements OnInit{

  constructor(private router: Router, private authService: AuthService,
              private spinner: NgxSpinnerService,
              private route: ActivatedRoute,
              private tokenService: TokenService,
              private editoService: EditoService) {
  }

  ngOnInit(): void {

    this.route.queryParams.subscribe(params => {
      if (params.token !== null && params.token !== undefined) {
        this.tokenService.saveToken(params.token);

      }
      if (this.tokenService.getToken() && !this.tokenService.isExpired()) {
        window.location.href = "/dashboard";
      } else {
        this.editoService.redirectToLogin();
      }
    });

  }

}
