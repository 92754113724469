<div class="modal-header">
  <h4 class="modal-title">{{message == 'edit' ? parameter.name : 'Nouveau paramètre'}}</h4>

  <button type="button" style="border: 0; background: none" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"><i class="ft-x-circle" ></i></button>
</div>
<div class="modal-body">
  <form  method="post" name="parameter" #AddEditForm="ngForm" (ngSubmit)="onSubmit(AddEditForm);">
    <div class="">
      <div class="row">

        <!-- code -->
        <div class="col-lg-4">
          <div class="mb-3">
            <label for="code">Code <span class="red" >*</span></label>
            <input [ngClass]="{'is-invalid' : !code.valid && !code.pristine}" class="form-control" id="code" maxlength="30" name="code" ngModel placeholder="Code" [(ngModel)]="parameter.code"   #code="ngModel" required minlength="3" type="text">
            <div [hidden]="code.valid  || code.pristine" class="invalid-feedback">
              Le code est obligatoire (min 3 caractères)
            </div>
          </div>
        </div>

        <!-- Nom -->
        <div class="col-lg-4">
          <div class="mb-3">
            <label for="name">Nom <span class="red" >*</span></label>
            <input [ngClass]="{'is-invalid' : !name.valid && !name.pristine}" class="form-control" id="name" maxlength="100" name="name" ngModel placeholder="Nom" #name="ngModel" [(ngModel)]="parameter.name"  required minlength="3" type="text" autocomplete="off">
            <div [hidden]="name.valid  || name.pristine" class="invalid-feedback">
              Le nom est obligatoire (min 3 caractères)
            </div>
          </div>
        </div>

        <!-- parentCode -->
        <div class="col-lg-4">
          <div class="mb-3">
            <label for="name">ParentCode </label>
            <input class="form-control" id="parentCode" maxlength="100" name="parentCode" ngModel placeholder="ParentCode" [(ngModel)]="parameter.parentCode"    type="text">

          </div>
        </div>
        <!-- isMaster -->
        <div class="col-lg-2">
          <div class="custom-switch mb-3">
            <br>
            <input  type="checkbox" id="master-switch" name="master"  [(ngModel)]="parameter.isMaster"  class="custom-control-input form-control">
            <label for="master-switch" class="custom-control-label mr-1">
              <span>Master</span>
            </label>
          </div>
        </div>
        <!-- isActive -->
        <div class="col-lg-2">
          <div class="custom-switch mb-3">
            <br>
            <input  type="checkbox" id="status-switch" name="status" ngModel [(ngModel)]="parameter.isActive" class="custom-control-input form-control">
            <label for="status-switch" class="custom-control-label mr-1">
              <span>Actif</span>
            </label>
          </div>
        </div>
        <div class="col-lg-12 float-end">
          <button class="pull-right btn btn-primary waves-effect btn-label waves-light modal-wait blocRightBottom" type="submit" [disabled]="!AddEditForm.form.valid">
            <i class="ft-save"></i> Enregistrer
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
