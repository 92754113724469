import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CronLogService} from '../../shared/services/log/cron-log.service';
import {NotificationService} from '../../shared/services/notification.service';
import {Subscription} from 'rxjs';
import {TokenService} from '../../shared/services/token.service';

@Component({
  selector: 'app-cron-log-detail',
  templateUrl: './cron-log-detail.component.html',
  styleUrls: ['./cron-log-detail.component.scss']
})
export class CronLogDetailComponent implements OnInit, OnDestroy  {
    @Input() id:number;
    @Input() code:string;
    now = new Date(new Date().toLocaleString("en-US", {timeZone: "Europe/Paris"}));
    cronExecutionDetail
    steps: any [];
    running: boolean = true;
    executionTime: string = '';
    previousTime: any = null
    getCronLogDetailSubscription: Subscription;
    refreshIntervalId: any;
    constructor(private cronLogService: CronLogService,private notifyService : NotificationService,public activeModal: NgbActiveModal, public tokenService: TokenService) {}

    ngOnInit(): void {
        this.previousTime = null
        this.getLog();
        this.refreshIntervalId = setInterval(()=> {
            if(this.running) {
                this.getLog();
            }
        }, 30 * 1000);
    }

    getLog() {
        this.now = new Date(new Date().toLocaleString("en-US", {timeZone: "Europe/Paris"}));
        this.previousTime = null
        this.steps = []
        this.running = null
        this.getCronLogDetailSubscription = this.cronLogService.getCronLogDetail(this.code ?? '',this.id).subscribe(
          cronExecutionDetail => {
              this.cronExecutionDetail = cronExecutionDetail
              this.running = cronExecutionDetail.endDateTime === null
              for (let item in cronExecutionDetail.steps) {
                  this.steps.push(cronExecutionDetail.steps[item]);
              }
              this.steps.reverse();
              for (let item in this.steps) {
                  if(this.steps[item].message.includes('fil d\'attente')) {
                      this.steps[item].time = ''
                  } else {
                      this.steps[item].time = this.getDuration(this.steps[item].dateTime, this.previousTime)
                  }
              }
              if(this.running) {
                  this.initCounter(this.steps[this.steps.length - 1].dateTime)
              }
          },
          error => {
              this.notifyService.showError(error.error.message ?? (error.message ?? ''),"")
          }
        )
    }
    getDuration(dateTime,previousTime) {
        if(!dateTime || !previousTime){
            this.previousTime= dateTime;
            return '';
        }
        // Convertir les dates en millisecondes depuis l'Époque Unix (1 janvier 1970)
        let startDate = new Date(previousTime);
        let endDate = new Date(dateTime);

        const time1 = startDate.getTime();
        const time2 = endDate.getTime();

        // Calculer la différence en millisecondes
        const differenceInMilliseconds = Math.abs(time1 - time2);

        // Convertir la différence en secondes
        let differenceInSeconds = differenceInMilliseconds / 1000;

        this.previousTime= dateTime;
        if (differenceInSeconds < 0) {
            return '';
        }
        const minutes = Math.floor(differenceInSeconds / 60);
        const remainingSeconds = Math.round(differenceInSeconds % 60);

        if (minutes === 0) {
            return `${remainingSeconds} sec`;
        } else {
            if (remainingSeconds === 0) {
                return `${minutes} min`;
            } else {
                return `${minutes} min - ${remainingSeconds} sec`;
            }
        }
  }
    initCounter(dateTime){
        this.getCounter(dateTime);
        setInterval(() => {
            this.getCounter(dateTime);
        }, 1000);
    }
    getCounter(dateTime) {
        // Convertir les dates en millisecondes depuis l'Époque Unix (1 janvier 1970)
        const startDate = new Date(dateTime);
        const endDate = new Date();

        const time1 = startDate.getTime();
        const time2 = endDate.getTime();

        // Calculer la différence en millisecondes
        const differenceInMilliseconds = Math.abs(time1 - time2);

        // Convertir la différence en secondes
        let differenceInSeconds = differenceInMilliseconds / 1000;

        if (differenceInSeconds < 0) {
            return '';
        }
        let hours = Math.floor(differenceInSeconds / 3600);
        differenceInSeconds = differenceInSeconds - (hours*3600);
        let minutes = Math.floor(differenceInSeconds / 60);
        differenceInSeconds = differenceInSeconds - (minutes*60);
        let remainingSeconds = Math.round(differenceInSeconds % 60);

        let time = '';
        if (hours > 0) {
            time = time + hours.toString().padStart(2, '0') + 'h';
        }
        if (minutes > 0) {
            time = time + (time.length ? ':' : '') + minutes.toString().padStart(2, '0') + 'm';
        }
        time = time + (time.length ? ':' : '') + remainingSeconds.toString().padStart(2, '0') + 's';
        this.executionTime = time;

    }

    ngOnDestroy() {
        clearInterval(this.refreshIntervalId);
        if (this.getCronLogDetailSubscription) {
            this.getCronLogDetailSubscription.unsubscribe();
        }
    }
    getDiffDays(date1, date2): string {
        if(!date1 || !date2){
            return '-';
        }
        // Convertir les dates en millisecondes depuis l'Époque Unix (1 janvier 1970)
        let startDate = new Date(date1);
        let endDate = new Date(date2);

        const time1 = startDate.getTime();
        const time2 = endDate.getTime();

        // Calculer la différence en millisecondes
        const differenceInMilliseconds = Math.abs(time1 - time2);

        // Convertir la différence en secondes
        let differenceInSeconds = differenceInMilliseconds / 1000;

        if (differenceInSeconds < 0) {
            return 'Temps invalide';
        }

        const minutes = Math.floor(differenceInSeconds / 60);
        const remainingSeconds = differenceInSeconds % 60;

        if (minutes === 0) {
            return `${remainingSeconds} sec`;
        } else {
            if (remainingSeconds === 0) {
                return `${minutes} min`;
            } else {
                return `${minutes} min - ${remainingSeconds} sec`;
            }
        }
    }
    isLink(message): boolean {
        return message.includes('href=');
    }
}
