import { Injectable } from '@angular/core';
import {MidOfficeSharedService} from './mid-office-shared.service';
import {GlobalConstants} from '../../global-constants';
import {Observable, of} from 'rxjs';
import {
  brandType,
  fileTypeType,
  hotelProviderType,
  localityType,
  localityTypeType, midOfficeParameterType,
  networkType,
  subNetworkType, userRoleType
} from '../../interfaces/interfaces';
import {catchError, map, shareReplay} from 'rxjs/operators';
import {NotificationService} from '../notification.service';
import {UtilsService} from '../utils.service';

@Injectable({
  providedIn: 'root'
})
export class ParameterService {

  constructor(private midOfficeSharedService: MidOfficeSharedService,private notifyService : NotificationService) { }
  /*******************************************************************************************************************/
  /**************************************************** PARAMETER ****************************************************/
  /*******************************************************************************************************************/
  endPoint = 'parameter';
  getParameter(parameterCode: string): Observable<any>
  {
    return this.midOfficeSharedService.get(this.endPoint+'/'+parameterCode)
  }
  getParameters(page: number|null = 1,name: string|null = '', status: boolean|null = null, master: boolean|null = null): Observable<any>
  {
    let params = {
      page:page ?? 1,
      pageSize:GlobalConstants.pageSize,
      name:name,
      isActive:status??'',
      isMaster:master??'',

    };
    return this.midOfficeSharedService.get(this.endPoint,params)
  }
  addEditParameter(parameterCode: string,parameter: any = []): Observable<any>
  {
    let parameterBody = { code: parameter.code,name: parameter.name,parentCode: parameter.parentCode,isActive: parameter.isActive ?? false, isMaster:parameter.isMaster  ?? false };

    if(parameterCode.length) {
      return this.midOfficeSharedService.put(this.endPoint+'/'+parameterCode,parameterBody)
    } else {
      return this.midOfficeSharedService.post(this.endPoint,parameterBody)
    }

  }
  deleteParameter(parameterCode: string): Observable<any>
  {
    return this.midOfficeSharedService.delete(this.endPoint+'/'+parameterCode)
  }
  getParameterAudit(parameterCode: string): Observable<any>
  {
    return this.midOfficeSharedService.get(this.endPoint + '/' + parameterCode + '/audit')
  }
  /*******************************************************************************************************************/
  /************************************************* PARAMETER VALUE *************************************************/
  /*******************************************************************************************************************/
  getParameterValue(parameterCode: string, valueCode: string): Observable<any>
  {
    return this.midOfficeSharedService.get(this.endPoint+'/'+parameterCode+'/value/'+valueCode)
  }
  getParameterValues(page: number = 1,parameterCode: string,code?: string|null,name?: string|null,externalCode?: string|null, isActive?: boolean|null, parentCode?: string|null): Observable<any>
  {
    let params = {
      page:page ?? 1,
      pageSize:GlobalConstants.pageSize,
      code:code??'',
      name:name??'',
      externalCode:externalCode??'',
      parentCode:parentCode??'',
      isActive:isActive??''

    };
    if(page == -1) {
      params.page = 1
      params.pageSize = 999999
    }
    return this.midOfficeSharedService.get('parameter/'+parameterCode+'/value',params)

  }

  addEditParameterValue(parameterCode: string,valueCode: string,parameterValue: any = []): Observable<any>
  {
    let parameterValueBody = {
      code: parameterValue.code,
      name: parameterValue.name,
      parentCode: parameterValue.parentCode,
      rank: parameterValue.rank,
      isActive: parameterValue.isActive,
      externalCode:parameterValue.externalCode,
      data1:parameterValue.data1,
      data2:parameterValue.data2,
      data3:parameterValue.data3,
    }

    if(valueCode.length) {
      return this.midOfficeSharedService.put(this.endPoint+'/'+parameterCode+'/value/'+valueCode,parameterValueBody)
    } else {
      return this.midOfficeSharedService.post(this.endPoint+'/'+parameterCode+'/value',parameterValueBody)
    }

  }

  deleteParameterValue(parameterCode: string,valueCode: string): Observable<any>
  {
    return this.midOfficeSharedService.delete(this.endPoint+'/'+parameterCode+'/value/'+valueCode)
  }

  getParameterValueAudit(parameterCode: string,valueCode: string): Observable<any>
  {
    return this.midOfficeSharedService.get(this.endPoint+'/'+parameterCode+'/value/'+valueCode + '/audit')
  }

  getBrands(): Observable<brandType[]> {
    return this.getParameterValues(-1, 'brand',null,null,null,true).pipe(
        map(brandList => Object.values(brandList.parameterValues).map((brand: any): brandType => ({'code': brand.code, 'name': brand.name}))),shareReplay(1),
        catchError(error => {
          this.notifyService.showError(error.error.message ?? (error.message ?? ''),"");
          return of([]);
        })
    );
  }
  getFileType(): Observable<fileTypeType[]> {
    return this.getParameterValues(-1, 'file-type',null,null,null,true).pipe(
        map(fileTypeList => Object.values(fileTypeList.parameterValues).map((fileType: any): fileTypeType => ({'code': fileType.code, 'name': fileType.name}))),shareReplay(1),
        catchError(error => {
          this.notifyService.showError(error.error.message ?? (error.message ?? ''),"");
          return of([]);
        })
    );
  }
  getNetworks(): Observable<networkType[]> {
    return this.getParameterValues(-1, 'network',null,null,null,true).pipe(
        map(networkList => Object.values(networkList.parameterValues).map((network: any): networkType => ({'code': network.code, 'name': network.name}))),shareReplay(1),

        catchError(error => {
          this.notifyService.showError(error.error.message ?? (error.message ?? ''),"");
          return of([]);
        })
    );
  }
  getSubNetworks(network: string|null = null): Observable<subNetworkType[]> {
    return this.getParameterValues(-1, 'sub-network',null,null,null,true, network).pipe(
        map(subNetworkList => Object.values(subNetworkList.parameterValues).map((subNetwork: any): subNetworkType => ({'code': subNetwork.code, 'name': subNetwork.name}))),shareReplay(1),
        catchError(error => {
          this.notifyService.showError(error.error.message ?? (error.message ?? ''),"");
          return of([]);
        })
    );
  }
  getProvider(): Observable<brandType[]> {
    return this.getParameterValues(-1, 'provider',null,null,null,true).pipe(
        map(hotelProviderList => Object.values(hotelProviderList.parameterValues).map((hotelProvider: any): hotelProviderType => ({'code': hotelProvider.code, 'name': hotelProvider.name}))),shareReplay(1),
        catchError(error => {
          this.notifyService.showError(error.error.message ?? (error.message ?? ''),"");
          return of([]);
        })
    );
  }
  getLocalityType(): Observable<localityTypeType[]> {
    return this.getParameterValues(-1, 'locality-type',null,null,null,true).pipe(
        map(localityTypeList => Object.values(localityTypeList.parameterValues).map((localityType: any): localityType => ({'code': localityType.code, 'name': localityType.name}))),shareReplay(1),
        catchError(error => {
          this.notifyService.showError(error.error.message ?? (error.message ?? ''),"");
          return of([]);
        })
    );
  }
  getUserRole(): Observable<userRoleType[]> {
    return this.getParameterValues(-1, 'user-role',null,null,null,true).pipe(
        map(userRoleList => Object.values(userRoleList.parameterValues).map((userRole: any): userRoleType => ({'code': userRole.code, 'name': userRole.name}))),shareReplay(1),
        catchError(error => {
          this.notifyService.showError(error.error.message ?? (error.message ?? ''),"");
          return of([]);
        })
    );
  }
  getPlatform(): Observable<midOfficeParameterType[]> {
    return this.getParameterValues(-1, 'platform',null,null,null,true).pipe(
        map(parameterList => Object.values(parameterList.parameterValues).map((midOfficeParameterType: any): midOfficeParameterType => ({'code': midOfficeParameterType.code, 'name': midOfficeParameterType.name}))),shareReplay(1),
        catchError(error => {
          this.notifyService.showError(error.error.message ?? (error.message ?? ''),"");
          return of([]);
        })
    );
  }
}
