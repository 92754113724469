<nav class="navbar navbar-expand-lg navbar-light header-navbar {{transparentBGClass}}"
  [ngClass]="{'navbar-brand-center': menuPosition === 'Top', 'navbar-static': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Static' , 'navbar-sticky': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Fixed', 'fixed-top': isSmallScreen && menuPosition === 'Top'}">
  <div class="container-fluid navbar-wrapper">
    <div class="navbar-header d-flex">
      <div class="navbar-toggle menu-toggle d-xl-none d-block float-left align-items-center justify-content-center"
        data-toggle="collapse" (click)="toggleSidebar()">
        <i class="ft-menu font-medium-3"></i>
      </div>
      <div class="navbar-brand-center">
        <div class="navbar-header">
          <ul class="navbar-nav">
            <li class="nav-item">
              <div class="logo">
                <div class="d-flex align-items-center justify-content-between px-2">
                <a class="logo-text" [routerLink]="['/']" *ngIf="config?.layout.menuPosition === 'Top'">
                    <div class="logo-img">
                      <img class="logo-img" alt="Thalasso N°1" [src]="logoUrl">
                    </div>
                  <span class="text">APEX</span>
                </a>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="navbar-container">
      <div class="collapse navbar-collapse d-block" id="navbarSupportedContent">
        <ul class="navbar-nav">
          <li   class="dropdown nav-item" placement="bottom-left" display="static" ngbDropdown >
            <a class="nav-link dropdown-toggle dropdown-notification p-0 mt-2" id="drp-notification" href="javascript:;" data-toggle="dropdown" ngbDropdownToggle>
              <i class="ft-bell font-medium-3"></i>
              <span class="notification badge badge-pill badge-danger" style="min-width: 20.25px;" *ngIf="notificationCount > 0">{{notificationCount}}</span>
            </a>
            <ul  ngbDropdownMenu class="notification-dropdown dropdown-menu dropdown-menu-media dropdown-menu-right m-0 overflow-hidden">

              <li class="dropdown-menu-header">
                <div class="dropdown-header d-flex justify-content-between m-0 px-3 py-2 white bg-primary">
                  <div class="d-flex">
                    <i class="ft-bell font-medium-3 d-flex align-items-center mr-2"></i><span class="noti-title">
                      {{notificationList.length}} {{notificationList.length > 1 ? 'Notifications' : 'Notification'}} </span>
                  </div>
                  <span *ngIf="notificationList.length > 0" class="text-bold-400 cursor-pointer" (click)="markAllNotification();">Tout marquer comme lu</span>
                </div>
              </li>
              <li class="scrollable-container" [perfectScrollbar]>
                <a *ngFor="let notification of notificationList " class="d-flex justify-content-between" (click)="markNotification(notification); notification.link.length ? '' :  $event.preventDefault();" target="_blank" href="{{notification.link.length ? notification.link : ''}}">
                  <div class="media d-flex align-items-center" >
                    <div class="media-body" >
                      <h6 class="m-0">
                        <!--<span>Kate Young</span>-->
                        <small class="grey lighten-1 font-italic float-right">{{notification.creationDate | dateAsAgoPipe}}</small>
                      </h6>
                      <!--<small class="noti-text">Commented on your photo</small>-->
                      <h6 class="noti-text font-small-3 m-0">
                        {{notification.content}}
                      </h6>
                    </div>
                  </div>
                </a>
                <div *ngIf="notificationPageCount > 1" class="media d-flex align-items-center" >
                  <div class="media-body" >
                    <h6 class="noti-text font-small-3 m-0">
                      + {{notificationCount - notificationPageSize}} notification(s)
                    </h6>
                  </div>
                </div>
              </li>

              <li class="dropdown-menu-footer">
                <div class="noti-footer text-center cursor-pointer primary border-top text-bold-400 py-1" routerLink="/notification">
                  Voir toutes les notifications
                </div>
              </li>
            </ul>
          </li>
          <li class="dropdown nav-item mr-1" placement="bottom-left" display="static" ngbDropdown >
            <a class="nav-link dropdown-toggle user-dropdown d-flex align-items-end" id="dropdownBasic2"
              href="javascript:;" data-toggle="dropdown" ngbDropdownToggle>
              <div class="user d-md-flex d-none mr-2">
                <span class="text-right">{{userName}}</span>
                <!--<span class="text-right text-muted font-small-3">Available</span>-->
              </div>
              <!--<img class="avatar" src="assets/img/favicon.png" alt="avatar" height="35" width="35" />-->
            </a>
            <div class="dropdown-menu text-left dropdown-menu-right m-0 pb-0" aria-labelledby="dropdownBasic2"
              ngbDropdownMenu>
              <!--<a class="dropdown-item" href="javascript:;">
                <div class="d-flex align-items-center">
                  <i class="ft-message-square mr-2"></i><span>Chat</span>
                </div>
              </a>
              <a class="dropdown-item" href="javascript:;">
                <div class="d-flex align-items-center">
                  <i class="ft-edit mr-2"></i><span>Edit Profile</span>
                </div>
              </a>
              <a class="dropdown-item" href="javascript:;">
                <div class="d-flex align-items-center">
                  <i class="ft-mail mr-2"></i><span>My Inbox</span>
                </div>
              </a>-->
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" (click)="logout()">
                <div class="d-flex align-items-center">
                  <i class="ft-power mr-2"></i><span>Se déconnecter</span>
                </div>
              </a>
            </div>
          </li>
          <li *ngIf="false" class="nav-item d-none d-lg-block mr-2 mt-1">
            <a class="nav-link notification-sidebar-toggle" (click)="toggleNotificationSidebar();"><i
                class="ft-align-right font-medium-3"></i></a>
          </li>
        </ul>
      </div>
    </div>
  </div>





</nav>
