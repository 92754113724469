import {Component, OnDestroy, OnInit} from '@angular/core';
import {CronLogService} from '../../shared/services/log/cron-log.service';
import {NotificationService} from '../../shared/services/notification.service';
import {UtilsService} from '../../shared/services/utils.service';
import {GlobalConstants} from '../../shared/global-constants';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AuditLogComponent} from '../../shared/audit-log/audit-log.component';
import {CronService} from '../../shared/services/midOffice/cron.service';
import {CronFormComponent} from '../form/cron-form/cron-form.component';
import swal from 'sweetalert2';
import {NgDynamicBreadcrumbService} from 'ng-dynamic-breadcrumb';
import cronstrue from 'cronstrue/i18n';
import {Title} from '@angular/platform-browser';
import {CronLogDetailComponent} from '../cron-log-detail/cron-log-detail.component';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {ViewportScroller} from '@angular/common';
import * as moment from 'moment';
import {CronWorkerService} from '../../shared/services/cron-worker/cron-worker.service';
import {environment} from '../../../environments/environment';


@Component({
  selector: 'app-cron-list',
  templateUrl: './cron-list.component.html',
  styleUrls: ['./cron-list.component.scss']
})
export class CronListComponent implements OnInit, OnDestroy {

    now = new Date(new Date().toLocaleString("en-US", {timeZone: "Europe/Paris"}));
    cronApiUrl = environment.cronApiUrl;
    cronList: any = null
    page: number = 1;
    pageSize: number;
    totalCount: number = 0
    pageCount: number = 1

    code: string = '';
    name: string = '';
    status: boolean|null = null;
    statusList = [
    { value: true, name: 'Actif' },
    { value: false, name: 'Inactif' },
    ];
    searchEngineHidden: boolean = (window.innerWidth < 1200 )
    getCronsSubscription: Subscription
    getCronLogSubscription: Subscription
    deleteCronSubscription: Subscription
    getCronAuditSubscription: Subscription
  constructor(private cronService: CronService, private cronWorkerService: CronWorkerService,private cronLogService: CronLogService, private title: Title,private route: ActivatedRoute,private notifyService : NotificationService, private utilsService: UtilsService,private modalService: NgbModal,private ngDynamicBreadcrumbService: NgDynamicBreadcrumbService,private viewportScroller: ViewportScroller) { }
  ngOnInit(): void {
      this.now = new Date(new Date().toLocaleString("en-US", {timeZone: "Europe/Paris"}));
    this.title.setTitle((environment.server != 'PRODUCTION' ? environment.server + ' - ' : '') + "Crons - MidOffice Thalasso N°1");
    this.pageSize = GlobalConstants.pageSize;
    this.searchCron()
      const breadcrumbs  =  [
          {
              label: 'Accueil',
              url: '/'
          },
          {
              label: 'Crons',
              url: ''
          }
      ];
      this.ngDynamicBreadcrumbService.updateBreadcrumb(breadcrumbs);
  }
    ngOnDestroy(): void {
        if (this.getCronsSubscription) {
            this.getCronsSubscription.unsubscribe();
        }
        if (this.getCronLogSubscription) {
            this.getCronLogSubscription.unsubscribe();
        }
        if (this.deleteCronSubscription) {
            this.deleteCronSubscription.unsubscribe();
        }
        if (this.getCronAuditSubscription) {
            this.getCronAuditSubscription.unsubscribe();
        }
    }

    onSubmit(){
        this.page = 1;
        this.searchCron();
    }
  searchCron() {
    this.utilsService.loadingShow();
    this.getCronsSubscription = this.cronService.getCrons(this.code,this.name, this.status, this.page).subscribe(
      cronList => {
        this.cronList = [];
        this.totalCount = cronList.totalCount;
        this.pageCount = cronList.nbPage;
        let dataInfo
        for (let item in cronList.crons) {
            cronList.crons[item]['lastExecutionDate'] = null;
            cronList.crons[item]['lastExecutionEndDate'] = null;
            cronList.crons[item]['lastExecutionMessage'] = null;
            cronList.crons[item]['isError'] = null;
            cronList.crons[item]['isWarning'] = null;
            dataInfo = '';
            if(cronList.crons[item].data1.length || cronList.crons[item].data1Label.length){
                dataInfo += cronList.crons[item].data1Label + (cronList.crons[item].data1Label.length ? ' : ' : '')  + cronList.crons[item].data1;
            }
            if(cronList.crons[item].data2.length || cronList.crons[item].data2Label.length){
                dataInfo += (dataInfo.length ? '\n' : '') + cronList.crons[item].data2Label + (cronList.crons[item].data2Label.length ? ' : ' : '')  + cronList.crons[item].data2;
            }
            if(cronList.crons[item].data3.length || cronList.crons[item].data3Label.length){
                dataInfo += (dataInfo.length ? '\n' : '') + cronList.crons[item].data3Label + (cronList.crons[item].data3Label.length ? ' : ' : '')  + cronList.crons[item].data3;
            }
            cronList.crons[item]['dataInfo'] = dataInfo;
            this.cronList.push(cronList.crons[item]);
        }

        this.utilsService.loadingHide()
        this.viewportScroller.scrollToAnchor('scrollTo');
        this.updateCronLastExecution()
      },
      error => {
        this.utilsService.loadingHide()
        this.cronList = [];
        this.notifyService.showError(error.error.message ?? (error.message ?? ''),"")
      }
    )
  }
    updateCronLastExecution(){
        for (let item in this.cronList) {
            this.cronList[item].lastExecutionDate = null
            this.getCronLastExecution(item);
        }
    }
    getCronLastExecution(index){
      this.cronLogService.getCronLog(this.cronList[index]['code'],null,null,null,null,null,null,null,1,1).subscribe(
          cronExecutionList => {
              this.cronList[index]['lastExecutionDate'] = '';
              this.cronList[index]['lastExecutionEndDate'] = '';
              this.cronList[index]['lastExecutionMessage'] = '';
              let date;
              for (let indexLog in cronExecutionList.cronExecutionLog) {
                  this.cronList[index]['lastExecutionDate'] = cronExecutionList.cronExecutionLog[indexLog]['startDateTime'];
                  this.cronList[index]['lastExecutionEndDate'] = cronExecutionList.cronExecutionLog[indexLog]['endDateTime'];
                  this.cronList[index]['lastExecutionMessage'] = cronExecutionList.cronExecutionLog[indexLog]['details'];
                  this.cronList[index]['isError'] = cronExecutionList.cronExecutionLog[indexLog]['isError'];
                  this.cronList[index]['isWarning'] = cronExecutionList.cronExecutionLog[indexLog]['isWarning'];
                  this.cronList[index]['lastExecutionId'] = cronExecutionList.cronExecutionLog[indexLog]['id'];
                  this.cronList[index]['todayExecution'] = true;
                  // If last execution is not today
                  date = moment(cronExecutionList.cronExecutionLog[indexLog]['startDateTime'], 'YYYY-MM-DD\Thh:mm:ss').toDate()
                  if(this.now.getFullYear()+'-'+(this.now.getMonth() + 1)+'-'+this.now.getDate() != date.getFullYear()+'-'+(date.getMonth() + 1)+'-'+date.getDate()){

                      this.cronList[index]['todayExecution'] = false;
                  }

              }
          },
          error => {

              this.cronList[index]['lastExecutionDate'] = '';
              this.cronList[index]['lastExecutionEndDate'] = '';
              this.cronList[index]['lastExecutionMessage'] = '';
              this.cronList[index]['error'] = error;
          }
      )

  }
    deleteCron(code: string) {
      swal.fire({
          title: '',
          text: "Êtes-vous sûr de vouloir supprimer ce cron ?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#2F8BE6',
          cancelButtonColor: '#F55252',
          confirmButtonText: 'Supprimer',
          cancelButtonText: 'Annuler',
          customClass: {
              confirmButton: 'btn btn-warning',
              cancelButton: 'btn btn-danger ml-1'
          },
          buttonsStyling: false,
      }).then((result) => {
          if (result.isConfirmed) {
              this.utilsService.loadingShow();
              this.deleteCronSubscription = this.cronService.deleteCron(code).subscribe(
                  () => {
                      this.notifyService.showSuccess('Suppression effectuée avec succès',"")
                      this.paginate();
                  },
                  error => {
                      this.notifyService.showError(error.error.message ?? (error.message ?? ''),"")
                  }
              )
          }
      });
  }
    paginate(page: number|null = null) {
    this.page = page ?? this.page;
        if(this.page>this.pageCount) {
          this.page = this.pageCount -1
        }
        this.searchCron()

    }
  auditLog(code:string) {
    this.utilsService.loadingShow();
    this.getCronAuditSubscription = this.cronService.getCronAudit(code).subscribe(
        audit => {
          const modalRef = this.modalService.open(AuditLogComponent,{ size: 'lg'});
          modalRef.componentInstance.title = 'Log modification';
          modalRef.componentInstance.auditLog = audit.auditLog;
          this.utilsService.loadingHide();
        },
        error => {
          this.utilsService.loadingHide();
          this.notifyService.showError(error.error.message ?? (error.message ?? ''),"")
        }
    )
  }

  addEdit(cron = null) {
        const modalRef = this.modalService.open(CronFormComponent,{ size: 'xl',centered:false});
        modalRef.componentInstance.cronCode = cron !== null ? cron.code : '';
        modalRef.componentInstance.cron = cron !== null ? cron : {isActive:true};
        modalRef.componentInstance.message = cron !== null ? 'edit' : 'add';
        modalRef.result.then(
            () => {
                this.paginate();
            },
            () => { });
    }
    cronToText(cron: string): string {
        if (cron.length == 0 ) {
            return 'Aucune configuration';
        }
        return cronstrue.toString(cron, { locale: "fr" });
    }
    getSteps(id:number) {
        let code = this.route.snapshot.paramMap.get('code');
        const modalRef = this.modalService.open(CronLogDetailComponent,{ size: 'lg'});
        modalRef.componentInstance.id = id;
        modalRef.componentInstance.code = code;
    }
    showHideSearchEngine(){
        this.searchEngineHidden = !this.searchEngineHidden;
    }
    executeCron(url: string, cron) {
        this.cronWorkerService.executeCron(url,cron);
    }
}
