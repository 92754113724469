import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pad'
})
export class PadPipe implements PipeTransform {

  transform(num: number, len: number=2): string {
    let result: string = ''
    if(num<=9) {
      result = '0';
    }
    return result + num;
  }

}
