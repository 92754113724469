import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, of, Subscription} from 'rxjs';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {Title} from '@angular/platform-browser';
import {XftTransactionService} from '../../shared/services/log/xft-transaction.service';
import {UtilsService} from '../../shared/services/utils.service';
import {NotificationService} from '../../shared/services/notification.service';
import {shareReplay, tap} from 'rxjs/operators';
import {XftUserService} from '../../shared/services/midOffice/xft-user.service';
import {environment} from '../../../environments/environment';

const now = new Date(new Date().toLocaleString("en-US", {timeZone: "Europe/Paris"}));
@Component({
  selector: 'app-xft-transaction-report',
  templateUrl: './xft-transaction-report.component.html',
  styleUrls: ['./xft-transaction-report.component.scss']
})
export class XftTransactionReportComponent implements OnInit, OnDestroy {
    transactionReport: any= {};
    transactionReportByCode: any[]|null;
    transactionReportByRequester: any[]|null;
    producerList$: Observable<any[]>;
    xftUserList$: Observable<any[]>;

    producerCode:string|null = 'OVOYAGES';
    requesterCode:string|null = null;
    dateNow: NgbDateStruct;
    maxDate: NgbDateStruct;
    transactionDate:NgbDateStruct;
    loadingXftUser: boolean = true
    searchEngineHidden: boolean = false
    xftTransactionSearchEngineSubscription: Subscription
    xftTransactionReportSearchSubscription: Subscription
  constructor(private xftTransactionService: XftTransactionService,private utilsService : UtilsService,private xftUserService: XftUserService,private title: Title,private notifyService : NotificationService) {}
  ngOnInit(): void {
    this.title.setTitle((environment.server != 'PRODUCTION' ? environment.server + ' - ' : '') + "Rapport transactions XFT - MidOffice Thalasso N°1");
    this.dateNow = {year: now.getFullYear(), month: now.getMonth() + 1, day: now.getDate()};
    this.maxDate = this.dateNow;
    this.transactionDate = this.dateNow;

    // PRODUCER
    this.xftTransactionSearchEngineSubscription = this.xftTransactionService.xftTransactionSearchEngine().subscribe(
        searchEngine => {
          // PRODUCER
          this.producerList$ = of(Object.values(searchEngine.producer).map(producer => ({ code: producer }))).pipe();
        },
        error => {
          this.utilsService.loadingHide();
          this.notifyService.showError(error.error.message ?? (error.message ?? ''),"")
        }
    )
    // REQUESTER
      this.xftUserList$ = this.xftUserService.getAllXftUsers().pipe(tap(() => this.loadingXftUser = false),shareReplay(1));
  }
    ngOnDestroy() {
        if (this.xftTransactionSearchEngineSubscription) {
            this.xftTransactionSearchEngineSubscription.unsubscribe();
        }
        if (this.xftTransactionReportSearchSubscription) {
            this.xftTransactionReportSearchSubscription.unsubscribe();
        }
    }

  onSubmit() {
      this.searchReport()
  }

    searchReport(){
        this.transactionReport = {};
        this.utilsService.loadingShow();
        this.xftTransactionReportSearchSubscription = this.xftTransactionService.xftTransactionReportSearch(
            this.transactionDate,
            this.producerCode,
            this.requesterCode,
        ).subscribe(
            response => {
                this.transactionReport = response;
                this.utilsService.loadingHide();
            },
            error => {
              this.utilsService.loadingHide();
              this.notifyService.showError(error.error.message ?? (error.message ?? ''),"")
            }
        )
  }
    customSearchClient(term: string, item) {
        term = term.toLocaleLowerCase();
        return item.requesterCode.toString().toLocaleLowerCase().indexOf(term) > -1 || item.name.toLocaleLowerCase().indexOf(term) > -1 || item.backofficeCode.toLocaleLowerCase().indexOf(term) > -1;
    }
    showHideSearchEngine(){
        this.searchEngineHidden = !this.searchEngineHidden;
    }
}
