import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'durationBadge'
})
export class DurationBadgePipe implements PipeTransform {

  transform(msDuration: number, warningDuration: number = 30000): string {
    if (msDuration < warningDuration) {
      return 'bg-light-success';
    } else {
      return 'bg-light-warning';
    }
  }

}
