import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-audit-log-edito',
  templateUrl: './audit-log-edito.component.html',
  styleUrls: ['./audit-log-edito.component.scss']
})
export class AuditLogEditoComponent implements OnInit{
  @Input() id: number;
  @Input() title: string;
  @Input() auditLog: any ={};
  constructor(public activeModal: NgbActiveModal) {
  }

  ngOnInit(): void {
    let newAuditData = [];
    this.auditLog.forEach((item, index) => {
      if(item.entity_id == this.id && item.entity_type == 'User') {
        if (item.action == 'Création') {
          item.event_data = '{}';
        }
        let event_data = this.jsonArray(item.event_data);
        let newEventData = [];
        event_data.forEach((event, eventIndex) => {
          if (event.key != 'password' && event.key != 'apiTokenTtl' && event.key != 'createdAt') {
            newEventData.push(event);
            //event_data.splice(eventIndex, 1);
          }
        })
        if (newEventData.length || item.action == 'Création') {
          let jsonArrayString: string = JSON.stringify(newEventData);
          item.event_data = jsonArrayString;
          newAuditData.push(item);
        }
      }
    })
    this.auditLog = newAuditData;
    }

  flattenJson(obj: any, parentKey = ''): any[] {
    let result: any[] = [];

    Object.keys(obj).forEach(key => {
      const item = obj[key];
      const currentKey = parentKey ? `${parentKey}.${key}` : key;

      result.push({ key: currentKey, ...item });

      if (item.children) {
        result = result.concat(this.flattenJson(item.children, currentKey));
      }
    });

    return result;
  }
  jsonArray(jsonData): any[] {
    return this.flattenJson(JSON.parse(jsonData));
  }
}
