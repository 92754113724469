import { Injectable } from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CronWorkerSharedService {
  cronApiHeaders = new HttpHeaders({'Content-Type':'application/json; charset=utf-8','accept': 'application/ld+json'});
  requestOptionsGet = {headers: this.cronApiHeaders, params: {}};
  requestOptionsPost = {headers: this.cronApiHeaders};
  constructor(private httpService: HttpClient) { }
  get(endPoint, params = {}) {
    let requestUrl=environment.cronApiUrl +'/' + endPoint;
    this.requestOptionsGet.params = params;
    return this.httpService.get<any>(requestUrl, this.requestOptionsGet).pipe( );
  }
  post(endPoint, params = {}) {
    let requestUrl=environment.cronApiUrl +'/' + endPoint;
    return this.httpService.post<any>(requestUrl,params, this.requestOptionsPost).pipe( );
  }
  put(endPoint, params = {}) {
    let requestUrl=environment.cronApiUrl +'/' + endPoint;
    return this.httpService.put<any>(requestUrl,params, this.requestOptionsPost).pipe( );
  }
  delete(endPoint, params = {}) {
    let requestUrl=environment.cronApiUrl +'/' + endPoint;
    this.requestOptionsGet.params = params;
    return this.httpService.delete<any>(requestUrl, this.requestOptionsGet).pipe( );
  }

}
