
<div class="content-wrapper">
  <div style="text-align: center;font-size: 24px;">
    <span>Serveur : <strong>{{server}}</strong></span>
  </div>
  <div class="row">
    <div class="col-xl-4 col-lg-4 col-md-6 col-12">
      <div class="card bg-lighten-3">
        <a href="/xft-transaction/log">
          <div class="card-content">
            <div class="card-body">
              <div class="media">
                <div class="media-body text-left">
                  <h3 class="font-large-1 info mb-0">Log XFT</h3>
                  <span>&nbsp;</span>
                </div>
                <div class="media-right info text-right">
                  <i class="ft-repeat font-large-1"></i>
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
    <div class="col-xl-4 col-lg-4 col-md-6 col-12">
      <div class="card bg-lighten-3">
        <a href="/flight-transaction">
          <div class="card-content">
            <div class="card-body">
              <div class="media">
                <div class="media-body text-left">
                  <h3 class="font-large-1 info mb-0">Log Flight</h3>
                  <span>&nbsp;</span>
                </div>
                <div class="media-right info text-right">
                  <i class="fa fa-plane font-large-1"></i>
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
    <div class="col-xl-4 col-lg-4 col-md-6 col-12"  *ngIf="tokenService.checkRole([])">
      <div class="card bg-lighten-3">
        <a href="/api-log">
          <div class="card-content">
            <div class="card-body">
              <div class="media">
                <div class="media-body info text-left">
                  <h3 class="font-large-1 info mb-0">Log API</h3>
                  <span>&nbsp;</span>
                </div>
                <div class="media-right info text-right">
                  <i class="ft-cloud font-large-1"></i>
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
    <div class="col-xl-4 col-lg-4 col-md-6 col-12">
      <div class="card bg-lighten-3">
          <div class="card-content">
            <div class="card-body">
              <div class="media" style="padding-bottom: 0">
                <div class="media-body info text-left">
                  <a href="/file-generation/monitoring"><h3 class="font-large-1 info mb-0">Générations Cache Price<span style="font-size: 16px;line-height: 0;"></span> </h3></a>
                  <div style="margin-top: 1rem">
                    <a href="/file-generation/monitoring/ovoyages" class="info"><span>Ovoyages</span></a>
                    &nbsp;&nbsp;&nbsp;
                    <a href="/file-generation/monitoring/thalasson1" class="info">Thalasso n°1</a>
                  </div>
                </div>
                <div class="media-right info text-right">
                  <i class="ft-check-circle  font-large-1"></i>
                </div>
              </div>
            </div>
          </div>

      </div>
    </div>
    <div class="col-xl-4 col-lg-4 col-md-6 col-12">
      <div class="card bg-lighten-3">
          <div class="card-content">
            <div class="card-body">
              <div class="media" style="padding-bottom: 0">
                <div class="media-body info text-left">
                  <a href="/file-generation/monitoring/all/ceto"><h3 class="font-large-1 info mb-0">Générations CETO<span style="font-size: 16px;line-height: 0;"></span> </h3></a>
                  <div style="margin-top: 1rem">
                    <a href="/file-generation/monitoring/ovoyages/ceto" class="info"><span>Ovoyages</span></a>
                    &nbsp;&nbsp;&nbsp;
                    <a href="/file-generation/monitoring/thalasson1/ceto" class="info">Thalasso n°1</a>
                  </div>
                </div>
                <div class="media-right info text-right">
                  <i class="ft-check-circle  font-large-1"></i>
                </div>
              </div>
            </div>
          </div>

      </div>
    </div>
  </div>
</div>
