import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ParameterListComponent } from './parameter-list/parameter-list.component';
import {RouterModule, Routes} from '@angular/router';
import {FormsModule} from '@angular/forms';
import {MidOfficeServiceModule} from '../shared/services/midOffice/mid-office-service.module';
import {NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import {NgSelectModule} from '@ng-select/ng-select';
import { ParameterValueListComponent } from './parameter-value-list/parameter-value-list.component';
import { ParameterFormComponent } from './form/parameter-form/parameter-form.component';
import { ParameterValueFormComponent } from './form/parameter-value-form/parameter-value-form.component';
import {AuthGuard} from '../shared/auth/auth-guard.service';

const routes: Routes = [
  {path: '',component:ParameterListComponent, canActivate: [AuthGuard],data: { roles: [] }},
  {path: ':parameterCode/values', component:ParameterValueListComponent, canActivate: [AuthGuard],data: { roles: [] }},
];

@NgModule({
    declarations: [
        ParameterListComponent,
        ParameterValueListComponent,
        ParameterFormComponent,
        ParameterValueFormComponent,
    ],
    imports: [
        CommonModule,
        [RouterModule.forChild(routes)],
        FormsModule,
        MidOfficeServiceModule,
        NgbPaginationModule,
        NgSelectModule,

    ]
})
export class ParameterModule { }
