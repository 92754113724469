import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {fa} from 'cronstrue/dist/i18n/locales/fa';
import {NotificationService} from '../../shared/services/notification.service';
import {Util} from 'leaflet';
import trim = Util.trim;
import 'prismjs';
import 'prismjs/components/prism-sql'; // Importez le support pour SQL
import * as Prism from 'prismjs';
@Component({
  selector: 'app-api-log-rq-rs',
  templateUrl: './api-log-rq-rs.component.html',
  styleUrls: ['./api-log-rq-rs.component.scss']
})
export class ApiLogRqRsComponent implements OnInit,AfterViewInit {
  @ViewChild('codeEle') codeEle!: ElementRef;
  @Input() logId: number;
  @Input() apiLog: any ={};
  @Input() logRqRs: any ={};
  request:string = '';
  response:string = '';
  requestHeader:string = '';
  responseHeader:string = '';
  showHeader:boolean = false;
  requestSql:string = '';
  responseSql:any;
  isSqlResponse: boolean = false;
  isSqlResponseValid: boolean = false;
  isMultiResponse: boolean = false;
  showSql: boolean = false;
  constructor(public activeModal: NgbActiveModal,private notifyService : NotificationService) {

  }

  ngOnInit(): void {
    this.request = this.logRqRs.request ?? '';
    this.response = this.logRqRs.response ?? '';
    this.responseSql = {};
    this.requestHeader = JSON.parse(this.apiLog.headerRequest)
    this.requestHeader = JSON.stringify(this.requestHeader, undefined, 8)
    this.responseHeader = JSON.parse(this.apiLog.headerResponse)
    this.responseHeader = JSON.stringify(this.responseHeader, undefined, 8)

    if (this.requestHeader == 'null') {
      this.requestHeader = ''
    }
    if (this.responseHeader == 'null') {
      this.responseHeader = ''
    }

    // Beautify JSON request/ response
    if ((this.logRqRs.format ?? '') == 'json') {
      try {
        this.request = JSON.parse(this.request)
        this.request = JSON.stringify(this.request, undefined, 8)
      } catch (error) {}

      try {
        this.response = JSON.parse(this.response)
        this.response = JSON.stringify(this.response, undefined, 8)
      } catch (error) {}
    }
    // Beautify SQL response (JSON)
    if ((this.logRqRs.format ?? '') == 'sql') {
      this.isSqlResponse = true;
      this.isMultiResponse = false;
      this.responseSql = [];
      try {
        this.requestSql = trim(this.request);
        this.showSql = true;
      } catch (error) {}
      try {
        if(this.response.length) {
          this.response = JSON.parse(this.response)
          this.responseSql = this.response
          this.response = JSON.stringify(this.response, undefined, 8)
          if (this.responseSql.every(item => Array.isArray(item))) {
            this.isMultiResponse = false;
          } else {
            this.responseSql = [this.responseSql];
          }
        }
        this.isSqlResponseValid = true;
        this.showSql = true;
      } catch (error) {}
    }

  }
  copyRqRs(content, type:string = 'Contenu', feminine:boolean =false) {
    navigator.clipboard.writeText(content).then(function(){});
    this.notifyService.showInfo(type+' copié'+(feminine ? 'e' : ''),'')
  }
  showHeaders(show:boolean) {
    this.showHeader = show;
  }
  showText(show:boolean) {
    this.showSql = show;
  }
  ngAfterViewInit() {
    Prism.highlightElement(this.codeEle.nativeElement);
  }
  isObject(obj: any): boolean {
    return obj !== null && typeof obj === 'object';
  }
}
